import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import { PagesService } from './pages/pages.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  activeRequest = 0;
  constructor(public toasterService: ToastrService, public authService: AuthService,
    public pageService: PagesService, public router: Router, public ActivatedRoute: ActivatedRoute) {

  }
  onSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'block';
    curtain.style.display = 'block';
  }
  offSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'none';
    curtain.style.display = 'none';
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqURl = request['url']
    if (reqURl.includes('lane-task/search') || reqURl.includes('entities/autosuggest/search') || reqURl.includes('otp-request') || reqURl.includes('transaction-files/upload-anchor-transaction-file')) {
      this.pageService.hide();
    }
    else {
      if (this.activeRequest === 0) {
        this.pageService.show();
      }
    }
    this.activeRequest++;
    const token: string = localStorage.getItem('TOKEN');
    if (token && !reqURl.includes('DropdownData.json')) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.stopLoader();
      }),
      catchError((error: HttpErrorResponse) => {
        if (!window.navigator.onLine) {
          this.toasterService.error("Please check your Internet Connection")
          return throwError(error);
        }
        if ((error['status'] == 403 || error['status'] == 410) && error.error != null && error.error['path'] != '/users/verify-reset-password') {
          this.authService.destroySession();
          this.router.navigate(['/auth/login'])
            .then(() => {
              window.location.reload();
            });
        }
        else if ((error['status'] == 403 || error['status'] == 410)) {
          this.authService.destroySession();
          this.router.navigate(['/auth/login'])
            .then(() => {
              window.location.reload();
            });
        }
        console.log("error", error);
        console.log("error. message :", error.message);
        console.log("error.error.message", error.error.message);
        this.toasterService.error(error.error.message || error.message || error);
        return throwError(error);
      })
    );
  }

  stopLoader() {
    this.activeRequest--;
    if (this.activeRequest === 0) {
      this.pageService.hide();
    }
  }
}
