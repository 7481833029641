<section class="main-wrap">
	<div class="head-sec d-flex al-center js-between">
		<div class="left">
			<h2>Details & Documentations</h2>
		</div>

		<div class="right d-flex al-center">
			<button type="submit" form="details" [disabled]="!showSave" class="btn-outline-primary save-cta" mat-stroked-button color="black"><i class="ri-save-2-line"></i>Save</button>

			<button *ngIf="tabName == 'documentation'" type="button" (click)="downloadAllFiles()" class="btn-outline-primary download-cta" mat-stroked-button color="black">
				<i class="ri-download-2-fill"></i>Download Uploaded File
			</button>
		</div>
	</div>

	<div class="inner-wrap">
		<form [formGroup]="detailForm" id="details" (ngSubmit)="submitForm()">
			<div class="inner-tabs">
				<mat-tab-group class="outline-tab" animationDuration="0ms" mat-align-tabs="start" mat-ripple-disabled="true" class="h-100" (selectedIndexChange)="getTabValue($event)">
					<mat-tab>
						<ng-template mat-tab-label> Company Details </ng-template>

						<div class="formbox" formGroupName="companyDetails">
							<div class="d-flex al-center flex-wrap">
								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Supplier Name<em>*</em></mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="supplierName" id="supplierName" #supplierName />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Type of Company</mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="typeOfCompany">
												<mat-option class="mat-option" value="PRIVATE">Private</mat-option>
												<mat-option class="mat-option" value="PUBLIC">Public</mat-option>
												<mat-option class="mat-option" value="PROPRIETORSHIP">Proprietorship</mat-option>
												<mat-option class="mat-option" value="PARTNERSHIP">Partnership</mat-option>
												<mat-option class="mat-option" value="LLP">LLP</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Nature of Business<em>*</em></mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="natureOfBusiness" id="natureOfBusiness" #natureOfBusiness>
												<mat-option class="mat-option" value="RETAILER">Retailer</mat-option>
												<mat-option class="mat-option" value="MANUFACTURER">Manufacturer</mat-option>
												<mat-option class="mat-option" value="WHOLESALER">Wholesaler</mat-option>
												<mat-option class="mat-option" value="PROFESSIONAL">Professional</mat-option>
												<mat-option class="mat-option" value="OTHER">Other</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label>{{ showCompanyPan }} Pan<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="companyPAN" id="companyPAN" #companyPAN (keypress)="restrictPan($event)" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('COMPANY_PAN')">
													<div
														class="atom-select-file"
														(click)="companyPanFileInput.value = ''; companyPanFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'COMPANY_PAN')"
													>
														<input style="display: none" type="file" (change)="fileUpload($event, 'COMPANY_PAN')" #companyPanFileInput [disabled]="!fieldEnable" />
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('COMPANY_PAN')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('COMPANY_PAN')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="companyPanFileInput.value = ''; companyPanFileInput.click()"></i>
														</a>
														<input style="display: none" type="file" (change)="fileUpload($event, 'COMPANY_PAN')" #companyPanFileInput [disabled]="!fieldEnable" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15" *ngIf="showCin">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label>CIN No.<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="cinNo" id="cinNo" #cinNo (keypress)="restrictCin($event)" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('CIN_NUMBER')">
													<div
														class="atom-select-file"
														(click)="cinFileInput.value = ''; cinFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'CIN_NUMBER')"
													>
														<input style="display: none" type="file" (change)="fileUpload($event, 'CIN_NUMBER')" #cinFileInput [disabled]="!fieldEnable" />
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('CIN_NUMBER')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('CIN_NUMBER')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="cinFileInput.value = ''; cinFileInput.click()"></i>
														</a>
														<input style="display: none" type="file" (change)="fileUpload($event, 'CIN_NUMBER')" #cinFileInput [disabled]="!fieldEnable" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label>GSTIN No.<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="gstinNo" id="gstinNo" #gstinNo (keypress)="restrictGstin($event)" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('GSTIN_NUMBER')">
													<div
														class="atom-select-file"
														(click)="gstinFileInput.value = ''; gstinFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'GSTIN_NUMBER')"
													>
														<input style="display: none" type="file" (change)="fileUpload($event, 'GSTIN_NUMBER')" #gstinFileInput [disabled]="!fieldEnable" />
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('GSTIN_NUMBER')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('GSTIN_NUMBER')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="gstinFileInput.value = ''; gstinFileInput.click()"></i>
														</a>
														<input style="display: none" type="file" (change)="fileUpload($event, 'GSTIN_NUMBER')" #gstinFileInput [disabled]="!fieldEnable" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label> Business User Details </ng-template>
						<div class="formbox" formArrayName="businessUsersDetails">
							<div class="d-flex al-center flex-wrap" *ngFor="let control of businessUsersDetails.controls; let i = index" [formGroupName]="i">
								<div class="head-box w-full d-flex al-center justify-between">
									<h2>Director Details {{ i + 1 }}</h2>
									<button class="del-cta" type="button" (click)="deleteDirector(i)" *ngIf="businessUsersDetails.length > 1 && fieldEnable"><i class="ri-delete-bin-line"></i></button>
								</div>
								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Type of Company</mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="typeOfCompany">
												<mat-option class="mat-option" value="PRIVATE">Private</mat-option>
												<mat-option class="mat-option" value="PUBLIC">Public</mat-option>
												<mat-option class="mat-option" value="PROPRIETORSHIP">Proprietorship</mat-option>
												<mat-option class="mat-option" value="PARTNERSHIP">Partnership</mat-option>
												<mat-option class="mat-option" value="LLP">LLP</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Name</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="name" />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>DOB</mat-label>
										<mat-form-field class="mat-input" appearance="outline" color="warn">
											<input matInput (focus)="MogDatePicker.open()" [matDatepicker]="MogDatePicker" placeholder="MM/DD/YYYY" formControlName="dob" />

											<mat-datepicker-toggle matSuffix [for]="MogDatePicker"> </mat-datepicker-toggle>
											<mat-datepicker panelClass="atom-datepicker" #MogDatePicker></mat-datepicker>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Gender</mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="gender">
												<mat-option class="mat-option" value="MALE" selected>Male</mat-option>
												<mat-option class="mat-option" value="FEMALE">Female</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Citizenship</mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="citizenship">
												<mat-option class="mat-option" value="INDIAN" selected>Indian</mat-option>
												<mat-option class="mat-option" value="NRI">NRI</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Contact No.</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="contactNo" type="number" (input)="restrictPhoneLength($event)" />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Email Address</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15" *ngIf="aoaAndIncomeCheck">
									<div class="input-group">
										<mat-label>DIN</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="dinNo" />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Address</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="address" />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>City</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="city" />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>State</mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="state">
												<mat-option class="mat-option" *ngFor="let item of states" value="{{ item.city }}" selected>{{ item.name }}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label>Pin Code</mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input
												matInput
												placeholder="Enter Pin Code "
												type="number"
												formControlName="pincode"
												(keypress)="restrictPinCode($event)"
												(paste)="$event.preventDefault()"
											/>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label>Pan Card<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="panCard" (keypress)="restrictPan($event)" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showUpload('pan', i)">
													<div
														class="atom-select-file"
														(click)="fileInputBusinessPan.value = ''; fileInputBusinessPan.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUploadInBusinessDetails($event, 'PAN_CARD', i)"
													>
														<input
															style="display: none"
															type="file"
															(change)="fileUploadInBusinessDetails($event, 'PAN_CARD', i)"
															#fileInputBusinessPan
															[disabled]="!fieldEnable"
														/>
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showUpload('pan', i)">
													<div class="file-name-section">
														<span class="file-name" (click)="viewBusinessDocument('pan', i)"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="fileInputBusinessPan.value = ''; fileInputBusinessPan.click()"></i>
														</a>
														<input
															style="display: none"
															type="file"
															(change)="fileUploadInBusinessDetails($event, 'PAN_CARD', i)"
															#fileInputBusinessPan
															[disabled]="!fieldEnable"
														/>
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label>KYC Document<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="kycDocument" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showUpload('kyc', i)">
													<div
														class="atom-select-file"
														(click)="fileInputBusinessKyc.value = ''; fileInputBusinessKyc.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUploadfileUploadInBusinessDetails($event, 'KYC_DOCUMENT', i)"
													>
														<input
															style="display: none"
															type="file"
															(change)="fileUploadInBusinessDetails($event, 'KYC_DOCUMENT', i)"
															#fileInputBusinessKyc
															[disabled]="!fieldEnable"
														/>
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showUpload('kyc', i)">
													<div class="file-name-section" (click)="viewBusinessDocument('kyc', i)">
														<span class="file-name"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="fileInputBusinessKyc.value = ''; fileInputBusinessKyc.click()"></i>
														</a>
														<input
															style="display: none"
															type="file"
															(change)="fileUploadInBusinessDetails($event, 'KYC_DOCUMENT', i)"
															#fileInputBusinessKyc
															[disabled]="!fieldEnable"
														/>
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15" *ngIf="showSave">
									<div class="director-button" *ngIf="i == businessUsersDetails.length - 1">
										<button type="button" (click)="addDirector(i)"><i class="ri-add-circle-line"></i> {{showCin? 'Add More Director': 'Add Co-Applicant'}} </button>
									</div>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label> Documentations </ng-template>
						<div class="formbox" formGroupName="documentations">
							<div class="head-box">
								<h2>KYC Document</h2>
							</div>

							<div class="d-flex al-center flex-wrap" formGroupName="kycDocument">
								<div class="w-32 mb-15">
									<div class="input-group">
										<mat-label class="white-pre">Type / constitution of companies</mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="typeOfCompany">
												<mat-option class="mat-option" value="PRIVATE">Private</mat-option>
												<mat-option class="mat-option" value="PUBLIC">Public</mat-option>
												<mat-option class="mat-option" value="PROPRIETORSHIP">Proprietorship</mat-option>
												<mat-option class="mat-option" value="PARTNERSHIP">Partnership</mat-option>
												<mat-option class="mat-option" value="LLP">LLP</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Utility Bills(Latest 3 Months)</mat-label>
										</div>

										<div class="upload-wrapper w-100 mt-0">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('UTILITY_BILLS')">
													<div
														class="atom-select-file"
														(click)="utilityFileInput.value = ''; utilityFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'UTILITY_BILLS')"
													>
														<input style="display: none" type="file" (change)="fileUpload($event, 'UTILITY_BILLS')" #utilityFileInput [disabled]="!fieldEnable" />
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('UTILITY_BILLS')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('UTILITY_BILLS')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="utilityFileInput.value = ''; utilityFileInput.click()"></i>
														</a>
                            <input style="display: none" type="file" (change)="fileUpload($event, 'UTILITY_BILLS')" #utilityFileInput [disabled]="!fieldEnable" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label class="white-pre">GST Registration Certificate</mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="gstCertificate" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('GST_REGISTRATION')">
													<div
														class="atom-select-file"
														(click)="gstRegistrationFileInput.value = ''; gstRegistrationFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'GST_REGISTRATION')"
													>
														<input
															style="display: none"
															type="file"
															(change)="fileUpload($event, 'GST_REGISTRATION')"
															#gstRegistrationFileInput
															[disabled]="!fieldEnable"
														/>
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('GST_REGISTRATION')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('GST_REGISTRATION')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="gstRegistrationFileInput.value = ''; gstRegistrationFileInput.click()"></i>
														</a>
                            <input
															style="display: none"
															type="file"
															(change)="fileUpload($event, 'GST_REGISTRATION')"
															#gstRegistrationFileInput
															[disabled]="!fieldEnable"
														/>
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label>Udhyam Aadhar Card</mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" type="number" formControlName="udhyamAadharCertificate" (keypress)="restrictAadhar($event)" />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('UDHYAM_AADHAR')">
													<div
														class="atom-select-file"
														(click)="udhyamFileInput.value = ''; udhyamFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'UDHYAM_AADHAR')"
													>
														<input style="display: none" type="file" (change)="fileUpload($event, 'UDHYAM_AADHAR')" #udhyamFileInput [disabled]="!fieldEnable" />
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('UDHYAM_AADHAR')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('UDHYAM_AADHAR')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="udhyamFileInput.value = ''; udhyamFileInput.click()"></i>
														</a>
                            <input style="display: none" type="file" (change)="fileUpload($event, 'UDHYAM_AADHAR')" #udhyamFileInput [disabled]="!fieldEnable" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15" *ngIf="aoaAndIncomeCheck">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Certificate of Incorporation</mat-label>
										</div>
										<div class="upload-wrapper w-100 mt-0">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('INCORPORATION_CERTIFICATE')">
													<div
														class="atom-select-file"
														(click)="incorporateFileInput.value = ''; incorporateFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'INCORPORATION_CERTIFICATE')"
													>
														<input
															style="display: none"
															type="file"
															(change)="fileUpload($event, 'INCORPORATION_CERTIFICATE')"
															#incorporateFileInput
															[disabled]="!fieldEnable"
														/>
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('INCORPORATION_CERTIFICATE')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('INCORPORATION_CERTIFICATE')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="incorporateFileInput.value = ''; incorporateFileInput.click()"></i>
														</a>
                            <input
															style="display: none"
															type="file"
															(change)="fileUpload($event, 'INCORPORATION_CERTIFICATE')"
															#incorporateFileInput
															[disabled]="!fieldEnable"
														/>
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>

								<div class="w-32 mb-15">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48">
											<mat-label class="white-pre">{{ showCompanyPan }} PAN Card</mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="companyPanCard" readonly />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
												<ng-container *ngIf="!showCompanyDetailsDoc('COMPANY_PAN')">
													<div
														class="atom-select-file"
														(click)="companyPanFileInput.value = ''; companyPanFileInput.click()"
														draggable="true"
														ngFileDragDrop
														(fileDropped)="fileUpload($event, 'COMPANY_PAN')"
													>
														<input style="display: none" type="file" (change)="fileUpload($event, 'COMPANY_PAN')" #companyPanFileInput [disabled]="true" />
														<span> Upload File<i class="ri-upload-2-line"></i> </span>
													</div>
												</ng-container>
												<ng-container *ngIf="showCompanyDetailsDoc('COMPANY_PAN')">
													<div class="file-name-section">
														<span class="file-name" (click)="viewDocument('COMPANY_PAN')"> View File <i class="ri-download-2-line download"></i> </span>
														<a>
															<i class="ri-upload-2-line" (click)="companyPanFileInput.value = ''; companyPanFileInput.click()"></i>
														</a>
                            <input style="display: none" type="file" (change)="fileUpload($event, 'COMPANY_PAN')" #companyPanFileInput [disabled]="true" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="formbox mt-15">
							<div class="head-box">
								<h2>Company Document</h2>
							</div>

							<div class="d-flex al-center flex-wrap">
								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Audited Statement (3Years)</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!auditFolder">
														<div
															class="atom-select-file"
															(click)="auditFileInput.value = ''; auditFileInput.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'AUDITED_STATEMENT')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'AUDITED_STATEMENT')"
																#auditFileInput
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="auditFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="auditFolder.filesNew.length > 0">{{ auditFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('AUDITED_STATEMENT')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('AUDITED_STATEMENT')">
												<a (click)="openDialog('AUDITED_STATEMENT')" class="uploaded-cta active" *ngIf="showFoldeFileCount('AUDITED_STATEMENT')">
													<span>{{ getFileCount('AUDITED_STATEMENT') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">{{ sidPidName }} Order</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!salesFolder">
														<div
															class="atom-select-file"
															(click)="salesFolder.value = ''; salesFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'SALES_OR_PURCHASE_ORDER')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'SALES_OR_PURCHASE_ORDER')"
																#salesFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="salesFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="salesFolder.filesNew.length > 0"> {{ salesFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('SALES_OR_PURCHASE_ORDER')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('SALES_OR_PURCHASE_ORDER')">
												<a (click)="openDialog('SALES_OR_PURCHASE_ORDER')" class="uploaded-cta active">
													<span>{{ getFileCount('SALES_OR_PURCHASE_ORDER') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Stock Statement</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!stockFolder">
														<div
															class="atom-select-file"
															(click)="stockFolder.value = ''; stockFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'STOCK_STATEMENT')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'STOCK_STATEMENT')"
																#stockFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="stockFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="stockFolder.filesNew.length > 0"> {{ stockFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('STOCK_STATEMENT')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('STOCK_STATEMENT')">
												<a (click)="openDialog('STOCK_STATEMENT')" class="uploaded-cta active">
													<span>{{ getFileCount('STOCK_STATEMENT') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Debtor Statement & Ageing</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!debtorFolder">
														<div
															class="atom-select-file"
															(click)="debtorFolder.value = ''; debtorFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'DEBTOR_STATEMENT')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'DEBTOR_STATEMENT')"
																#debtorFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="debtorFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="debtorFolder.filesNew.length > 0"> {{ debtorFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('DEBTOR_STATEMENT')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('DEBTOR_STATEMENT')">
												<a (click)="openDialog('DEBTOR_STATEMENT')" class="uploaded-cta active">
													<span>{{ getFileCount('DEBTOR_STATEMENT') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Creditor Statment & Agening</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!creditorFolder">
														<div
															class="atom-select-file"
															(click)="creditorFolder.value = ''; creditorFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'CREDITOR_STATEMENT')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'CREDITOR_STATEMENT')"
																#creditorFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="creditorFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="creditorFolder.filesNew.length > 0"> {{ creditorFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('CREDITOR_STATEMENT')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('CREDITOR_STATEMENT')">
												<a (click)="openDialog('CREDITOR_STATEMENT')" class="uploaded-cta active">
													<span>{{ getFileCount('CREDITOR_STATEMENT') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Bank Statement</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!bankFolder">
														<div
															class="atom-select-file"
															(click)="bankFolder.value = ''; bankFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'BANK_STATEMENTS')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'BANK_STATEMENTS')"
																#bankFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="bankFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="bankFolder.filesNew.length > 0"> {{ bankFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('BANK_STATEMENTS')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('BANK_STATEMENTS')">
												<a (click)="openDialog('BANK_STATEMENTS')" class="uploaded-cta active">
													<span>{{ getFileCount('BANK_STATEMENTS') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Sanction Letter For Existing Facilities</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!sacntionFolder">
														<div
															class="atom-select-file"
															(click)="sacntionFolder.value = ''; sacntionFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'SANCTION_LETTERS')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'SANCTION_LETTERS')"
																#sacntionFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="sacntionFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="sacntionFolder.filesNew.length > 0"> {{ sacntionFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('SANCTION_LETTERS')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('SANCTION_LETTERS')">
												<a (click)="openDialog('SANCTION_LETTERS')" class="uploaded-cta active">
													<span>{{ getFileCount('SANCTION_LETTERS') }} Uploded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Commercial Transaction Documents</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!commercialFolder">
														<div
															class="atom-select-file"
															(click)="fileInput.value = ''; fileInput.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'COMMERCIAL_TRANSACTION')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'COMMERCIAL_TRANSACTION')"
																#fileInput
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="commercialFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="commercialFolder.filesNew.length > 0"> {{ commercialFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('COMMERCIAL_TRANSACTION')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('COMMERCIAL_TRANSACTION')">
												<a (click)="openDialog('COMMERCIAL_TRANSACTION')" class="uploaded-cta active">
													<span>{{ getFileCount('COMMERCIAL_TRANSACTION') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">GST Returns</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!gstFolder">
														<div
															class="atom-select-file"
															(click)="gstFolder.value = ''; gstFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'GST_RETURNS')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'GST_RETURNS')"
																#gstFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="gstFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="gstFolder.filesNew.length > 0"> {{ gstFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('GST_RETURNS')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('GST_RETURNS')">
												<a (click)="openDialog('GST_RETURNS')" class="uploaded-cta active">
													<span>{{ getFileCount('GST_RETURNS') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>
								<div class="w-49 mb-15" *ngIf="aoaAndIncomeCheck">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">AOA & MOA</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container>
														<div
															class="atom-select-file"
															(click)="aomoFolder.value = ''; aomoFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'AOA_AND_MOA')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'AOA_AND_MOA')"
																#aomoFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<!-- <ng-container *ngIf="aomoFolder">
                            <div class="file-name-section">
                              <span class="file-name">

                                <span *ngIf="aomoFolder.filesNew.length > 0"
                                  > {{ aomoFolder.filesNew.length }} files</span
                                >
                              </span>

                              <a (click)="removeFolder()">
                                <i class="ri-close-line"></i>
                              </a>
                            </div>
                          </ng-container> -->
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('AOA_AND_MOA')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('AOA_AND_MOA')">
												<a (click)="openDialog('AOA_AND_MOA')" class="uploaded-cta active">
													<span>{{ getFileCount('AOA_AND_MOA') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="w-49 mb-15">
									<div class="d-flex al-center justify-between flex-wrap w-100">
										<div class="input-group w100">
											<mat-label class="white-pre">Cibil Data</mat-label>
										</div>

										<div class="d-flex w-100 al-center">
											<div class="upload-wrapper mt-0 w-100">
												<button class="btn-outline-primary atom-input-file-btn w-100" type="button" mat-stroked-button>
													<ng-container *ngIf="!cibilFolder">
														<div
															class="atom-select-file"
															(click)="cibilFolder.value = ''; cibilFolder.click()"
															draggable="true"
															ngFileDragDrop
															(fileDropped)="multiFileUploadFolder($event, 'CIBIL_DATA')"
														>
															<input
																style="display: none"
																type="file"
																multiple
																(change)="multiFileUploadFolder($event, 'CIBIL_DATA')"
																#cibilFolder
																[disabled]="!fieldEnable"
															/>
															<span> Upload Files <i class="ri-upload-2-line"></i> </span>
														</div>
													</ng-container>
													<ng-container *ngIf="cibilFolder">
														<div class="file-name-section">
															<span class="file-name">
																<span *ngIf="cibilFolder.filesNew.length > 0"> {{ cibilFolder.filesNew.length }} files</span>
															</span>

															<a (click)="removeFolder()">
																<i class="ri-close-line"></i>
															</a>
														</div>
													</ng-container>
												</button>
											</div>

											<ng-container *ngIf="!showFoldeFileCount('CIBIL_DATA')">
												<a class="uploaded-cta">
													<span>No File Uploaded <i class="ri-eye-fill"></i> </span>
												</a>
											</ng-container>
											<ng-container *ngIf="showFoldeFileCount('CIBIL_DATA')">
												<a (click)="openDialog('CIBIL_DATA')" class="uploaded-cta active">
													<span>{{ getFileCount('CIBIL_DATA') }} Uploaded Files <i class="ri-eye-fill"></i></span>
												</a>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</form>
	</div>
</section>
