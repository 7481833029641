import { Component, Inject, OnInit } from '@angular/core';
import { GstGenerateService } from '../gst-generate.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';

@Component({
  selector: 'app-sent-to-user',
  templateUrl: './sent-to-user.component.html',
  styleUrls: ['./sent-to-user.component.scss']
})
export class SentToUserComponent implements OnInit {
  email: any;
  name: any;
  contactDetails: any;
  url: any;
  constructor(private gstGenerateService: GstGenerateService, @Inject(MAT_DIALOG_DATA) public data: any, private kanbanService: KanbanService) { }

  ngOnInit() {
    console.log(this.data);
    this.contactDetails = this.getLeadContactDetails(this.data.lead)
    this.email = this.contactDetails.email || this.data.lead.leadOwner.email || null;
    this.name = this.getName(this.data.lead);
    this.url = this.data.url;
  }

  getLeadContactDetails = (lead) => {
    var contact = {
      "email": null,
      "phone": null
    };
    if (lead['data'] != null && lead['data'].length > 0) {
      lead['data'].find(element => {
        if (element['field'] == "contact_details") {
          if (element['operationsData'] != null && element['operationsData']['CONTACT_DETAILS'] != null) {
            let contactDetails = element['operationsData']['CONTACT_DETAILS'];
            if (contactDetails != null && contactDetails.length > 0) {
              for (let k = 0; k < contactDetails.length; k++) {
                let ele = contactDetails[k];
                if (contact['email'] != null && contact['phone'] != null) {
                  break;
                }
                if (ele['phone'] != null && contact['phone'] == null) {
                  contact['phone'] = ele['phone'];
                }
                if (ele['email'] != null && contact['email'] == null) {
                  contact['email'] = ele['email'];
                }
              }
            }
          }
        }
      });
    }
    return contact;
  }

  getName(lead) {
    const obj = {
      name: ""
    }
    if (lead['data'] != null && lead['data'].length > 0) {
      lead['data'].find(element => {
        if (element['field'] == "supplier_name") {
          obj.name = element['value'];
        }
      });
    }
    return obj.name;
  }

  sendToUser() {
    const obj = {
      toEmail: this.email,
      name: this.name,
      url: this.url
    }
    this.kanbanService.sentToUser(obj).subscribe((res: any) => {
      if (res && res['data']) {
        console.log(res);
      }
    })
  }
}
