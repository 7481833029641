import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-add-buyer-details',
  templateUrl: './add-buyer-details.component.html',
  styleUrls: ['./add-buyer-details.component.scss']
})
export class AddBuyerDetailsComponent implements OnInit {

  supplierDetailsForm: FormGroup;
  buyerDetail: any;
  insurerName = [];
  countryList = [];
  filteredStates: Observable<[]>;
  buyerList: any = [];
  stateCtrl = new FormControl('', Validators.required);
  constructor(public dialogRef: MatDialogRef<AddBuyerDetailsComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService, public kanbanService: KanbanService, public tostrService: ToastrService) {
    this.getCall();
  }

  ngOnInit() {
    this.buyerSuggestion();
    this.getInsurerList();
    this.formFields();
    if (this.data) {
      this.buyerDetail = this.data;
      this.getBuyerDetails(this.buyerDetail);
    }
  }
  formFields() {
    this.supplierDetailsForm = this.fb.group({
      country: [null, Validators.required],
      currency: ['USD', Validators.required],
      limitApproved: [null, Validators.required],
      insurer: [null, Validators.required],
      utilizedAmount: [null],
      tenorApprovedDays: [null],
      newRequestedLimitAmount: [null],
      availableLimit: [null]
    });
  }
  getBuyerDetails(buyerData) {
    this.disableField();
    this.stateCtrl.setValue(buyerData.buyerName);
    this.supplierDetailsForm.patchValue({
      country: buyerData.country,
      currency: buyerData.currency,
      limitApproved: buyerData.limitApproved,
      utilizedAmount: buyerData.utilizedAmount,
      tenorApprovedDays: buyerData.tenorApprovedDays,
      newRequestedLimitAmount: buyerData.newRequestedLimitAmount,
      insurer: buyerData.insurer,
      availableLimit: (buyerData.limitApproved - buyerData.utilizedAmount)
    })
  }
  disableField() {
    this.stateCtrl.disable();
    this.supplierDetailsForm.get('availableLimit').disable();
    this.supplierDetailsForm.get('newRequestedLimitAmount').disable();
  }
  closeForm() {
    this.dialogRef.close()
  }
  editDetails() {
    if (this.supplierDetailsForm.valid && this.stateCtrl.valid) {
      if (this.buyerDetail && this.buyerDetail.id) {
        let obj =
        {
          "id": this.buyerDetail.id,
          "buyerName":  this.stateCtrl.value,
          "country": this.supplierDetailsForm.get('country').value,
          "currency": this.supplierDetailsForm.get('currency').value,
          "insurer": this.supplierDetailsForm.get('insurer').value,
          "limitApproved": this.supplierDetailsForm.get('limitApproved').value,
          "newRequestedLimitAmount": this.supplierDetailsForm.get('newRequestedLimitAmount').value,
          "tenorApprovedDays": this.supplierDetailsForm.get('tenorApprovedDays').value,
          "utilizedAmount": this.supplierDetailsForm.get('utilizedAmount').value
        }
        this.kanbanService.updateApprovedBuyer(obj).subscribe(res => {
          if (res['status'] == 200) {
            this.dialogRef.close();
            this.tostrService.success("Buyer Updated Successfully")
          } else {
            this.tostrService.error(res['message']);
          }
        })
      } else {
        let obj = this.supplierDetailsForm.value;
        obj = {...obj, buyerName : this.stateCtrl.value};
        this.kanbanService.createApprovedBuyer(obj).subscribe(res => {
          if (res['status'] == 200) {
            this.dialogRef.close();
            this.tostrService.success("Buyer Added Successfully")
          } else {
            this.tostrService.error(res['message']);
          }
        })
      }
    }
  }
  getInsurerList() {
    this.kanbanService.getDropdowndata().subscribe(res => {
      this.insurerName = res['insurer_name'];
      let countryList = res['countryList']
      countryList.forEach(val => {
        if (val != 'India') {
          this.countryList.push(val);
        }
      })
    })
  }
  buyerSuggestion() {
    this.kanbanService.panjivaBuyerList().subscribe(res => {
      let data = res['result'];
      this.buyerList = data.map(item => JSON.parse(item));
    });
  }
  getCall() {
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(category => category ? this._filterStates(category) : this.buyerList.slice())
      );
  }
  _filterStates(value) {
    const filterValue = value.toLowerCase();
    return this.buyerList.filter(category => category.buyerName ? category.buyerName.toLowerCase().indexOf(filterValue) === 0 : '');
  }
}
