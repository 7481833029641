import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatOption } from '@angular/material';
import { RequestEarlyPaymentComponent } from '../../../component/early-payments/early-payment-listing/request-early-payment/request-early-payment.component';
import { Range, NgxDrpOptions, PresetItem } from 'ngx-mat-daterange-picker';
import * as moment from "moment";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AuthService } from '../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { NavigationStart, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { ReportDownloadInvoiceComponent } from '../../../component/early-payments/early-payment-listing/report-download-invoice/report-download-invoice.component';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../../../component/early-payments/early-payment-listing/custom-snackbar/custom-snackbar.component';
import { GlobalFiltersComponent } from '../../../globalFilters/global-filters/global-filters.component'
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { SettingService } from 'src/app/settings/setting.service';
import { SendUpdateWhatsappComponent } from './send-update-whatsapp/send-update-whatsapp.component';
import { CancelEPDialogComponent } from './cancel-ep-dialog/cancel-ep-dialog.component';
import { MarkDelayedEPDialogComponent } from './mark-delayed-ep-dialog/mark-delayed-ep-dialog.component';

@Component({
  selector: 'app-early-payment-listing',
  templateUrl: './early-payment-listing.component.html',
  styleUrls: ['./early-payment-listing.component.scss']
})
export class EarlyPaymentListingComponent {
  pageSize: number = 50;
  totalRecords: any;
  pageNumber: number = 0;
  earlyPaymentInvoices = [];
  sendinvoiceID: any;
  isButtonEnable: boolean = true;
  selectedDate: any;
  isCustomer: boolean = false;
  selected: any;
  startDateMillisec: any;
  EndDateMillisec: any;
  startDate: any;
  endDate: any;
  invObj = {};
  customDateOption = null;
  public date: Date;
  showtable: boolean = false;
  selectedStatusArray = [];
  isChecked: any
  timeSlot: number;
  statusSelected = null;
  searchText: any;
  userRoleListTemp: any = [];
  userRoleListToSave: any = [];
  checkedInfo: any;
  showSearchIcon: boolean = true;
  showCloseIcon: boolean = false;
  dataQuery: boolean = false;
  searchQuery: boolean = false;
  activeSort: object = {};
  step = 0;
  businessAccountId:any;
  LimitLeft: any;
  paymentInBank: any;
  isActive: boolean;
  mark = "&#8734";
  istotalLimit: number;
  limitExceeded: boolean = false;
  selectedAmount: number;
  isFiltersShow: boolean = true;
  searchedTextData = [];
  searchValueadded: string;
  searchResultListing = []
  suggestedSuppliers = [];
  suggestedInvoices = [];
  filteropen: boolean = false;
  showListing: boolean = false;
  EPStatus = [];
  isFinanceUser: boolean = false;
  isSupplyUser: boolean = false;
  isAdminUser: boolean = false;
  autoSuggestloading: boolean = false;
  previousSearchtext: string = ''
  seachText: any;
  isApproveButtonEnable: boolean = true;
  isClearButtonEnable: boolean = true;
  isApplyButtonEnable: boolean = true;
  BizTypeCheckedList = [];
  checkApprovalEnable: boolean = true;
  selectedBizTypeStatus = []
  setfilterValue: any;
  sortColumnName: string;
  sortDirection: string;
  requestEPDisable: boolean = false;
  isDownloadClicked: boolean = false;
  EPFilteresStatus = [''];
  SetBizSelectedValue = [];
  SetPaymentSelectedValue = [];
  slotvalue: number;
  sendStartValue: any;
  sendEndValue: any;
  setSupplierID: any;
  setSupplierName: any;
  browserRefresh = false;
  supplierID: any;
  supplierMongoId:string;
  supplierGSTN: any;
  fetchURlParams: any;
  isEPBreadcrumVisible: boolean = false;
  globalsessionResult = {}
  isWhatsappNumber: boolean = false;
  sessionStoredFilters = {}
  isEligibleInvoiceDisabled: boolean = false;
  jkFennerCheck:boolean=false;
  selectedSortingName: string = "Modified Date - Newest first";
  dateCreated = [{ "id": 30, "type": "Last 30days" }, { "id": 90, "type": "Last 90days" }, { "id": 7, "type": "Last Week" }]
  displayedColumns = [];
  displayedColumns1 = [];
  displayedSupplierColumns = ['select', 'invoiceNumber', 'EPStatus', 'image', 'invoiceDate', 'customer_account_name',
    'amount', 'receivableEarly', 'receivable', 'payable', 'last_modified'];
  statusFilterList = [];
  BizTypeFilterList: any = [
    { id: '1', roleName: 'ABFRL', roleStatus: 'isAbfrl' },
    { id: '2', roleName: 'Moglix', roleStatus: 'moglix' }
  ];
  paymentHoldFilterList: any = ['Gst Hold ', 'Gst Uploaded'];
  @ViewChild('dateRangePicker') dateRangePicker;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('matRef') matRef: MatSelect;
  @ViewChild('matRef1') matRef1: MatSelect;
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  dataSource = new MatTableDataSource(this.earlyPaymentInvoices);
  selection = new SelectionModel(true, []);
  customerFilterList: any = [
    { id: '1', roleName: 'Requested', roleStatus: 'PAYMENT_REQUESTED', isChecked: false },
    { id: '2', roleName: 'Partially Settled', roleStatus: 'PARTIALLY_PAID', isChecked: false },
    { id: '3', roleName: 'Settled', roleStatus: 'PAID', isChecked: false },
    { id: '4', roleName: 'Eligible', roleStatus: 'ELIGIBLE', isChecked: false },
    { id: '5', roleName: 'Expired', roleStatus: 'EXPIRED', isChecked: false },
    { id: '6', roleName: 'Approval Awaited', roleStatus: 'APPROVAL_AWAITED', isChecked: false },
  ];
  supplierFilterList: any = [
    { id: '1', roleName: 'Requested', roleStatus: 'PAYMENT_REQUESTED', isChecked: false },
    { id: '2', roleName: 'Partially Settled', roleStatus: 'PARTIALLY_PAID', isChecked: false },
    { id: '3', roleName: 'Settled', roleStatus: 'PAID', isChecked: false },
    { id: '4', roleName: 'Eligible', roleStatus: 'ELIGIBLE', isChecked: false },
    { id: '5', roleName: 'Expired', roleStatus: 'EXPIRED', isChecked: false },
    { id: '6', roleName: 'Approval Awaited', roleStatus: 'APPROVAL_AWAITED', isChecked: false },
    { id: '7', roleName: 'On Hold', roleStatus: 'ON_HOLD', isChecked: false },
  ];
  EPSortingList = [
    { id: '1', sort: 'sort', BEColumnName: 'updatedAt', direction: 'desc', sortName: 'Modified Date - Newest first' },
    { id: '2', sort: 'sort', BEColumnName: 'updatedAt', direction: 'asc', sortName: 'Modified Date - Oldest first' },
    { id: '3', sort: 'sort', BEColumnName: 'activeEarlyPayment.amountRequested', direction: 'desc', sortName: 'Amount Due - Highest first' },
    { id: '4', sort: 'sort', BEColumnName: 'activeEarlyPayment.amountRequested', direction: 'asc', sortName: 'Amount Due - Lowest first' },
    { id: '5', sort: 'sort', BEColumnName: 'dueDate', direction: 'desc', sortName: 'Due Date - Newest first' },
    { id: '6', sort: 'sort', BEColumnName: 'dueDate', direction: 'asc', sortName: 'Due Date - Oldest first' },
    { id: '7', sort: 'sort', BEColumnName: 'invoiceDate', direction: 'desc', sortName: 'Invoice Date - Newest first' },
    { id: '8', sort: 'sort', BEColumnName: 'invoiceDate', direction: 'asc', sortName: 'Invoice Date - Oldest first' }
  ]
  state$: Observable<object>;
  selectedPaymentHold: any;

  constructor(public pagesService: PagesService, public settingService: SettingService, public dialog: MatDialog, private router: Router,
    public authService: AuthService, public toasterService: ToastrService, private datePipe: DatePipe,
    private otherComp: GlobalFiltersComponent,
    private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, private _eref: ElementRef) {
    this.selection.changed.subscribe(item => {
      this.isButtonEnable = this.selection.selected.length == 0;
      setTimeout(() => {
        this.isApproveButtonEnable = this.selection.selected.length == 0
      }, 0);
    })
    if (window.history.state.navigationId == 1) {
      sessionStorage.removeItem('searchedList');
      sessionStorage.removeItem('sortingName');
      sessionStorage.removeItem("totalRecors");
    }
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          sessionStorage.setItem('previousUrl', this.router.url);
        }
      });
  }

  @HostListener('document:click')
  clickout() {
    this.showListing = false;
  }

  setStep(index: number) {
    this.step = index;
  }
  fetchRecord: any;
  onboardStatus: any;

  ngOnInit() {
    this.pagesService.isChangeColor("EP_white");
    this.pagesService.show();
    if (this.authService.getIsCusotmer() || this.authService.isCredlixUser()) {
      this.isCustomer = true;
      this.statusFilterList = this.customerFilterList
      if (this.authService.getIsFinanceUser()) {
        this.isFinanceUser = true;
      }
      else if (this.authService.getIsSupplyUser()) {
        this.isSupplyUser = true;
      }
      else {
        this.isAdminUser = true;
      }
    }
    else {
      this.statusFilterList = this.supplierFilterList
      this.getConsumedLimit();
    }
    if (!this.isCustomer) {
      this.settingService.checkGSTINExist().subscribe(res => {
        this.onboardStatus = res['result'];
      });
    }
    if (this.activeSort == null || this.activeSort['field'] == null) {
      this.activeSort = {
        "field": "updatedAt",
        "order": "desc"
      };
      if (this.sort != null) {
        this.sort.sort({ id: "updatedAt", start: "desc", disableClear: true });
      }
    }
    let checkCancelClicked;
    checkCancelClicked = sessionStorage.getItem('Cancelled');
    if (!this.isCustomer && checkCancelClicked == null) {
      this.iswhatsappNmbrExist();
    }
    this.loadInitProcess()
  }
  iswhatsappNmbrExist() {
    this.settingService.checkWhatsappUserExist().subscribe(res => {
      console.log(res);
      if (res['result'] == false) {
        this.isWhatsappNumber = true;
        this.sendUpdatesOverWhatsapp();
      }
    });
  }


  sendUpdatesOverWhatsapp() {
    const dialogRef = this.dialog.open(SendUpdateWhatsappComponent, {
      disableClose: true,
      width: '550px',
      height: '260px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        sessionStorage.setItem("Cancelled", 'yes');
      }
    });
  }

  loadInitProcess() {
    if (this.isCustomer) {
      this.activatedRoute.params.subscribe(params => {
        let globalFilterData = {};
        this.fetchURlParams = params;
        console.log(params);
        if (params.id == 'search' && sessionStorage.getItem("searchedText")) {
          setTimeout(() => {
            this.isEPBreadcrumVisible = true;
          }, 0);
          this.pagesService.earlyPaymntBehv.subscribe((res: any) => {
            this.globalsessionResult = JSON.parse(sessionStorage.getItem('GlobalSearch'));
            globalFilterData = JSON.parse(sessionStorage.getItem('GlobalSearchData'));
            this.pagesService.setGlobalFilterData(globalFilterData);
            if (Object.keys(res).length === 0) {
              this.globalsessionResult['sort'] = this.activeSort
              this.EpCommonRequestCall(this.globalsessionResult)
            }
            else {
              res['sort'] = this.activeSort
              this.EpCommonRequestCall(res);
            }
          })
        }
        else if (params.id != 'search') {
          // let sessionStoredFilters = {}
          this.supplierID = params.id;
          this.supplierMongoId = params.supplierId;
          this.sessionStoredFilters = JSON.parse(sessionStorage.getItem('EPFilters'));
          if (this.sessionStoredFilters == null || this.sessionStoredFilters == undefined) {
            this.selectedSortingName = "Modified Date - Newest first";
            sessionStorage.setItem("supplierID", this.supplierID);
            if (this.supplierID != undefined || this.supplierID != '') {
              this.applyInvoiceFilters(false);
            }
          }
          else {
            let setsessionObj = this.sessionStoredFilters
            let sessionFromValue = setsessionObj['from'];
            let sessionSizeValue = setsessionObj['size'];
            this.pageNumber = (sessionFromValue / sessionSizeValue);
            this.pageSize = sessionSizeValue;
            let sessionTotalRecords = sessionStorage.getItem('totalRecors');
            this.totalRecords = sessionTotalRecords;
            this.paginator.length = this.totalRecords;
            let getSearchedSessionValue = JSON.parse(sessionStorage.getItem('searchedList'));
            if (getSearchedSessionValue == null) {
              this.searchedTextData = []
            }
            else {
              this.searchedTextData = getSearchedSessionValue;
            }
            let fetchSortingName = sessionStorage.getItem('sortingName');
            if (fetchSortingName != null) {
              this.selectedSortingName = fetchSortingName;
            } else {
              this.selectedSortingName = "Modified Date - Newest first";
            }

            if (this.sessionStoredFilters['where'].hasOwnProperty('activeEarlyPayment.status')) {
              this.isApplyButtonEnable = false;
              let filtervalue = this.sessionStoredFilters['where']['activeEarlyPayment.status']['value'];
              this.EPFilteresStatus = filtervalue;
              this.selectedStatusArray = this.EPFilteresStatus
              this.isFiltersShow = false;
              this.isClearButtonEnable = false;
            }
            if (this.sessionStoredFilters['where'].hasOwnProperty('isAbfrl')) {
              this.isApplyButtonEnable = false;
              this.isFiltersShow = false;
              this.isClearButtonEnable = false;
              let bizSetValue = this.sessionStoredFilters['where']['isAbfrl']['value'];
              if (bizSetValue == true) {
                this.SetBizSelectedValue.push('isAbfrl');
                this.selectedBizTypeStatus = this.SetBizSelectedValue;
              }
              else {
                this.SetBizSelectedValue.push('moglix');
                this.selectedBizTypeStatus = this.SetBizSelectedValue
              }

            }
            if (this.sessionStoredFilters['where'].hasOwnProperty('invoiceDate')) {
              this.isFiltersShow = false;
              this.isClearButtonEnable = false;
              if (this.sessionStoredFilters['where']['invoiceDate'].hasOwnProperty('custom')) {
                let slot = this.sessionStoredFilters['where']['invoiceDate']['custom'];
                this.customDateOption = slot;
                this.timeSlot = slot;
                this.setfilterValue = "Last" + " " + slot + " " + "Days";

              }
              else if (this.sessionStoredFilters['where']['invoiceDate'].hasOwnProperty('start_value')) {
                let getStartSelectedValue = this.sessionStoredFilters['where']['invoiceDate']['start_value'];
                let getEndSelectedValue = this.sessionStoredFilters['where']['invoiceDate']['end_value'];
                this.customDateOption = "-1";
                this.timeSlot = -1;
                this.startDate = getStartSelectedValue;
                this.endDate = getEndSelectedValue;
                this.setfilterValue = this.startDate + " " + this.endDate
                this.isApplyButtonEnable = false;
              }
            }
            if (!this.isCustomer) {
              if (this.sessionStoredFilters['where'].hasOwnProperty('invoiceNumber')) {
                let SearchSesionKey = this.sessionStoredFilters['where']['invoiceNumber'].value
                this.seachText = SearchSesionKey;
                if (this.seachText != null) {
                  this.showCloseIcon = true;
                  this.showSearchIcon = false;
                }
              }
            }
            this.EpCommonRequestCall(this.sessionStoredFilters);
          }
        }
        else {
          sessionStorage.clear();
          this.router.navigate(['/supplierLinks']);
        }
      })
    }
    else {
      this.applyInvoiceFilters(false);
    }
  }

  getConsumedLimit() {
    this.pagesService.usedEPAmount().subscribe((res) => {
      this.pagesService.offSpinner();
      this.isActive = res['result']['isActive'];
      this.istotalLimit = res['result']['totalLimit'];
      if (res['result']['totalLimit'] == null || res['result']['totalLimit'] == -1) {
        this.LimitLeft = "N.A"
      }
      else if (res['result']['usedLimit'] > res['result']['totalLimit']) {
        this.LimitLeft = 0
      }
      else if (res['result']['totalLimit'] != null || res['result']['totalLimit'] != -1) {
        let totalLimit = res['result']['totalLimit']
        let usedLimit = res['result']['usedLimit']
        this.LimitLeft = totalLimit - usedLimit
      }
      let intervalDays = res['result']['minDaysInterval']
      let formatDate = moment().add(intervalDays, 'days');
      this.paymentInBank = moment(formatDate).format("dddd, MMMM DD YYYY");
    })
  }

  applySupplierSearch() {
    if (this.seachText != null) {
      this.showCloseIcon = true;
      this.showSearchIcon = false;
    }
    else {
      this.showCloseIcon = false;
    }
    if (this.seachText != null && this.seachText.trim() == "") {
      this.searchQuery = false
    }
    else {
      this.searchQuery = true
    }
    this.applyInvoiceFilters(false);
  }
  CustomerReportName: string;
  downloadInvoiceResults() {
    if (this.isCustomer) {
      const dialogRef = this.dialog.open(ReportDownloadInvoiceComponent, {
        disableClose: true,
        width: '550px',
        height: '250px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != true) {
          this.CustomerReportName = result;
          this.isDownloadClicked = true;
          if (this.fetchURlParams.id == 'search') {
            this.globalsessionResult = JSON.parse(sessionStorage.getItem('GlobalSearch'));
            this.globalsessionResult['reportName'] = this.CustomerReportName;
            this.downloadInvoiceCommonRequest(this.globalsessionResult);
          }
          else {
            this.applyInvoiceFilters(true);
          }
        }
      });
    }
    else {
      this.isDownloadClicked = true;
      this.applyInvoiceFilters(true);
    }
  }

  datesUpdated(event) {
    if (event.startDate != null && event.endDate != null) {
      this.startDateMillisec = event.startDate._d
      this.startDate = moment(this.startDateMillisec).format('YYYY-MM-DD');
      this.EndDateMillisec = event.endDate._d
      this.endDate = moment(this.EndDateMillisec).format('YYYY-MM-DD');
    }
    this.setfilterValue = this.startDate + " " + this.endDate
  }
  cancelEPRequest() {
    const dialogRef = this.dialog.open(CancelEPDialogComponent, {
      disableClose: false,
      width: '550px',
      height: '420px',
      data: this.sendinvoiceID
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != true) {
        this.applyInvoiceFilters(true);
      }
    });
  }

  markEPDelayed() {
    const dialogRef = this.dialog.open(MarkDelayedEPDialogComponent, {
      disableClose: false,
      width: '550px',
      height: '420px',
      data: this.sendinvoiceID
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != true) {
        this.applyInvoiceFilters(true);
      }
    });

  }

  applyInvoiceFilters(reset) {
    if (reset == false) {
      this.dataQuery = true;
    }
    if (this.paginator != null && !reset) {
      this.paginator.firstPage();
    }

    this.invObj = {
      "size": -1,
      "from": 0,
      "where": {
      }
    }
    if (this.selectedPaymentHold && this.selectedPaymentHold.length < 2) {
      this.invObj['where']['gstHold'] = {
        "type": "search",
        "value": this.selectedPaymentHold == 'Gst Uploaded' ? 'false' : 'true'
      }
    }
    if (this.selectedStatusArray != null && this.selectedStatusArray.length > 0) {
      this.invObj['where']['activeEarlyPayment.status'] = {
        "type": "in",
        "value": this.selectedStatusArray
      }
    }
    if (this.timeSlot != null && this.customDateOption != -1 && this.customDateOption != null) {
      this.invObj['where']['invoiceDate'] = {
        "type": "range",
        "custom": this.timeSlot
      }
    }
    if (this.startDate != "No Date Selected") {
      if (this.startDate != null && this.endDate != null && this.customDateOption == -1) {
        this.invObj['where']['invoiceDate'] = {
          "type": "range",
          "start_value": this.startDate,
          "end_value": this.endDate
        }
      }
    }
    if (this.supplierID != '' && this.supplierID != undefined) {
      this.invObj['where']['supplierId'] = {
        "type": "search",
        "field": "supplierId",
        "value": this.supplierID
      }

    }

    if (this.selectedBizTypeStatus != null && this.selectedBizTypeStatus.length != 0) {
      if (this.selectedBizTypeStatus.length < 2) {
        if (this.selectedBizTypeStatus[0] == 'isAbfrl') {
          this.invObj['where']['isAbfrl'] = {
            "type": "search",
            "field": "isAbfrl",
            "value": true
          }
        }
        else if (this.selectedBizTypeStatus[0] == 'moglix') {
          this.invObj['where']['isAbfrl'] = {
            "type": "search",
            "field": "isAbfrl",
            "value": false
          }
        }
      }
    }
    if (this.searchedTextData != null && this.searchedTextData.length != 0) {
      let values = []
      for (let i = 0; i < this.searchedTextData.length; i++) {
        let selectedData = this.searchedTextData[i];
        let field: string;
        let value: string;
        if (selectedData.documentType == 'supplier') {
          field = 'supplierGSTN',
            value = selectedData.gstNo
        }
        else {
          field = 'invoiceNumber',
            value = selectedData.searchkey
        }
        values.push({
          "field": field,
          "value": value
        })
      }

      this.invObj['where']['invoiceNumber'] = {
        "type": "search",
        "operator": "AND",
        "value": values
      }
    }
    else {
      if (this.seachText != null && this.seachText.trim() != '') {
        this.invObj['where']['invoiceNumber'] = {
          "type": "search",
          "value": this.seachText
        }
      }
    }


    if ((this.timeSlot != null && this.customDateOption != -1 && this.customDateOption != null)
      || (this.selectedStatusArray != null && this.selectedStatusArray.length > 0)) {
      this.isClearButtonEnable = false
    }

    this.invObj['from'] = this.paginator.pageIndex * this.paginator.pageSize
    this.invObj['size'] = this.paginator.pageSize
    this.invObj["accessAs"] = this.isCustomer ? 'customerGSTN' : 'supplierGSTN';
    this.invObj['sort'] = this.activeSort
    this.searchText = '';
    sessionStorage.setItem('EPFilters', JSON.stringify(this.invObj));

    if (this.isDownloadClicked == true) {
      this.downloadInvoiceCommonRequest(this.invObj);
    }
    else if (this.isDownloadClicked == false) {
      this.EpCommonRequestCall(this.invObj)
    }
  }
  downloadInvoiceCommonRequest(invObj) {
    if (!this.isCustomer) {
      this.pagesService.downloadInvoiceResults(invObj).toPromise().then(res => {
        this.isDownloadClicked = false;
        this.downloadCSV('earlyPaymentResult.csv', res)
      }).catch(error => console.log(error))
    }
    else {
      this.invObj['reportName'] = this.CustomerReportName
      this.pagesService.downloadCustomerInvoices(invObj).toPromise().then(res => {
        this.isDownloadClicked = false;
        this._snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 5000,
          verticalPosition: this.verticalPosition,
          panelClass: 'snack-class'
        });
      }).catch(error => console.log(error))
    }
  }

  isTotalRecordsEmpty: boolean = false;
  EpCommonRequestCall(invObj) {
    invObj['from'] = this.paginator.pageIndex * this.paginator.pageSize ? this.paginator.pageIndex * this.paginator.pageSize : 0;
    invObj['size'] = this.paginator.pageSize
    if (this.authService.isCredlixUser()) {
      invObj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.id != 'search') {
        invObj['where']['supplierId'] = {
          "type": "search",
          "field": "supplierId",
          "value": params.id
        }
      }
    });
    this.pagesService.getEarlyPaymentInvoice(invObj).subscribe(list => {
      this.showListing = false;
      this.selection = new SelectionModel(true, []);
      this.earlyPaymentInvoices = list['result'];
      this.isTotalRecordsEmpty = true
      this.dataSource.data = list['result'];
      this.totalRecords = list['total'];
      this.paginator.length = this.totalRecords
      let headerData = list['fieldConfig'];
      this.createHeaders(headerData);
      const credlixUserId = localStorage.getItem("credlixUserID");
      // TODO: put this id in environment file so that we don't have to hardcode it
      if(this.authService.isJKFennerAnchor()){
        this.jkFennerCheck = true;
      }
      if (list != null) {
        if (list['result'].length > 0) {
          this.setSupplierName = list['result'][0]['supplierName'];
          this.setSupplierID = list['result'][0]['supplierId'];
          this.businessAccountId = list['result'][0]['supplierBusinessAccountId'];
          this.jkFennerCheck = list['result'][0]['isJkFennerInvoice'];
        }
      }
      sessionStorage.setItem('totalRecors', list['total']);
    })
  }

  downloadCSV(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }

  }

  resetSearchText() {
    this.showSearchIcon = true;
    this.showCloseIcon = false;
    this.seachText = null;
    this.searchedTextData = []
    this.searchQuery = false;
    if (this.paginator != null) {
      this.paginator.firstPage();
    }
    this.applyInvoiceFilters(true);
  }
  resetInvoiceFilters(event, el, val) {
    sessionStorage.removeItem('EPFilters');
    sessionStorage.removeItem('searchedList');
    sessionStorage.removeItem('sortingName');
    sessionStorage.removeItem("totalRecors");
    this.dataQuery = false;
    this.selectedStatusArray = [];
    this.selectedDate = null;
    this.timeSlot = null;
    this.startDate = "";
    this.endDate = "";
    event.preventDefault();
    this.matRef.options.forEach((data: MatOption) => data.deselect());
    if (this.matRef1) {
      this.matRef1.options.forEach((data: MatOption) => data.deselect());
    }
    if (this.customDateOption && this.customDateOption === el.value) {
      el.checked = false;
      this.customDateOption = null;
    } else {
      this.customDateOption = el.value
      el.checked = true;
    }
    for (let i = 0; i < this.statusFilterList.length; i++) {
      this.statusFilterList[i].isChecked = val;
    }
    if (this.paginator != null) {
      this.paginator.firstPage()
    }
    this.isClearButtonEnable = true;
    this.isApplyButtonEnable = true;
    this.applyInvoiceFilters(true)
  }

  getCustomSelectedDate(item) {
    this.timeSlot = item;
    this.setfilterValue = null;
    if (item == -1) {
      this.startDate = "No Date Selected";
      this.endDate = "";
      this.setfilterValue = "No Date Selected";
    }
    else {
      this.setfilterValue = "Last" + " " + item + " " + "Days";
    }
    this.selectedDate = null;
    if (this.timeSlot != null) {
      this.isClearButtonEnable = false;
      this.isApplyButtonEnable = false;
    }
  }

  checkSupplierLimit(row) {
    let isSelected = !this.selection.isSelected(row);
    let amount = 0;
    let docs = this.selection.selected
    if (docs != null) {
      if (docs.length < 1) {
        this.limitExceeded = false;

      }
      if (row != null && isSelected) {
        docs.push(row)
      }
      else if (row != null && isSelected == false) {
        let index = docs.findIndex((doc) => {
          return doc.id == row.id
        })
        docs.splice(index, 1);
      }
      if (docs.length > 0) {
        this.isApproveButtonEnable = false;
      }
      if (docs.length < 1) {
        this.limitExceeded = false;
        this.isApproveButtonEnable = true;
      }
      for (let i = 0; i < docs.length; i++) {
        amount = amount + Number(docs[i]['epAmount'])
      }
    }
    if ((Math.round(this.LimitLeft * 100) / 100) - amount < 0) {
      this.limitExceeded = true;
      this.isButtonEnable = true;
    }
    else {
      this.limitExceeded = false;
      this.isButtonEnable = false;
    }
    if (amount > 0) {
      this.selectedAmount = amount
    }
    if (docs.length < 1) {
      this.isButtonEnable = true;
    }
  }

  isAllSelected() {
    this.sendinvoiceID = this.selection.selected;
    if (this.sendinvoiceID != null && this.sendinvoiceID.length > 0) {
      this.isApproveButtonEnable = false;
      this.isButtonEnable = false;
      for (let i = 0; i < this.sendinvoiceID.length; i++) {
        if (this.sendinvoiceID[i].epStatus == 'Supply Approval Awaited' || this.sendinvoiceID[i].epStatus == 'Finance Approval Awaited') {
          this.checkApprovalEnable = false;
        }
      }
    }
    else if (this.sendinvoiceID == '' || this.sendinvoiceID.length < 1) {
      this.isApproveButtonEnable = true;
      this.isButtonEnable = true;
    }
    const numSelected = this.selection.selected.length;
    const numRowsMinusExcluded = this.dataSource.data
      .filter((row) => {
        if (this.authService.isCredlixUser()) {
          return row.epStatus == 'Eligible'
        }
        else if (!this.isCustomer) {
          return row.epStatus == 'Eligible'
        }
        else {
          return row.epStatus == 'Supply Approval Awaited' || row.epStatus == 'Finance Approval Awaited';
        }
      }).length;
    return numSelected === numRowsMinusExcluded;
  }

  masterToggle() {
    if (this.isCustomer == true && !this.authService.isCredlixUser()) {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => {
          if (row.epStatus == 'Supply Approval Awaited' || row.epStatus == 'Finance Approval Awaited') {
            this.selection.select(row);
          }
        });
    }
    else if (this.authService.isCredlixUser()) {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => {
          if (row.epStatus == 'Eligible') {
            this.selection.select(row);
          }
        });

    }
    else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => {
          if (row.epStatus == 'Eligible') {
            this.selection.select(row);
          }

        });
    }
  }

  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;

  }

  requestEarlyPayment() {
    let selectedArray = this.selection.selected
    let InvoiceID = []
    for (let i = 0; i < selectedArray.length; i++) {
      InvoiceID.push(selectedArray[i])
    }

    let obj = {
      data: this.earlyPaymentInvoices,
      selectedInvoiceDetails: InvoiceID,
      limit: this.LimitLeft
    }
    const dialogRef = this.dialog.open(RequestEarlyPaymentComponent, {
      disableClose: true,
      width: '450px',
      height: '100vh',
      data: obj,
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isButtonEnable = true;
      // this.selection = new SelectionModel(true, []);
      if (result != true) {
        if (this.globalsessionResult != null) {
          this.EpCommonRequestCall(this.globalsessionResult);
        }
        else {
          this.applyInvoiceFilters(false);
        }
      }
    });
  }
  activeItem = 'Modified Date - Newest first';
  sortData(event, columnName, direction, sortingName) {
    this.activeItem = sortingName;
    this.selectedSortingName = sortingName;
    sessionStorage.setItem("sortingName", sortingName);
    this.sortColumnName = columnName;
    this.sortDirection = direction;
    if (event != null && event['active'] != null) {
      this.activeSort = {}
      this.activeSort['field'] = columnName
      this.activeSort['order'] = direction;
      if (this.paginator != null) {
        this.paginator.firstPage();
      }
      if (this.fetchURlParams != null && this.fetchURlParams != undefined) {
        if (this.fetchURlParams.id == 'search') {
          this.globalsessionResult['sort'] = this.activeSort
          this.EpCommonRequestCall(this.globalsessionResult)
        }
        else {
          this.applyInvoiceFilters(true);
        }
      }
      else {
        this.applyInvoiceFilters(true);
      }
    }
  }


  getPaginatorData(event) {
    // this.applyInvoiceFilters(true);
    if (this.fetchURlParams != null && this.fetchURlParams != undefined) {
      if (this.fetchURlParams.id == 'search') {
        this.EpCommonRequestCall(this.globalsessionResult)
      }
      else {
        this.applyInvoiceFilters(true);
      }
    }
    else {
      this.applyInvoiceFilters(true);
    }
  }

  requestPaymentBySingleRow(element) {
    let elementArray = []
    if (this.selection.selected.length > 0)
      elementArray = this.selection.selected;
    else
      elementArray.push(element)
    let obj = {
      selectedInvoiceDetails: elementArray,
      limit: this.LimitLeft,
      singleRow: true
    }
    const dialogRef = this.dialog.open(RequestEarlyPaymentComponent, {
      disableClose: true,
      width: '450px',
      height: '100vh',
      data: obj,
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isButtonEnable = true;
      this.selection = new SelectionModel(true, []);
      if (result != true) {
        if (this.fetchURlParams && this.fetchURlParams.id == 'search' && sessionStorage.getItem("searchedText")) {
          this.EpCommonRequestCall(this.globalsessionResult);
        }
        else if (this.fetchURlParams != null && this.fetchURlParams.id == 'search') {
          this.EpCommonRequestCall(this.sessionStoredFilters);
        }
        else {
          this.applyInvoiceFilters(false);
        }
      }
    });
    // }
  }
  getAmount(amount) {
    return amount.toLocaleString('en-IN')
  }

  getInvoiceStatus(status) {
    if (status == 'PARTIALLY_PAID') {
      return 'Partially Settled'
    }
    else if (status == 'PAID') {
      return 'Fully Settled'
    }
    else if (status == 'SUPPLY_APPROVAL_AWAITED') {
      return 'Supply Approval Awaited'
    }
    else if (status == 'FINANCE_APPROVAL_AWAITED') {
      return 'Finance Approval Awaited'
    }
    else if (status == 'APPROVAL_AWAITED') {
      return 'Approval Awaited'
    }
    else if (status == 'EXPIRED') {
      return 'Expired'
    }
    else if (status == 'CANCELLED') {
      return 'Cancelled'
    }
    else if (status == 'ON_HOLD') {
      return "On Hold"
    }
    else {
      return 'Approval Awaited'
    }
  }

  SetApprovalSuppliers(value) {
    let selectedArray = this.selection.selected
    let InvoiceID = []
    for (let i = 0; i < selectedArray.length; i++) {
      InvoiceID.push(selectedArray[i].id)
    }
    let request = {
      'invoiceIds': InvoiceID
    }
    if (value == 'supply') {
      request['supplyApproval'] = true
    }
    else if (value == 'finance') {
      request['financeApproval'] = true
    }
    this.pagesService.updateInvoiceEPEligibility(request).subscribe((resp) => {
      if (resp['result'] == true) {
        this.pagesService.offSpinner();
        this.toasterService.success("successfully Approved");
        this.isApproveButtonEnable = true;
        this.loadInitProcess();
        this.selection = new SelectionModel(true, []);
      }
    })
  }

  filtersDisplay() {
    this.isFiltersShow = !this.isFiltersShow;
  }

  onEPStatusChange(selectedList) {
    this.EPStatus = selectedList;
    this.selectedStatusArray = selectedList;
    if (this.EPStatus.length > 0) {
      this.isClearButtonEnable = false;
      this.isApplyButtonEnable = false;
    }
    else if (this.EPStatus.length == 0) {
      if (this.timeSlot != null) {
        this.isClearButtonEnable = false
        this.isApplyButtonEnable = false;
      }
      else if (this.selectedBizTypeStatus != null || this.selectedBizTypeStatus.length != 0) {
        this.isClearButtonEnable = false
        this.isApplyButtonEnable = false;
      }
      else if ('activeEarlyPayment.status' in this.invObj['where']) {
        this.isClearButtonEnable = false;
        this.isApplyButtonEnable = true;
      }
      else {
        this.isClearButtonEnable = true
        this.isApplyButtonEnable = true;
      }
    }
  }

  onBizTypeChange(typeList) {
    this.BizTypeCheckedList = typeList;
    this.selectedBizTypeStatus = typeList;
    if (this.BizTypeCheckedList.length > 0) {
      this.isClearButtonEnable = false;
      this.isApplyButtonEnable = false;
    }
    else if (this.BizTypeCheckedList.length == 0) {
      if (this.timeSlot != null) {
        this.isClearButtonEnable = false
        this.isApplyButtonEnable = false;
      }
      else if (this.selectedStatusArray != null || this.selectedStatusArray.length != 0) {
        this.isClearButtonEnable = false
        this.isApplyButtonEnable = false;
      }
      else if ('isAbfrl' in this.invObj['where']) {
        this.isClearButtonEnable = false;
        this.isApplyButtonEnable = true;
      }
      else {
        this.isClearButtonEnable = false
        this.isApplyButtonEnable = true;
      }
    }
  }

  onPaymentHoldChange(event: any) {
    console.log('onPaymentHoldChange', event);
    this.selectedPaymentHold = event;
    if (this.SetPaymentSelectedValue.length > 0) {
      this.isApplyButtonEnable = false;
    }
  }

  getRecord(row, status) {
    if (status === "invoiceNumber") {
      this.pagesService.setinvoiceRecords(row);
      window.open('/#/listing/invoice/' + row.id, "_blank");
    }
  }

  breadcrumNavigation() {
    sessionStorage.clear();
    this.pagesService.getClickEvent('clicked');
    this.router.navigate(['/supplierLinks']);
  }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    this.router.navigate(['/supplierLinks']);
  }

  getStatus(status) {
    if (status === 'invoiceNumber' || status === 'epStatus' || status === 'image' || status === 'invoiceStatus' || status === 'delayStatus')
      return true;
    else
      return false;
  }

  //setting css for status column
  getClass(status, element) {
    var k = {
      "Finance Approval Awaited": {
        "color": "status early_status4",
        "value": "Finance Approval Awaited"
      },
      "Supply Approval Awaited": {
        "color": "status early_status4",
        "value": "Supply Approval Awaited"
      },
      "Eligible": {
        "color": "status early_status1",
        "value": "Eligible"
      },
      "Requested": {
        "color": "status early_status2",
        "value": "Requested"
      },
      "Partially Paid": {
        "color": "status early_status3",
        "value": "Partially Paid"
      },
      "Paid": {
        "color": "status early_status3",
        "value": "Paid"
      },
      "Expired": {
        "color": "status early_status4",
        "value": "Expired"
      },
      "On Hold": {
        "color": "status early_status4",
        "value": "On Hold"
      },
      "Overdue": {
        "color": "status early_status2",
        "value": "Overdue"
      },
      "Open": {
        "color": "status early_status1",
        "value": "Open"
      }
    };
    if (status === "epStatus" || status == 'invoiceStatus') {
      let v = k[element[status]];
      if (v)
        return v["color"];
    }
    else if (status === "invoiceNumber") {
      return "overtext";
    } else {
      return {};
    }
  }

  //creating columns dynamically for ep table
  createHeaders = (config) => {
    this.displayedColumns1 = [];
    this.displayedColumns = [];
    config.map((each, id) => {
      if (id == 4) {
        this.displayedColumns1.push('image');
      }

      if (each['display'] === false) {
        each['key'] = " ";
      }
      this.displayedColumns1.push(each.field);
      each['cell'] = (element: any) => {
        if (each['type'] == null)
          return _.get(element, each.field);
        else if (each['type'] === "date") {
          return this.datePipe.transform(_.get(element, each.field), 'dd-MM-yyyy');
        }
        else {
          return _.get(element, each.field);
        }
      }
    });
    this.displayedColumns1.push('action');
    this.displayedColumns1.unshift('select');
    this.displayedColumns = config;
  }
  refreshStatus(id){
    this.pagesService.refreshEpStatus(id).subscribe(res =>{
        this.toasterService.success(res['result']);
        this.loadInitProcess()
    })
  }
  updateInvoiceStatus(){
    this.pagesService.bulkInvoicesSync(this.supplierMongoId).subscribe({
      next:(resp:any)=>{
        this.toasterService.success(resp.result);
      },
      error:(err:any)=>this.toasterService.error('Something Went Wrong')
    })
  }

  //adding tooltip in status column
  getToolTip(status, element) {
    if (status === "epStatus" && element.epRequestDate) {
      return element.epRequestDate;
    }
    else if (status == 'supplierName') {
      return element.supplierName
    }
    else if (status == 'credit_terms') {
      return element.credit_terms
    }
    else if (status == 'customerName') {
      return element.customerName
    }
    else if (status == 'invoiceNumber') {
      return element.invoiceNumber
    }
  }

  continueWithOnboard() {
    this.router.navigate(['/OnBoard']);
  }
}
