<mat-dialog-content class="email-popup-content">
  <div class="form-content">
    <button mat-icon-button mat-dialog-close aria-label="Close popup" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
    <p class="label"><strong>Name: </strong>{{name}}</p>
    <form #emailForm="ngForm" class="email-form">
      <div class="input-field">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" placeholder="Enter your email" [(ngModel)]="email"
          #emailModel="ngModel" required email>
      </div>
      <div *ngIf="emailModel.invalid && emailModel.touched" class="error-messages">
        <p *ngIf="emailModel.errors.required">Email is required.</p>
        <p *ngIf="emailModel.errors.email">Please enter a valid email.</p>
      </div>
    </form>
  </div>

  <div class="actions">
    <button mat-raised-button color="primary" (click)="sendToUser()" [disabled]="emailForm.invalid">Send to
      User</button>
  </div>
</mat-dialog-content>