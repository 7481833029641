import { Component, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, Form, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material'
import { ThemePalette } from '@angular/material/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { element, EventEmitter } from 'protractor'
import { CsvExportService } from 'src/app/services/csv-export.service'
import { KanbanService } from '../../../kanban.service'
import { PagesService } from 'src/app/pages/pages.service'

export interface Task {
	name: string
	completed: boolean
	color: ThemePalette
	subtasks?: Task[]
}

@Component({
	selector: 'app-new-leads',
	templateUrl: './new-leads.component.html',
	styleUrls: ['./new-leads.component.scss'],
})
export class NewLeadsComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger

	file: any
	isProgressVisible = false
	panelOpenState = true
	states = statesOfIndia
	showSave: boolean = true
	fieldEnable: boolean = true
	laneData: any
	laneTaskId: any
	name = 'Buyer'
	dataSource1: MatTableDataSource<any> = new MatTableDataSource([])
	dataSource2: MatTableDataSource<any> = new MatTableDataSource([])
	displayColumns1: Array<string> = ['BuyerName', 'BuyerAddress', 'BuyerRating', 'BuyerTurnOver', 'LimitRequested', 'TendorDays', 'valueOfPo', 'Action']
	displayColumns2: Array<string> = ['SupplierName', 'SupplierAddress', 'SupplierRating', 'SupplierTurnOver', 'LimitRequested', 'TendorDays', 'valueOfPo', 'Action']
	piCheck: boolean = false
	sidCheck: boolean = false
	newLeadForm: FormGroup
	newLeadData: any = {}
	constructor(
		private fb: FormBuilder,
		public toasterService: ToastrService,
		public csvService: CsvExportService,
		private activatedRoute: ActivatedRoute,
		private pagesService: PagesService,
		public kService: KanbanService,
	) {}

	task: Task = {
		name: 'Checl All',
		completed: false,
		color: 'primary',
		subtasks: [
			{ name: 'Check 1', completed: false, color: 'primary' },
			{ name: 'Check 2', completed: false, color: 'primary' },
			{ name: 'Check 3', completed: false, color: 'primary' },
		],
	}

	allComplete: boolean = false

	someComplete(): boolean {
		return this.task.subtasks.filter((t) => t.completed).length > 0 && !this.allComplete
	}

	setAll(completed: boolean) {
		this.allComplete = completed
		this.task.subtasks.forEach((t) => (t.completed = completed))
	}

	updateAllComplete() {
		this.allComplete = this.task.subtasks != null && this.task.subtasks.every((t) => t.completed)
	}

	cancel() {
		this.menuTrigger.closeMenu()
	}

	rmeoveSelectedFile() {
		this.file = null
	}

	ngOnInit() {
		this.initialiseForm()
		this.newLeadForm.get('businessProduct').valueChanges.subscribe((x) => this.businessChanged())
		this.activatedRoute.params.subscribe((x) => {
			this.laneTaskId = x.id
			this.kService.setLaneTaskId(x.id)
			this.fetchdata(x.id)
		})
	}
	fetchdata(x) {
		if (x !== null && x !== 'undefined') {
			this.kService.fetchData(x).subscribe({
				next: (resp: any) => {
					this.kService.setLaneData(resp.result)
					this.editable(resp)
					this.newLeadData = resp.result.domesticData['NEW_LEADS']
					this.newLeadForm.get('supplierName').setValue(resp.result.name)
					// this.newLeadForm.get('supplierName').setValue(this.newLeadData && this.newLeadData.supplierName ? this.newLeadData.supplierName : '');
					this.newLeadForm.get('supplierTurnover').setValue(this.newLeadData && this.newLeadData.supplierTurnover ? this.newLeadData.supplierTurnover : '')
					this.newLeadForm.get('typeOfCompany').setValue(this.newLeadData && this.newLeadData.typeOfCompany ? this.newLeadData.typeOfCompany : '')
					this.newLeadForm.get('businessIncorporationYear').setValue(this.newLeadData && this.newLeadData.businessIncorporationYear ? this.newLeadData.businessIncorporationYear : '')
					this.newLeadForm.get('businessProduct').setValue(this.newLeadData && this.newLeadData.businessProduct ? this.newLeadData.businessProduct : '')
					this.newLeadForm.get('natureOfBusiness').setValue(this.newLeadData && this.newLeadData.natureOfBusiness ? this.newLeadData.natureOfBusiness : '')
					this.newLeadForm.get('totalFundingRequirement').setValue(this.newLeadData && this.newLeadData.totalFundingRequirement ? this.newLeadData.totalFundingRequirement : '')
					this.newLeadForm.get('addressLine1').setValue(this.newLeadData && this.newLeadData.addressLine1 ? this.newLeadData.addressLine1 : '')
					this.newLeadForm.get('addressLine2').setValue(this.newLeadData && this.newLeadData.addressLine2 ? this.newLeadData.addressLine2 : '')
					this.newLeadForm.get('city').setValue(this.newLeadData && this.newLeadData.city ? this.newLeadData.city : '')
					this.newLeadForm.get('state').setValue(this.newLeadData && this.newLeadData.state ? this.newLeadData.state : '')
					this.newLeadForm.get('pincode').setValue(this.newLeadData && this.newLeadData.pincode ? this.newLeadData.pincode : '')
					// this.newLeadForm.get('probabilityToConvert').setValue(this.newLeadData && this.newLeadData.probabilityToConvert ? this.newLeadData.probabilityToConvert : '');
					this.newLeadForm.get('leadSource').setValue(this.newLeadData && this.newLeadData.leadSource ? this.newLeadData.leadSource : '')
					// this.newLeadForm.get('goLiveMonth').setValue(this.newLeadData && this.newLeadData.goLiveMonth ? this.newLeadData.goLiveMonth : '');
					this.newLeadForm.get('relationshipManager').setValue(this.newLeadData && this.newLeadData.relationshipManager ? this.newLeadData.relationshipManager : '')
					this.newLeadForm.get('salesReason').setValue(this.newLeadData && this.newLeadData.salesReason ? this.newLeadData.salesReason : '')
					if (this.newLeadData && this.newLeadData.contact.length > 0) {
						while (this.formArr.length) {
							this.formArr.removeAt(0)
						}
						this.newLeadData.contact.map((item) => this.fb.group(item)).forEach((item) => this.formArr.push(item))
					} else {
						this.formArr.push(this.createContactForm())
					}
				},
			})
		}
	}
	editable(resp) {
		this.kService.hasAccess.subscribe((access) => {
			this.kService.laneListIds.subscribe((x) => {
				if (x && Object.keys(x).length > 0) {
					let obj = x.filter((element) => element.laneName == 'new-leads')
					if (resp.result.laneId == obj[0].laneId && access) {
						this.showSave = true
						this.fieldEnable = true
						this.newLeadForm.enable()
					} else {
						this.showSave = false
						this.fieldEnable = false
						this.newLeadForm.disable()
					}
				}
			})
		})
	}
	initialiseForm() {
		this.newLeadForm = this.fb.group({
			supplierName: [null, Validators.required],
			supplierTurnover: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
			typeOfCompany: [null, Validators.required],
			businessIncorporationYear: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
			businessProduct: [null, Validators.required],
			natureOfBusiness: [null, Validators.required],
			totalFundingRequirement: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
			addressLine1: [null, Validators.required],
			addressLine2: [null],
			city: [null, Validators.required],
			state: [null, Validators.required],
			pincode: [null, [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
			// probabilityToConvert: [null],
			leadSource: [null],
			// goLiveMonth: [null],
			relationshipManager: [null],
			salesReason: [null],
			contact: this.fb.array([]),
		})
	}

	createContactForm() {
		return this.fb.group({
			name: [null, Validators.required],
			email: [null, Validators.required],
			// email: [null, Validators.required, [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
			phone: [null, [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
			designation: [null, [Validators.required]],
			isPrimary: [false, Validators.required],
		})
	}

	createBuyerForm() {
		return this.fb.group({
			buyerName: [null, Validators.required],
			buyerAddress: [null, Validators.required],
			buyerRating: [null, Validators.required],
			valueOfPo: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
			buyerTurnover: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
			limitRequested: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
			tenorDaysRequested: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
		})
	}
	createSupplierForm() {
		return this.fb.group({
			supplierName: [null, Validators.required],
			supplierAddress: [null, Validators.required],
			supplierRating: [null, Validators.required],
			valueOfPo: [null, Validators.required],
			supplierTurnover: [null, [Validators.required]],
			limitRequested: [null, Validators.required],
			tenorDaysRequested: [null, Validators.required],
		})
	}

	restrictPhoneLength(event: any) {
		if (event.target.value.length > 9) {
			return false
		}
	}

	restrictPincodeLength(event: any) {
		if (event.target.value.length > 5) {
			return false
		}
	}

	restrictToLetters(event: KeyboardEvent): void {
		const inputChar = String.fromCharCode(event.keyCode)
		if (!/^[a-zA-Z]*$/.test(inputChar)) {
			event.preventDefault()
		}
	}
	restrcitNumbersOnly(event: Event): void {
		const inputElement = event.target as HTMLInputElement
		let inputValue = inputElement.value
		inputValue = inputValue.replace(/\D/g, '')
		inputElement.value = inputValue
	}
	restrictyearLength(event: any): void {
		if (event.target.value.length > 4) {
			event.target.value = event.target.value.slice(0, 4)
		}
	}

	get formArr() {
		return this.newLeadForm.get('contact') as FormArray
	}
	get formArrBuyer() {
		return this.newLeadForm.get('buyer') as FormArray
	}
	get formArrSupplier() {
		return this.newLeadForm.get('supplier') as FormArray
	}
	submitForm() {
		if (this.formArr.length == 1) {
			this.formArr.at(0).get('isPrimary').setValue(true)
		}
		if (this.formArr.length > 0) {
			let count = 0
			for (let i = 0; i < this.formArr.length; i++) {
				if (this.formArr.at(i).get('isPrimary').value) {
					count = count + 1
				}
			}
			if (count == 0) {
				this.toasterService.error('Please Set One Primary Contact!')
				return
			}
		}
		if (this.newLeadForm.valid) {
			this.kService.saveNewLead(this.newLeadForm.value, this.laneTaskId).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.toasterService.success(resp.message)
						this.fetchdata(this.laneTaskId)
					} else {
						this.toasterService.error(resp.message)
					}
				},
				error: (err: any) => this.toasterService.error(err.message),
			})
		} else {
			console.log(this.newLeadForm.controls)
			this.focusFirstInvalidField(this.newLeadForm)
			this.toasterService.error('Please fill all required fields')
		}
	}

	private focusFirstInvalidField(form): void {
		for (const field in form.controls) {
			if (field != 'contact' && form.controls[field].invalid) {
				const invalidControl = form.controls[field]
				const invalidFieldElement = document.getElementById(field)
				console.log('field name', field)
				if (invalidFieldElement) {
					invalidControl.markAsTouched()
					invalidFieldElement.focus()
				}
				return
			} else if (field == 'contact' && form.controls[field].invalid) {
				for (let contactField in form.controls['contact'].controls[0].controls) {
					let errorFormcontrol = form.controls['contact'].controls[0].controls[contactField]
					if (errorFormcontrol.invalid) {
						const invalidControl = form.controls[field]
						const invalidFieldElement = document.getElementById(contactField)
						console.log('field name', form.controls['contact'].controls[0].controls[contactField])
						if (invalidFieldElement) {
							invalidControl.markAsTouched()
							invalidFieldElement.focus()
						}
						return
					}
				}
			}
		}
	}

	addNewRow(index) {
		let itemArr = this.formArr.at(index) as FormGroup
		console.log(itemArr.valid)
		if (itemArr.valid) {
			this.formArr.push(this.createContactForm())
		} else {
			this.toasterService.error('Please Add Details Before Adding New Field')
		}
	}
	deleteRow(index: number) {
		this.formArr.removeAt(index)
	}
	addNewRowBuyer(index) {
		let itemArr = this.formArrBuyer.at(index) as FormGroup
		if (itemArr) {
			this.formArrBuyer.push(this.createBuyerForm())
			this.dataSource1.data = this.formArrBuyer.controls
		} else {
			this.toasterService.error('Please Add Details Before Adding New Field')
		}
	}
	deleteRowBuyer(index: number) {
		this.formArrBuyer.removeAt(index)
		this.dataSource1.data = this.formArrBuyer.controls
	}
	addNewRowSupplier(index) {
		let itemArr = this.formArrSupplier.at(index) as FormGroup
		if (itemArr.valid) {
			this.formArrSupplier.push(this.createSupplierForm())
			this.dataSource2.data = this.formArrSupplier.controls
		} else {
			this.toasterService.error('Please Add Details Before Adding New Field')
		}
	}
	deleteRowSupplier(index: number) {
		this.formArrSupplier.removeAt(index)
		this.dataSource2.data = this.formArrSupplier.controls
	}
	primarySet(index) {
		for (let i = 0; i < this.formArr.length; i++) {
			if (i != index) {
				this.formArr.controls[i].get('isPrimary').setValue(false)
			} else {
				this.formArr.controls[i].get('isPrimary').setValue(true)
			}
		}
	}
	businessChanged() {
		let val = this.newLeadForm.get('businessProduct').value
		if (val == 'SID') {
			this.sidCheck = true
			this.piCheck = false
			this.name = 'Buyer'
			this.kService.setBuyerValue('Buyer')
			this.newLeadForm.addControl('buyer', this.fb.array([]))
			if (this.newLeadData && this.newLeadData.buyer && this.newLeadData.buyer.length > 0) {
				while (this.formArrBuyer && this.formArrBuyer.length > 0) {
					this.formArrBuyer.removeAt(0)
				}
				let data1 = this.newLeadData && this.newLeadData.buyer ? this.newLeadData.buyer : []
				data1.map((item) => this.fb.group(item)).forEach((item) => this.formArrBuyer.push(item))
				this.dataSource1.data = this.formArrBuyer && this.formArrBuyer.controls ? this.formArrBuyer.controls : []
			} else {
				this.formArrBuyer.push(this.createBuyerForm())
				this.dataSource1.data = this.formArrBuyer.controls
			}
			if (this.newLeadForm.get('supplier')) {
				this.newLeadForm.removeControl('supplier')
			}
		} else if (val == 'PO' || val == 'PID') {
			this.piCheck = true
			this.sidCheck = false
			this.name = 'Supplier'
			this.kService.setBuyerValue('Supplier')
			this.newLeadForm.addControl('supplier', this.fb.array([]))
			if (this.newLeadData && this.newLeadData.supplier && this.newLeadData.supplier.length > 0) {
				while (this.formArrSupplier && this.formArrSupplier.length > 0) {
					this.formArrSupplier.removeAt(0)
				}
				let data2 = this.newLeadData && this.newLeadData.supplier ? this.newLeadData.supplier : []
				data2.map((item) => this.fb.group(item)).forEach((item) => this.formArrSupplier.push(item))
				this.dataSource2.data = this.formArrSupplier && this.formArrSupplier.controls ? this.formArrSupplier.controls : []
			} else {
				this.formArrSupplier.push(this.createSupplierForm())
				this.dataSource2.data = this.formArrSupplier.controls
			}
			if (this.newLeadForm.get('buyer')) {
				this.newLeadForm.removeControl('buyer')
			}
		}
	}
	downloadCsvFile() {
		let val = this.newLeadForm.get('businessProduct').value
		if (val == 'SID') {
			this.csvService.downloadCsv([], 'SID_Template', ['Buyer Name', 'Buyer Address', 'Buyer Rating', 'Buyer Turn Over', 'Limit Requested', 'Tenor Days', 'Value Of PO For Next 6 Months'])
		} else if (val == 'PID' || val == 'PO') {
			this.csvService.downloadCsv([], 'PID_PO_Template', [
				'Supplier Name',
				'Supplier Address',
				'Supplier Rating',
				'Supplier Turn Over',
				'Limit Requested',
				'Tenor Days',
				'Value Of PO For Next 6 Months',
			])
		} else {
			this.toasterService.error('Please Select Business Product Before Downloading Template')
		}
	}
	fileUpload(event: any) {
		this.isProgressVisible = true
		if (!this.newLeadForm.get('businessProduct').value) {
			this.toasterService.error('Please Select Business Product Before Uploading Buyer Data')
			return
		}
		if (event[0]) {
			this.file = event[0]
		} else {
			this.file = event.target.files[0]
		}
		const uploadData = new FormData()
		uploadData.append('file', this.file)
		this.pagesService.getUploadMappingHeaders(uploadData).subscribe({
			next: (resp: any) => {
				let response = resp.result.data
				if (this.newLeadForm.get('businessProduct').value == 'SID') {
					if (response[0]['Supplier Name'] || response[0]['Supplier Address'] || response[0]['Supplier Rating']) {
						this.toasterService.error('Please Upload Correct Data!')
						return
					}
					resp.result.data.forEach((element) => {
						const newField = this.fb.group({
							buyerName: [element['Buyer Name'] || element['﻿Buyer Name'], Validators.required],
							buyerAddress: [element['Buyer Address'], Validators.required],
							buyerRating: [element['Buyer Rating'], Validators.required],
							valueOfPo: [element['Value Of PO For Next 6 Months'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
							buyerTurnover: [element['Buyer Turn Over'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
							limitRequested: [element['Limit Requested'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
							tenorDaysRequested: [element['Tenor Days'] || element['﻿Tenor Days'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
						})
						if (!this.formArrBuyer.at(0).get('buyerName').value) {
							this.formArrBuyer.removeAt(0)
						}
						this.formArrBuyer.push(newField)
					})
					this.dataSource1.data = this.formArrBuyer.controls
				} else {
					if (response[0]['Buyer Name'] || response[0]['Buyer Address'] || response[0]['Buyer Rating']) {
						this.toasterService.error('Please Upload Correct Data!')
						return
					}
					resp.result.data.forEach((element) => {
						const newField = this.fb.group({
							supplierName: [element['Supplier Name'], Validators.required],
							supplierAddress: [element['Supplier Address'], Validators.required],
							supplierRating: [element['Supplier Rating'], Validators.required],
							valueOfPo: [element['Value Of PO For Next 6 Months'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
							supplierTurnover: [element['Supplier Turn Over'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
							limitRequested: [element['Limit Requested'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
							tenorDaysRequested: [element['Tenor Days'] || element['﻿Tenor Days'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
						})
						const formarray = this.newLeadForm.get('supplier') as FormArray
						// const
						console.log(this.checkFieldAtIndex(0, 'supplierName', this.formArrSupplier).value)
						if (!this.checkFieldAtIndex(0, 'supplierName', this.formArrSupplier).value) {
							this.formArrSupplier.removeAt(0)
						}
						this.formArrSupplier.push(newField)
					})
					this.dataSource2.data = this.formArrSupplier.controls
				}
			},
			complete: () => {
				// this.file=''
			},
		})
	}
	//   isSaveDisable(){
	//     if(this.newLeadForm )
	//     return !this.newLeadForm.valid;
	//   }
	checkFieldAtIndex(index: number, fieldName: string, formArr): FormControl {
		const formGroup = formArr.at(index) as FormGroup
		return formGroup.get(fieldName) as FormControl
	}
}

// Example usage to check the 'name' field at index 1const nameControl = checkFieldAtIndex(1, 'name');

const statesOfIndia = [
	{ city: 'ANDHRA_PRADESH', name: 'Andhra Pradesh' },
	{ city: 'ARUNACHAL_PRADESH', name: 'Arunachal Pradesh' },
	{ city: 'ASSAM', name: 'Assam' },
	{ city: 'BIHAR', name: 'Bihar' },
	{ city: 'CHHATTISGARH', name: 'Chhattisgarh' },
	{ city: 'DELHI', name: 'Delhi' },
	{ city: 'GOA', name: 'Goa' },
	{ city: 'GUJARAT', name: 'Gujarat' },
	{ city: 'HARYANA', name: 'Haryana' },
	{ city: 'HIMACHAL_PRADESH', name: 'Himachal Pradesh' },
	{ city: 'JHARKHAND', name: 'Jharkhand' },
	{ city: 'KARNATAKA', name: 'Karnataka' },
	{ city: 'KERALA', name: 'Kerala' },
	{ city: 'MADHYA_PRADESH', name: 'Madhya Pradesh' },
	{ city: 'MAHARASHTRA', name: 'Maharashtra' },
	{ city: 'MANIPUR', name: 'Manipur' },
	{ city: 'MEGHALAYA', name: 'Meghalaya' },
	{ city: 'MIZORAM', name: 'Mizoram' },
	{ city: 'NAGALAND', name: 'Nagaland' },
	{ city: 'ODISHA', name: 'Odisha' },
	{ city: 'PUNJAB', name: 'Punjab' },
	{ city: 'RAJASTHAN', name: 'Rajasthan' },
	{ city: 'SIKKIM', name: 'Sikkim' },
	{ city: 'TAMIL_NADU', name: 'Tamil Nadu' },
	{ city: 'TELANGANA', name: 'Telangana' },
	{ city: 'TRIPURA', name: 'Tripura' },
	{ city: 'UTTAR_PRADESH', name: 'Uttar Pradesh' },
	{ city: 'UTTARAKHAND', name: 'Uttarakhand' },
	{ city: 'WEST_BENGAL', name: 'West Bengal' },
]
