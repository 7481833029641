import { Component, OnInit, ViewChild } from '@angular/core'
import { ThemePalette } from '@angular/material/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { MatDialog } from '@angular/material/dialog'
import { FileDailogComponent } from '../file-dailog/file-dailog.component'
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { KanbanService } from '../../../kanban.service'
import { ActivatedRoute } from '@angular/router'
import { saveAs } from 'file-saver'

export interface Task {
	name: string
	completed: boolean
	color: ThemePalette
	subtasks?: Task[]
}
@Component({
	selector: 'app-details-documents',
	templateUrl: './details-documents.component.html',
	styleUrls: ['./details-documents.component.scss'],
})
export class DetailsDocumentsComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger

	states = statesOfIndia
	showSave: boolean = true
	fieldEnable: boolean = true
	sidPidName = ''
	file: any
	files: File[] = []
	folder: { name: string; filesNew: File[] } | null = null
	auditFolder: { name: string; filesNew: File[] } | null = null
	salesFolder: { name: string; filesNew: File[] } | null = null
	stockFolder: { name: string; filesNew: File[] } | null = null
	debtorFolder: { name: string; filesNew: File[] } | null = null
	creditorFolder: { name: string; filesNew: File[] } | null = null
	bankFolder: { name: string; filesNew: File[] } | null = null
	sacntionFolder: { name: string; filesNew: File[] } | null = null
	commercialFolder: { name: string; filesNew: File[] } | null = null
	gstFolder: { name: string; filesNew: File[] } | null = null
	aomoFolder: { name: string; filesNew: File[] } | null = null
	cibilFolder: { name: string; filesNew: File[] } | null = null
	isProgressVisible = false
	panelOpenState = true
	detailForm: FormGroup
	dataSource3: Array<any> = []
	laneData: any
	displayColumns3: Array<string> = ['BuyerName', 'BuyerAddress', 'BuyerRating', 'BuyerIndusrty', 'BuyerturnOver', 'LimitRequested', 'TendorDays', 'Action']
	laneTaskId: any
	directorId: any
	tabName: string
	newLeadLaneData: any
	aoaAndIncomeCheck: boolean
	showCompanyPan: String = ''
	showCin: boolean = false;

	constructor(public dialog: MatDialog, private fb: FormBuilder, public toasterService: ToastrService, private activatedRoute: ActivatedRoute, public kService: KanbanService) {}

	task: Task = {
		name: 'Checl All',
		completed: false,
		color: 'primary',
		subtasks: [
			{ name: 'Check 1', completed: false, color: 'primary' },
			{ name: 'Check 2', completed: false, color: 'primary' },
			{ name: 'Check 3', completed: false, color: 'primary' },
		],
	}

	allComplete: boolean = false

	someComplete(): boolean {
		return this.task.subtasks.filter((t) => t.completed).length > 0 && !this.allComplete
	}

	setAll(completed: boolean) {
		this.allComplete = completed
		this.task.subtasks.forEach((t) => (t.completed = completed))
	}

	updateAllComplete() {
		this.allComplete = this.task.subtasks != null && this.task.subtasks.every((t) => t.completed)
	}

	cancel() {
		this.menuTrigger.closeMenu()
	}

	multiFileUpload(event: any) {
		const newFiles = Array.from(event.target.files) as File[]
		this.files = this.files.concat(newFiles)
	}


	removeFolder() {
		this.folder = null
	}

	openDialog(keyWord): void {
		const dialogRef = this.dialog.open(FileDailogComponent, {
			width: '500px',
			data: { dataType: keyWord, laneTaskId: this.laneTaskId },
			panelClass: 'file-dialog-container',
		})

		dialogRef.afterClosed().subscribe((result) => {
			this.fetchdata(this.laneTaskId)
			// if (result) {
			//   this.folder.filesNew = result;
			// }
			//commenting this fetch datacalling method to Fix the duplication of froms in documentation Tab
			// this.fetchdata(this.laneTaskId);
		})
	}

	ngOnInit() {
		this.initialiseForm()
		this.activatedRoute.params.subscribe((x) => {
			this.laneTaskId = x.id
			this.kService.setLaneTaskId(x.id)
		})
		this.fetchdata(this.laneTaskId)
		this.companyPanReplicate()
		this.typeOfcompanyReplicate()
	}

	initialiseForm() {
		this.detailForm = this.fb.group({
			companyDetails: this.fb.group({
				supplierName: [{ value: null, disabled: true }, Validators.required],
				typeOfCompany: [null, Validators.required],
				natureOfBusiness: [null, Validators.required],
				companyPAN: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
				companyPANFile: [null],
				cinNo: [null],
				cinNoFile: [null],
				gstinNo: [null, [Validators.required, Validators.minLength(15), Validators.maxLength(15)]],
				gstinNoFile: [null],
			}),
			businessUsersDetails: this.fb.array([]),
			documentations: this.fb.group({
				kycDocument: this.fb.group({
					typeOfCompany: [],
					utilityBills: [],
					gstCertificate: [],
					gstCertificateFile: [],
					udhyamAadharCertificate: [],
					udhyamAadharCertificateFile: [],
					incorporationCertificateFile: [],
					companyPanCard: [],
					companyPanCardFile: [],
				}),
				companyDocuments: this.fb.group({
					auditedStatment: [],
					salesPurchaseOrder: [],
					stockStatement: [],
					debtorStatement: [],
					creditorStatement: [],
					bankStatements: [],
					sanctionLetters: [],
					commTransactionDoc: [],
					gstReturns: [],
					aoaAndMoa: [],
					cibilData: [],
				}),
			}),
		})
	}
	restrictAadhar(event: any) {
		if (event.target.value.length > 11) {
			return false
		}
	}
	multiFileUploadFolder(event: any, type: any) {
		const newFiles = Array.from(event.target.files) as File[]
		if (type == 'AUDITED_STATEMENT') {
			if (!this.auditFolder) {
				this.auditFolder = { name: 'Folder', filesNew: [] }
			}
			this.auditFolder.filesNew = this.auditFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.auditFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			if (!this.salesFolder) {
				this.salesFolder = { name: 'Folder', filesNew: [] }
			}
			this.salesFolder.filesNew = this.salesFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.salesFolder.filesNew, 'bulk upload', type)
		} else if (type == 'STOCK_STATEMENT') {
			if (!this.stockFolder) {
				this.stockFolder = { name: 'Folder', filesNew: [] }
			}
			this.stockFolder.filesNew = this.stockFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.stockFolder.filesNew, 'bulk upload', type)
		} else if (type == 'DEBTOR_STATEMENT') {
			if (!this.debtorFolder) {
				this.debtorFolder = { name: 'Folder', filesNew: [] }
			}
			this.debtorFolder.filesNew = this.debtorFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.debtorFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CREDITOR_STATEMENT') {
			if (!this.creditorFolder) {
				this.creditorFolder = { name: 'Folder', filesNew: [] }
			}
			this.creditorFolder.filesNew = this.creditorFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.creditorFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BANK_STATEMENTS') {
			if (!this.bankFolder) {
				this.bankFolder = { name: 'Folder', filesNew: [] }
			}
			this.bankFolder.filesNew = this.bankFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.bankFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SANCTION_LETTERS') {
			if (!this.sacntionFolder) {
				this.sacntionFolder = { name: 'Folder', filesNew: [] }
			}
			this.sacntionFolder.filesNew = this.sacntionFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.sacntionFolder.filesNew, 'bulk upload', type)
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			if (!this.commercialFolder) {
				this.commercialFolder = { name: 'Folder', filesNew: [] }
			}
			this.commercialFolder.filesNew = this.commercialFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.commercialFolder.filesNew, 'bulk upload', type)
		} else if (type == 'GST_RETURNS') {
			if (!this.gstFolder) {
				this.gstFolder = { name: 'Folder', filesNew: [] }
			}
			this.gstFolder.filesNew = this.gstFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.gstFolder.filesNew, 'bulk upload', type)
		} else if (type == 'AOA_AND_MOA') {
			if (!this.aomoFolder) {
				this.aomoFolder = { name: 'Folder', filesNew: [] }
			}
			this.aomoFolder.filesNew = this.aomoFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.aomoFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CIBIL_DATA') {
			if (!this.cibilFolder) {
				this.cibilFolder = { name: 'Folder', filesNew: [] }
			}
			this.cibilFolder.filesNew = this.cibilFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.cibilFolder.filesNew, 'bulk upload', type)
		}
	}
	uploadMultipleFiles(files, remark, type) {
		if (files.length > 10) {
			this.toasterService.error('Only 10 Files Can be Uploaded At A Time!')
			this.folder = null
			this.auditFolder = null
			this.salesFolder = null
			this.stockFolder = null
			this.debtorFolder = null
			this.creditorFolder = null
			this.bankFolder = null
			this.sacntionFolder = null
			this.commercialFolder = null
			this.gstFolder = null
			this.aomoFolder = null
			this.cibilFolder = null
			return
		}
		let formData = new FormData()
		files.forEach((x) => {
			formData.append('files', x)
		})
		this.kService.uploadBulkFiles(formData, this.laneTaskId, remark, type).subscribe({
			next: (resp: any) => {
				if (type == 'AUDITED_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								auditedStatment: resp.result,
							},
						},
					})
				} else if (type == 'SALES_OR_PURCHASE_ORDER') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								salesPurchaseOrder: resp.result,
							},
						},
					})
				} else if (type == 'STOCK_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								stockStatement: resp.result,
							},
						},
					})
				} else if (type == 'DEBTOR_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								debtorStatement: resp.result,
							},
						},
					})
				} else if (type == 'CREDITOR_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								creditorStatement: resp.result,
							},
						},
					})
				} else if (type == 'BANK_STATEMENTS') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								bankStatements: resp.result,
							},
						},
					})
				} else if (type == 'SANCTION_LETTERS') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								sanctionLetters: resp.result,
							},
						},
					})
				} else if (type == 'COMMERCIAL_TRANSACTION') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								commTransactionDoc: resp.result,
							},
						},
					})
				} else if (type == 'GST_RETURNS') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								gstReturns: resp.result,
							},
						},
					})
				} else if (type == 'AOA_AND_MOA') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								aoaAndMoa: resp.result,
							},
						},
					})
				} else if (type == 'CIBIL_DATA') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								cibilData: resp.result,
							},
						},
					})
				}
				this.fetchdata(this.laneTaskId)
			},
		})
	}
	showFoldeFileCount(type) {
		let show = false
		let documents = (this.detailForm.get('documentations') as FormGroup).get('companyDocuments')
		if (type == 'AUDITED_STATEMENT') {
			show = documents.get('auditedStatment').value && documents.get('auditedStatment').value.length ? true : false
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			show = documents.get('salesPurchaseOrder').value && documents.get('salesPurchaseOrder').value.length ? true : false
		} else if (type == 'STOCK_STATEMENT') {
			show = documents.get('stockStatement').value && documents.get('stockStatement').value.length ? true : false
		} else if (type == 'DEBTOR_STATEMENT') {
			show = documents.get('debtorStatement').value && documents.get('debtorStatement').value.length ? true : false
		} else if (type == 'CREDITOR_STATEMENT') {
			show = documents.get('creditorStatement').value && documents.get('creditorStatement').value.length ? true : false
		} else if (type == 'BANK_STATEMENTS') {
			show = documents.get('bankStatements').value && documents.get('bankStatements').value.length ? true : false
		} else if (type == 'SANCTION_LETTERS') {
			show = documents.get('sanctionLetters').value && documents.get('sanctionLetters').value.length ? true : false
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			show = documents.get('commTransactionDoc').value && documents.get('commTransactionDoc').value.length ? true : false
		} else if (type == 'GST_RETURNS') {
			show = documents.get('gstReturns').value && documents.get('gstReturns').value.length ? true : false
		} else if (type == 'AOA_AND_MOA') {
			show = documents.get('aoaAndMoa').value && documents.get('aoaAndMoa').value.length ? true : false
		} else if (type == 'CIBIL_DATA') {
			show = documents.get('cibilData').value && documents.get('cibilData').value.length ? true : false
		}
		return show
	}

	getFileCount(type) {
		let show = 0
		let documents = (this.detailForm.get('documentations') as FormGroup).get('companyDocuments')
		if (type == 'AUDITED_STATEMENT') {
			show = documents.get('auditedStatment').value.length
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			show = documents.get('salesPurchaseOrder').value.length
		} else if (type == 'STOCK_STATEMENT') {
			show = documents.get('stockStatement').value.length
		} else if (type == 'DEBTOR_STATEMENT') {
			show = documents.get('debtorStatement').value.length
		} else if (type == 'CREDITOR_STATEMENT') {
			show = documents.get('creditorStatement').value.length
		} else if (type == 'BANK_STATEMENTS') {
			show = documents.get('bankStatements').value.length
		} else if (type == 'SANCTION_LETTERS') {
			show = documents.get('sanctionLetters').value.length
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			show = documents.get('commTransactionDoc').value.length
		} else if (type == 'GST_RETURNS') {
			show = documents.get('gstReturns').value.length
		} else if (type == 'AOA_AND_MOA') {
			show = documents.get('aoaAndMoa').value.length
		} else if (type == 'CIBIL_DATA') {
			show = documents.get('cibilData').value.length
		}
		return show
	}
	getTabValue(event) {
		if (event == 0) {
			this.tabName = 'company details'
		} else if (event == 1) {
			this.tabName = 'bussiness user'
		} else if (event == 2) {
			this.tabName = 'documentation'
		}
	}

	fetchdata(x) {
		if (x !== null && x !== 'undefined') {
			this.kService.fetchData(x).subscribe({
				next: (resp: any) => {
					this.kService.setLaneData(resp.result)
					this.editable(resp)
					this.folder = null
					this.auditFolder = null
					this.salesFolder = null
					this.stockFolder = null
					this.debtorFolder = null
					this.creditorFolder = null
					this.bankFolder = null
					this.sacntionFolder = null
					this.commercialFolder = null
					this.gstFolder = null
					this.aomoFolder = null
					this.cibilFolder = null
					if (resp.result.domesticData && resp.result.domesticData['NEW_LEADS']) {
						this.newLeadLaneData = resp.result.domesticData['NEW_LEADS']
						this.laneData = resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED']
						this.detailForm.patchValue({
							companyDetails: {
								supplierName: resp.result.domesticData['NEW_LEADS'].supplierName,
								typeOfCompany: resp.result.domesticData['NEW_LEADS'].typeOfCompany,
								natureOfBusiness: resp.result.domesticData['NEW_LEADS'].natureOfBusiness,
							},
						})
						this.sidPidName = resp.result.domesticData['NEW_LEADS'].businessProduct == 'SID' ? 'Sales' : 'Purchase'
						let checkValue = resp.result.domesticData['NEW_LEADS'].typeOfCompany
						if (checkValue == 'PROPRIETORSHIP' || checkValue == 'PARTNERSHIP' || checkValue == 'LLP') {
							this.aoaAndIncomeCheck = false
						} else {
							this.aoaAndIncomeCheck = true
						}
						if( checkValue != 'PROPRIETORSHIP'){
							(this.detailForm.get('companyDetails') as FormGroup).get('cinNo').setValidators([Validators.required, Validators.minLength(21), Validators.maxLength(21)]);
							this.showCin = true;
							this.detailForm.updateValueAndValidity();
						}
						this.addCompanyPan(resp.result.domesticData['NEW_LEADS'].typeOfCompany)
					}
					if (resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] && resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails) {
						this.detailForm.patchValue({
							companyDetails: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails,
						})
						let data = this.detailForm.get('companyDetails') as FormGroup
						data.get('supplierName').setValue(resp.result.domesticData['NEW_LEADS'].supplierName)
						data.get('typeOfCompany').setValue(resp.result.domesticData['NEW_LEADS'].typeOfCompany)
						data.get('natureOfBusiness').setValue(resp.result.domesticData['NEW_LEADS'].natureOfBusiness)
					}
					if (
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].businessUsersDetails &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].businessUsersDetails.length > 0
					) {
						this.createBusinessUserDetails(resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].businessUsersDetails)
					} else {
						while (this.businessUsersDetails.length) {
							this.businessUsersDetails.removeAt(0)
						}
						this.businessUsersDetails.push(this.createBusinessUserDetails())
					}
					if (resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] && resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations) {
						this.detailForm.patchValue({
							documentations: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations,
						})
						this.patchCompanyDocuments(resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.companyDocuments)
					}
				},
				error: (err: any) => {
					this.businessUsersDetails.push(this.createBusinessUserDetails())
				},
			})
		}
	}
	editable(resp) {
		this.kService.hasAccess.subscribe((access) => {
			this.kService.laneListIds.subscribe((x) => {
				if (x && Object.keys(x).length > 0) {
					let obj = x.filter((element) => element.laneName == 'application-documents-submitted')
					if (resp.result.laneId == obj[0].laneId && access) {
						this.showSave = true
						this.detailForm.enable()
						this.fieldEnable = true
					} else {
						this.showSave = false
						this.detailForm.disable()
						this.fieldEnable = false
					}
				}
				;(this.detailForm.get('companyDetails') as FormGroup).get('supplierName').disable()
				;(this.detailForm.get('companyDetails') as FormGroup).get('typeOfCompany').disable()
				;(this.detailForm.get('companyDetails') as FormGroup).get('natureOfBusiness').disable()
				;((this.detailForm.get('documentations') as FormGroup).get('kycDocument') as FormGroup).get('typeOfCompany').disable()
				;((this.detailForm.get('documentations') as FormGroup).get('kycDocument') as FormGroup).get('companyPanCard').disable()
				for (let i = 0; i < this.businessUsersDetails.length; i++) {
					this.businessUsersDetails.at(i).get('typeOfCompany').disable()
				}
			})
		})
	}
	addCompanyPan(companyType) {
		if (companyType == 'PROPRIETORSHIP') {
			this.showCompanyPan = 'Individual'
		} else {
			this.showCompanyPan = 'Company'
		}
	}

	patchCompanyDocuments(companyDocs: any) {
		if (companyDocs) {
			Object.keys(companyDocs).forEach((key) => {
				this.detailForm.patchValue({
					documentations: {
						companyDocuments: {
							key: companyDocs[key],
						},
					},
				})
			})
		}
	}

	createBusinessUserDetails(details: [] = null) {
		if (details && details.length > 0) {
			while (this.businessUsersDetails.length) {
				this.businessUsersDetails.removeAt(0)
			}
			// this.detailForm.patchValue({businessUsersDetails : this.fb.array([])});
			details.forEach((detail: any) => {
				const businessUserDetail = this.fb.group({
					typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
					name: [detail.name],
					dob: [detail.dob],
					gender: [detail.gender],
					citizenship: [detail.citizenship],
					contactNo: [detail.contactNo, Validators.pattern(/^[0-9]\d*$/)],
					email: [detail.email, Validators.email],
					dinNo: [detail.dinNo],
					address: [detail.address],
					city: [detail.city],
					state: [detail.state],
					pincode: [detail.pincode, [Validators.pattern(/^[0-9]{6}$/), Validators.minLength(6), Validators.maxLength(6)]],
					panCard: [detail.panCard],
					panCardFile: [detail.panCardFile],
					kycDocument: [detail.kycDocument],
					kycDocumentFile: [detail.kycDocumentFile],
					id: [detail.id],
				})
				this.businessUsersDetails.push(businessUserDetail)
			})
		}
		return this.fb.group({
			typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
			name: [],
			dob: [],
			gender: [],
			citizenship: [],
			contactNo: [null, Validators.pattern(/^[0-9]\d*$/)],
			email: [null, Validators.email],
			dinNo: [],
			address: [],
			city: [],
			state: [],
			pincode: [null, [Validators.pattern(/^[0-9]{6}$/), Validators.minLength(6), Validators.maxLength(6)]],
			panCard: [],
			panCardFile: [],
			kycDocument: [],
			kycDocumentFile: [],
			id: [null],
		})
	}

	restrictPhoneLength(event: any): void {
		if (event.target.value.length > 10) {
			event.target.value = event.target.value.slice(0, 10)
		}
	}
	restrictPinCode(event) {
		if (event.target.value.length > 5) {
			return false
		}
	}
	restrictPan(event) {
		if (event.target.value.length > 9) {
			return false
		}
	}
	restrictCin(event) {
		if (event.target.value.length > 20) {
			return false
		}
	}
	restrictGstin(event) {
		if (event.target.value.length > 14) {
			return false
		}
	}
	get businessUsersDetails() {
		return this.detailForm.get('businessUsersDetails') as FormArray
	}
	companyPanReplicate() {
		let companyDetail = this.detailForm.get('companyDetails') as FormGroup
		// let documentations = (this.detailForm.get('documentations') as FormGroup).get('kycDocument');
		companyDetail.get('companyPAN').valueChanges.subscribe((x) => {
			this.detailForm.patchValue({
				documentations: {
					kycDocument: {
						companyPanCard: x,
					},
				},
			})
		})
	}

	typeOfcompanyReplicate() {
		let companyDetail = this.detailForm.get('companyDetails') as FormGroup
		// let documentations = (this.detailForm.get('documentations') as FormGroup).get('kycDocument');
		companyDetail.get('typeOfCompany').valueChanges.subscribe((x) => {
			this.detailForm.patchValue({
				documentations: {
					kycDocument: {
						typeOfCompany: x,
					},
				},
			})
		})
	}
	fileUpload(event: any, fileType: any) {
		this.isProgressVisible = true
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentations') as FormGroup).get('kycDocument')
		if (fileType == 'COMPANY_PAN') {
			if (!details.get('companyPAN').value) {
				this.toasterService.error('Please Enter Company Pan Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('companyPAN').value, fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], details.get('companyPAN').value, fileType)
			}
		} else if (fileType == 'CIN_NUMBER') {
			if (!details.get('cinNo').value) {
				this.toasterService.error('Please Enter CIN Pan Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('cinNo').value, fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], details.get('cinNo').value, fileType)
			}
		} else if (fileType == 'GSTIN_NUMBER') {
			if (!details.get('gstinNo').value) {
				this.toasterService.error('Please Enter GST Number Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('gstinNo').value, fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], details.get('gstinNo').value, fileType, this.directorId)
			}
		} else if (fileType == 'UTILITY_BILLS') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], '', fileType)
			}
		} else if (fileType == 'GST_REGISTRATION') {
			if (!documents.get('gstCertificate').value) {
				this.toasterService.error('Please Enter GST Number Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadSingleFile(event[0], documents.get('gstCertificate').value, fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], documents.get('gstCertificate').value, fileType)
			}
		} else if (fileType == 'UDHYAM_AADHAR') {
			if (!documents.get('udhyamAadharCertificate').value) {
				this.toasterService.error('Please Enter Aadhar Certificate Number Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadSingleFile(event[0], documents.get('udhyamAadharCertificate').value, fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], documents.get('udhyamAadharCertificate').value, fileType)
			}
		} else if (fileType == 'INCORPORATION_CERTIFICATE') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType)
			} else {
				this.uploadSingleFile(event.target.files[0], '', fileType)
			}
		}
	}
	addDirector(index) {
		if (index > 8) {
			this.toasterService.error('Maximum Directors Added!')
			return
		}
		let itemArr = (this.businessUsersDetails.at(index) as FormGroup).value
		if (
			itemArr.name &&
			itemArr.dob &&
			itemArr.gender &&
			itemArr.citizenship &&
			itemArr.contactNo &&
			itemArr.email &&
			itemArr.address &&
			itemArr.city &&
			itemArr.state &&
			itemArr.pincode &&
			itemArr.panCard &&
			itemArr.panCardFile &&
			itemArr.kycDocument &&
			itemArr.kycDocumentFile
		) {
			this.businessUsersDetails.push(this.createBusinessUserDetails())
		} else {
			this.toasterService.error('Please Add Details Before Adding New Director!')
		}
	}
	deleteDirector(index) {
		this.businessUsersDetails.removeAt(index)
	}
	uploadSingleFile(file, value, type, directorId?) {
		this.kService.uploadSingleFile(this.laneTaskId, type, value, file, directorId).subscribe({
			next: (resp: any) => {
				if (type == 'COMPANY_PAN') {
					this.detailForm.patchValue({
						companyDetails: {
							companyPANFile: resp.result.id,
						},
					})
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								companyPanCardFile: resp.result.id,
							},
						},
					})
				} else if (type == 'CIN_NUMBER') {
					this.detailForm.patchValue({
						companyDetails: {
							cinNoFile: resp.result.id,
						},
					})
				} else if (type == 'GSTIN_NUMBER') {
					this.detailForm.patchValue({
						companyDetails: {
							gstinNoFile: resp.result.id,
						},
					})
				} else if (type == 'UTILITY_BILLS') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								utilityBills: resp.result.id,
							},
						},
					})
				} else if (type == 'GST_REGISTRATION') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								gstCertificateFile: resp.result.id,
							},
						},
					})
				} else if (type == 'UDHYAM_AADHAR') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								udhyamAadharCertificateFile: resp.result.id,
							},
						},
					})
				} else if (type == 'INCORPORATION_CERTIFICATE') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								incorporationCertificateFile: resp.result.id,
							},
						},
					})
				}
			},
		})
	}
	showCompanyDetailsDoc(type) {
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentations') as FormGroup).get('kycDocument')
		if (type == 'COMPANY_PAN') {
			return details.get('companyPANFile').value ? true : false
		} else if (type == 'CIN_NUMBER') {
			return details.get('cinNoFile').value ? true : false
		} else if (type == 'GSTIN_NUMBER') {
			return details.get('gstinNoFile').value ? true : false
		} else if (type == 'UTILITY_BILLS') {
			return documents.get('utilityBills').value ? true : false
		} else if (type == 'GST_REGISTRATION') {
			return documents.get('gstCertificateFile').value ? true : false
		} else if (type == 'UDHYAM_AADHAR') {
			return documents.get('udhyamAadharCertificateFile').value ? true : false
		} else if (type == 'INCORPORATION_CERTIFICATE') {
			return documents.get('incorporationCertificateFile').value ? true : false
		}
	}
	showUpload(type, index) {
		if (type == 'pan') {
			return this.businessUsersDetails.at(index).get('panCardFile').value ? true : false
		} else {
			return this.businessUsersDetails.at(index).get('kycDocumentFile').value ? true : false
		}
	}
	fileUploadInBusinessDetails(event: any, fileType: any, index: number) {
		this.isProgressVisible = true
		let businessDetails = this.businessUsersDetails.at(index)
		if (fileType == 'PAN_CARD') {
			if (!businessDetails.get('panCard').value) {
				this.toasterService.error('Please Enter Pan Number Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadBusinessSingleFile(
					event[0],
					businessDetails.get('panCard').value,
					fileType,
					this.directorId ? this.directorId : this.businessUsersDetails.at(0).get('id').value,
					index,
				)
			} else {
				this.uploadBusinessSingleFile(
					event.target.files[0],
					businessDetails.get('panCard').value,
					fileType,
					this.directorId ? this.directorId : this.businessUsersDetails.at(0).get('id').value,
					index,
				)
			}
		} else if (fileType == 'KYC_DOCUMENT') {
			if (!businessDetails.get('kycDocument').value) {
				this.toasterService.error('Please Enter KYC Document Number Before Uploading')
				return
			}
			if (event[0]) {
				this.uploadBusinessSingleFile(
					event[0],
					businessDetails.get('kycDocument').value,
					fileType,
					this.directorId ? this.directorId : this.businessUsersDetails.at(0).get('id').value,
					index,
				)
			} else {
				this.uploadBusinessSingleFile(
					event.target.files[0],
					businessDetails.get('kycDocument').value,
					fileType,
					this.directorId ? this.directorId : this.businessUsersDetails.at(0).get('id').value,
					index,
				)
			}
		}
	}
	uploadBusinessSingleFile(file, value, type, directorId, index) {
		this.kService.uploadSingleBusinessFile(this.laneTaskId, type, value, file, directorId).subscribe({
			next: (resp: any) => {
				this.directorId = resp.result && resp.result.directorId ? resp.result.directorId : null
				if (type == 'PAN_CARD') {
					this.businessUsersDetails.at(index).patchValue({
						panCardFile: resp.result.id,
					})
				} else if (type == 'KYC_DOCUMENT') {
					this.businessUsersDetails.at(index).patchValue({
						kycDocumentFile: resp.result.id,
					})
				}
			},
		})
	}
	removeEmptyObjects(array: any[]): any[] {
		return array.filter((obj) => {
			// Check if every property in the object is null
			return !Object.values(obj).every((value) => value === null)
		})
	}

	submitForm() {
		// Remove empty objects from the businessUsersDetails array
		const cleanedBusinessUsersDetails = this.removeEmptyObjects(this.detailForm.getRawValue().businessUsersDetails)

		// Create a new payload with the cleaned array
		const payload = {
			...this.detailForm.getRawValue(),
			businessUsersDetails: cleanedBusinessUsersDetails,
		}
		if (this.detailForm.valid) {
			this.kService.saveDetailsAndDocuments(payload, this.laneTaskId).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.toasterService.success('Details Saved Successfully!')
					} else {
						this.toasterService.error(resp.message)
					}
					this.fetchdata(this.laneTaskId)
				},
			})
		} else {
			this.focusFirstInvalidField(this.detailForm.controls.companyDetails)
			this.toasterService.error('Fill Mandatory field First')
		}
	}

	private focusFirstInvalidField(form): void {
		for (const field in form.controls) {
			if (form.controls[field].invalid) {
				const invalidControl = form.controls[field]
				const invalidFieldElement = document.getElementById(field)
				console.log('field name', field)
				if (invalidFieldElement) {
					invalidControl.markAsTouched()
					invalidFieldElement.focus()
					return
				}
			}
		}
	}

	viewDocument(type) {
		let id = ''
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentations') as FormGroup).get('kycDocument')
		if (type == 'COMPANY_PAN') {
			id = details.get('companyPANFile').value
		} else if (type == 'CIN_NUMBER') {
			id = details.get('cinNoFile').value
		} else if (type == 'GSTIN_NUMBER') {
			id = details.get('gstinNoFile').value
		} else if (type == 'UTILITY_BILLS') {
			id = documents.get('utilityBills').value
		} else if (type == 'GST_REGISTRATION') {
			id = documents.get('gstCertificateFile').value
		} else if (type == 'UDHYAM_AADHAR') {
			id = documents.get('udhyamAadharCertificateFile').value
		} else if (type == 'INCORPORATION_CERTIFICATE') {
			id = documents.get('incorporationCertificateFile').value
		}
		this.downloadFile(id)
	}
	viewBusinessDocument(type, index) {
		let id = ''
		if (type == 'pan') {
			id = this.businessUsersDetails.at(index).get('panCardFile').value
		} else {
			id = this.businessUsersDetails.at(index).get('kycDocumentFile').value
		}
		this.downloadFile(id)
	}

	downloadFile(id) {
		this.kService.downloadSingleUploadDocument(id).subscribe({
			next: (resp: any) => {
				this.downloadSheetCommonMethod(resp)
			},
		})
	}
	downloadAllFiles() {
		this.kService.downloadAllFiles(this.laneTaskId).subscribe({
			next: (resp: any) => {
				this.downloadSheetCommonMethod(resp)
			},
		})
	}
	resultDownload
	downloadSheetCommonMethod(res) {
		var contentDispositionHeader = res.headers.get('content-disposition')
		this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
		let fileName = this.resultDownload.replace(/"/g, '')
		saveAs(res.body, fileName)
		return fileName
	}
}
const statesOfIndia = [
	{ city: 'ANDHRA_PRADESH', name: 'Andhra Pradesh' },
	{ city: 'ARUNACHAL_PRADESH', name: 'Arunachal Pradesh' },
	{ city: 'ASSAM', name: 'Assam' },
	{ city: 'BIHAR', name: 'Bihar' },
	{ city: 'CHHATTISGARH', name: 'Chhattisgarh' },
	{ city: 'DELHI', name: 'Delhi' },
	{ city: 'GOA', name: 'Goa' },
	{ city: 'GUJARAT', name: 'Gujarat' },
	{ city: 'HARYANA', name: 'Haryana' },
	{ city: 'HIMACHAL_PRADESH', name: 'Himachal Pradesh' },
	{ city: 'JHARKHAND', name: 'Jharkhand' },
	{ city: 'KARNATAKA', name: 'Karnataka' },
	{ city: 'KERALA', name: 'Kerala' },
	{ city: 'MADHYA_PRADESH', name: 'Madhya Pradesh' },
	{ city: 'MAHARASHTRA', name: 'Maharashtra' },
	{ city: 'MANIPUR', name: 'Manipur' },
	{ city: 'MEGHALAYA', name: 'Meghalaya' },
	{ city: 'MIZORAM', name: 'Mizoram' },
	{ city: 'NAGALAND', name: 'Nagaland' },
	{ city: 'ODISHA', name: 'Odisha' },
	{ city: 'PUNJAB', name: 'Punjab' },
	{ city: 'RAJASTHAN', name: 'Rajasthan' },
	{ city: 'SIKKIM', name: 'Sikkim' },
	{ city: 'TAMIL_NADU', name: 'Tamil Nadu' },
	{ city: 'TELANGANA', name: 'Telangana' },
	{ city: 'TRIPURA', name: 'Tripura' },
	{ city: 'UTTAR_PRADESH', name: 'Uttar Pradesh' },
	{ city: 'UTTARAKHAND', name: 'Uttarakhand' },
	{ city: 'WEST_BENGAL', name: 'West Bengal' },
]
