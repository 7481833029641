<div fxFlexFill>
  <section class="ctaStripWrap">
    <div class="lft">
      <button *ngIf="checkNavigation()" class="filter back" mat-button (click)="navigateToSupplierPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button class="filter fltbtn" mat-button [ngClass]="{'open':!isFiltersShow}" (click)="filtersDisplay()">
        <span class="material-icons">
          filter_list
        </span>
        <span> Filter</span>
      </button>
      <button class="sort btnSort" mat-button [matMenuTriggerFor]="sortMenu">
        <i class="ri-sort-desc"></i>
        <span>Sort</span>
        <mat-menu #sortMenu="matMenu" class="sortingmenu">
          <button mat-menu-item
            (click)="sortData(sort, 'eligibleInvoiceValue', 'desc', 'Eligible Invoice- Highest First')"
            [ngClass]="{sortApplied : activeItem==='Eligible Invoice- Highest First' }">
            <i class="ri-check-line"></i><span>Eligible Invoice Value- Highest First</span></button>
          <button mat-menu-item
            (click)="sortData(sort, 'eligibleInvoiceValue', 'asc', ' Eligible Invoice- Lowest First')"
            [ngClass]="{sortApplied : activeItem===' Eligible Invoice- Lowest First' }">
            <i class="ri-check-line"></i><span>Eligible Invoice Value- Lowest First</span></button>

          <button mat-menu-item (click)="sortData(sort,'activeReqValue', 'desc', 'Active Request -
            Highest first')" [ngClass]="{sortApplied : activeItem==='Active Request -
            Highest first' }">
            <i class="ri-check-line"></i><span>Active Request Value - Highest first</span></button>
          <button mat-menu-item (click)="sortData(sort,'activeReqValue', 'asc','Active Request -
            Lowest first')" [ngClass]="{sortApplied : activeItem==='Active Request -
            Lowest first' }">
            <i class="ri-check-line"></i><span>Active Request Value - Lowest first</span></button>

          <button mat-menu-item
            (click)="sortData(sort,'paymentHoldValue', 'desc', 'Active Request Value - Lowest first')"
            [ngClass]="{sortApplied : activeItem==='Active Request Value - Lowest first' }">
            <i class="ri-check-line"></i><span>Payment Hold Value - Highest first</span></button>
          <button mat-menu-item (click)="sortData(sort,'paymentHoldValue', 'asc','Payment hold Value - Lowest first')"
            [ngClass]="{sortApplied : activeItem==='Payment hold Value - Lowest first' }">
            <i class="ri-check-line"></i><span>Payment hold Value - Lowest first</span></button>
        </mat-menu>
      </button>
      <button class="download" mat-button (click)="downloadSupplierInvoices()" *hasPermission="['EXPORT_DATA']">
        <i class="ri-download-line"></i>
      </button>
    </div>
    <div class="rht" *hasPermission="['ADD_BORROWER']">
      <button class="user" mat-button (click)="addSuppliers()" *ngIf="!authService.isAccordUser()">
        <i class="ri-user-add-line"></i>
      </button>

      <button class="dropdown bulkbtn" mat-button [matMenuTriggerFor]="syncMenu" *ngIf="jkFennerCheck">
        <span class="">Sync</span>
        <i class="ri-arrow-down-s-line"></i>
      </button>
      <mat-menu #syncMenu="matMenu">
        <button mat-menu-item (click)="directInvoices()">Invoices</button>
        <button mat-menu-item (click)="directVendors()">Vendor</button>
      </mat-menu>

      <button class="dropdown bulkbtn" mat-button [matMenuTriggerFor]="bulkMenu"
        [disabled]="isButtonEnable ===true?true:false">
        <span class="">Bulk Actions</span>
        <i class="ri-arrow-down-s-line"></i>
      </button>
      <mat-menu #bulkMenu="matMenu">
        <button mat-menu-item (click)="changeBulkActions('rate')">Change Rate</button>
        <button mat-menu-item (click)="changeBulkActions('eligible')">Change Eligible %age</button>
        <button mat-menu-item (click)="changeBulkActions('limit')">Change Limit</button>
        <button mat-menu-item (click)="changeBulkActions('maxDiscount')">Change Min and Max Discount Days</button>
        <button mat-menu-item (click)="changeBulkStatus('deactivate')">Deactivate</button>
        <button mat-menu-item (click)="changeBulkStatus('activate')">Activate</button>
        <button mat-menu-item (click)="changeBulkActions('approval')">Approvals</button>
        <button mat-menu-item (click)="changeBulkActions('financer')">Financer</button>
        <button *ngIf="authService.isCredlixUser()" mat-menu-item (click)="navigateToLeadsPage('Add to Leads')">Add to
          Leads</button>
        <button mat-menu-item *ngIf="jkFennerCheck" (click)="bulkSyncInvoices()">Sync Invoices</button>
      </mat-menu>
    </div>
  </section>
  <div class="pgheader">
    <h2><span>Early Payments</span></h2>
  </div>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
    <div class="listingFilters" [hidden]="isFiltersShow">
      <mat-form-field appearance="fill" class="selectearlypaystatus biztype w-170" *ngIf="!authService.isAccordUser()">
        <mat-label>Biz Type Status</mat-label>
        <mat-select #matRef1 multiple (selectionChange)="onBizTypeChange($event.value)">
          <mat-option *ngFor="let type of BizTypeFilterList" [value]="type">{{type.roleName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="selectearlypaystatus biztype w-170">
        <mat-label>Select Color</mat-label>
        <mat-select panelClass="color-flag" #matRef2 multiple (selectionChange)="onColorChange($event.value)">
          <mat-option *ngFor="let color of colorCodeArray" [value]="color.colorCode">
            <span class="dynamiccolor" [ngClass]="getColorOption(color)"></span>{{color.colorName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="selectearlypaystatus biztype w-170">
        <mat-label>Select Status</mat-label>
        <mat-select #matRef3 multiple (selectionChange)="onStatusChange($event.value)">
          <mat-option value="active">Active</mat-option>
          <mat-option value="inActive">In Active</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="selectearlypaystatus biztype w-170">
        <mat-label>RM Name</mat-label>
        <input type="text" matInput [formControl]="selectedRmName" [matAutocomplete]="auto"
          style="text-transform: capitalize;">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredRMList | async" (onSelectionChange)="onRMChange(option)"
            [value]="option.userName">
            {{option.userName | titlecase}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="selectearlypaystatus biztype w-170">
        <mat-label>Stage</mat-label>
        <mat-select #matRef5 (selectionChange)="onSelectStage($event.value)">
          <mat-option *ngFor="let type of stageArray" [value]="type.stage">
            {{type.stage | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="selectearlypaystatus biztype w-170">
        <mat-label>Sub Stage</mat-label>
        <mat-select #matRef6 (selectionChange)="onSelectSubStage($event.value)">
          <mat-option *ngFor="let type of subStageArray" [value]="type">
            {{type | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--Date Range Picker-->
      <!-- <div class="datebox" (click)="$event.stopPropagation();">
        <div class="dateboxinput">
          <input type="text" ngxDaterangepickerMd class="form-control"
            [linkedCalendars]="true" [(ngModel)]="selectedDate" (datesUpdated)="datesUpdated($event)"
            [locale]="{format: 'DD-MMM-YYYY'}" placeholder="Requested Date Range" readonly>
        </div>
      </div> -->

      <div class="leftfilteraction">
        <button mat-flat-button class="appbtn" #button (click)="getSupplierLinks(true)">Apply</button>
        <button mat-flat-button class="resetbtn" #button (click)="resetInvoiceFilters()">Clear All
        </button>
      </div>
    </div>

    <div class="dash-content" fxFlex fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
        <div class="mat_table mog3_msnsearchtable" fxFlex="100">
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i=index;">

                <span class="table-flag" [ngClass]="getColorCodeCSS(element,i)" (click)="resetComment(element, i)"
                  title="{{getTitleComments(element)}}"
                  *ngIf="element.config.commentConfig !=null && element.config.commentConfig.colorCode !=null"></span>

                <span class="table-flag" [ngClass]="getColorCodeCSS(element,i)" (menuOpened)="menuOpened(element, i)"
                  (menuClosed)="menuClosed(element,i)" title="{{getTitleComments(element)}}"
                  *ngIf="element.config.commentConfig ==null || element.config.commentConfig.colorCode ==null "
                  [matMenuTriggerFor]="editSupplierDetails"></span>

                <mat-menu #editSupplierDetails="matMenu" class="matmenu_editsupp">
                  <div class="editsuppdetails" *ngIf="isColorCodeVisible">
                    <div class="SplColrs">
                      <mat-radio-group class="suplierRadbtn" (change)="getColorCode($event.value, element, i)"
                        [(ngModel)]="colorCode">
                        <mat-radio-button value="red" class="radiored"></mat-radio-button>
                        <mat-radio-button value="blue" class="radioblue"></mat-radio-button>
                        <mat-radio-button value="orange" class="radioorange"></mat-radio-button>
                        <mat-radio-button value="green" class="radiogreen"></mat-radio-button>
                        <mat-radio-button value="purple" class="radiopurple"></mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </mat-menu>
                <!-- <button mat-icon-button class="chatIcon" [matMenuTriggerFor]="comment_menu"
                  (menuOpened)="commentMenu(element)">
                  <i class="ri-chat-1-line"></i>
                </button>
                <mat-menu #comment_menu="matMenu" class="matmenu_editsupp matcommentdrop">
                  <div class="editsuppdetails commenttext">
                    <textarea class="txtarea" placeholder="Enter Comments" [(ngModel)]="Comments"
                      (click)="$event.stopPropagation();$event.preventDefault();">
                  </textarea>
                    <button mat-flat-button color="primary" (click)="editCommentsdetails(element,i )">Save</button>
                  </div>
                </mat-menu> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="checkboxSelection(selection.isSelected(row))"
                  (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierID" sticky>
              <th mat-header-cell *matHeaderCellDef> Supplier ID-Supplier Name </th>
              <td mat-cell *matCellDef="let element" (click)="navigateToEarlyPayment(element)">
                <span class="suplierHighlight"> ({{element.searchKey}}) {{element.businessName | titlecase}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="isActive">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <span class="status statustrue" *ngIf="element.config.isActive == true ">Active
                </span>
                <span class="status statusfalse" *ngIf="element.config.isActive== false "> Inactive
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="biz_type">
              <th mat-header-cell *matHeaderCellDef> Biz Type </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.isABFRL == true && element.config && element.config.financier && element.config.financier.name == 'Shield Finance'">Shield Finance</span>
                <span *ngIf="element.isABFRL == true && element.config && element.config.financier && element.config.financier.name != 'Shield Finance'">ABFRL</span>
                <span
                  *ngIf="element.isABFRL == false && (element.isTasva == null || element.isTasva == false) ">Moglix</span>
                <span
                  *ngIf="element.isABFRL == false && (element.isTasva != null && element.isTasva == true) ">Tasva</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierName">
              <th mat-header-cell *matHeaderCellDef> Supplier Name</th>
              <td mat-cell *matCellDef="let element">{{element.businessName | titlecase}}
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierEmail">
              <th mat-header-cell *matHeaderCellDef> Supplier Email</th>
              <td mat-cell *matCellDef="let element">{{element.userEmail}}
              </td>
            </ng-container>

            <ng-container matColumnDef="rmName">
              <th mat-header-cell *matHeaderCellDef> RM Name</th>
              <td mat-cell *matCellDef="let element">{{element.rmName ? (element.rmName.name | titlecase) : '--'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="stage">
              <th mat-header-cell *matHeaderCellDef> Stage</th>
              <td mat-cell *matCellDef="let element">{{element.stage ? (element.stage | titlecase) : '--'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="subStage">
              <th mat-header-cell *matHeaderCellDef> Sub Stage</th>
              <td mat-cell *matCellDef="let element">{{element.subStage ? (element.subStage | titlecase) : '--'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="eligibleInvoice">
              <th mat-header-cell *matHeaderCellDef>Eligible Invoice Value(count)
                <span title={{storedEligibleAmountVal}}
                  style="font-weight: 400; padding: 6px;line-height: 2.2;">&#8377;({{numDifferentiation(storedEligibleAmountVal)}})
                  ({{storedEligibleCount | number:'1.0-0'}}) </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span title={{element.eligibleInvoiceValue}}
                  style="font-weight: 400; padding: 6px;line-height: 2.2;">&#8377;
                  {{element.eligibleInvoiceValue ? numDifferentiation(element.eligibleInvoiceValue): '0'}}
                  ({{element.elgInvoiceCount }})</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="requestValue">
              <th mat-header-cell *matHeaderCellDef>Active Request Value (count)
                <span title={{storedRequestedAmountVal}}
                  style="font-weight: 400; padding: 6px;line-height: 2.2;">&#8377;({{numDifferentiation(storedRequestedAmountVal)}})
                  ({{storedRequestedCount}})</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span
                  title={{element.activeReqValue}}>&#8377;{{numDifferentiation(element.activeReqValue)}}({{element.actvInvoiceCount
                  }})</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="holdValue">
              <th mat-header-cell *matHeaderCellDef>Payment Hold value (count)
                <div>
                  <span title={{storedRequestedAmountVal}}
                    style="font-weight: 400; padding: 6px;line-height: 2.2;">&#8377;({{numDifferentiation(paymentHoldAmountVal)}})
                    ({{paymentHoldCount}})</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <span
                  title={{element.activeReqValue}}>&#8377;{{numDifferentiation(element.paymentHoldAmount)}}({{element.paymentHoldCount}})</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="limit">
              <th mat-header-cell *matHeaderCellDef>Limit</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="(element.config.limit !=-1)">
                  <span> {{numDifferentiation(element.usedLimit)}}</span> of
                  <span>{{numDifferentiation(element.config.limit)}}</span>
                </span>
                <span *ngIf="(element.config.limit ==-1)" title={{element.usedLimit}}>
                  {{numDifferentiation(element.usedLimit)}} of
                  <span [innerHTML]="mark"></span>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div class="iconsaction">
                  <button mat-icon-button class="chatIcon" (click)="openCommentTab(element)">
                    <i class="ri-chat-1-line"></i>
                  </button>
                  <button mat-icon-button #phoneButton (click)="openSupplierDetails(element, phoneButton, 'true')"
                    (focusout)="openSupplierDetails(element, phoneButton, 'false')">
                    <mat-icon class="icon_details phone" mat-icon-button [matTooltip]="element.phoneNumber"
                      [matMenuTriggerFor]="phoneMenu"> phone</mat-icon>
                    <mat-menu #phoneMenu="matMenu">
                      <div class="phoneMenuBox">
                        <span>{{element.phoneNumber}}</span>
                      </div>
                      <img
                        src="http://chart.apis.google.com/chart?cht=qr&chs=80x80&chl=tel:+{{element.phoneNumber}}&chld=H|0"
                        width="100" height="100" />
                    </mat-menu>
                  </button>
                  <a>
                    <button mat-icon-button title={{element.userEmail}} #emailButton (click)="openSupplierDetails(element, emailButton, 'true')" (focusout)="openSupplierDetails(element, emailButton, 'false')">
                      <mat-icon class="icon_details" [matMenuTriggerFor]="mailMenu">mail_outline </mat-icon>
                      <mat-menu #mailMenu="matMenu">
                        <div class="phoneMenuBox" style="height: 24px;">
                          <span>{{element.userEmail}}</span>
                          <mat-icon (click)="copyEmailID(element.userEmail)" style="cursor: pointer;
                      font-size: 16px;
                      position: relative;
                      top: 4px;">content_copy</mat-icon>
                        </div>
                      </mat-menu>
                    </button>
                  </a>
                  <button class="p-text-edit" mat-icon-button *hasPermission="['ADD_BORROWER']" #editButton
                    (click)="editSupplierDetails(element, editButton)">
                    <mat-icon class="icon_details">edit</mat-icon>
                  </button>
                  <div *ngIf="isborrowrper || issendwhtsapPermis" style="display: contents;">
                    <button mat-icon-button [matMenuTriggerFor]="list" #acceptButton
                      (click)="openSupplierDetails(element, acceptButton, 'true')">
                      <mat-icon class="icon_details">more_vert</mat-icon>
                      <mat-menu #list="matMenu" (closed)="openSupplierDetails(element, acceptButton, 'false')">
                        <button mat-menu-item (click)="generateLink(element)" *ngIf="isborrowrper">Generate Password
                          Link</button>
                        <button mat-menu-item *ngIf="element.config.isActive== true && isborrowrper"
                          (click)="editSupplierStatus(element)">Deactivate</button>
                        <button mat-menu-item *ngIf="element.config.isActive== false && isborrowrper"
                          (click)="editSupplierStatus(element)">Activate</button>
                        <button mat-menu-item (click)="downloadReconcile(element)" *ngIf="isborrowrper">Download
                          Reconcile Sheet</button>
                        <button mat-menu-item (click)="refreshInvoices(element)" *ngIf="isborrowrper">Refresh
                          Invoices</button>
                        <button *ngIf="authService.isCredlixUser() && !element.isLead && isborrowrper" mat-menu-item
                          (click)="navigateToLeadsPage(element)">Add to Leads</button>
                        <button mat-menu-item (click)="sendWhatsappMessage(element)" *ngIf="issendwhtsapPermis">Send
                          Message</button>
                        <button mat-menu-item (click)="downloadReconcile(element, 'SUPPLIER_ANCHOR_WISE_LEDGER_REPORT')"
                          *ngIf="isEscrowPermission"> Download Supplier Ledger</button>
                          <button mat-menu-item (click)="SyncInvoice(element.config.businessAccountId)" *ngIf="jkFennerCheck"> Sync Invoice</button>
                      </mat-menu>
                    </button>
                  </div>

                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
            [pageSize]="pageSize" (page)="getPaginatorData()" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>