import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { environment } from "../../environments/environment";
import { PagesService } from "../pages/pages.service";
import { SettingService } from "../settings/setting.service";

@Injectable()
export class AuthGuard implements CanActivate {
  isCustomer: boolean;
  userOnBoardData: any;
  getSessionStorage: any;
  onBoardRes: any;
  permission: any;
  readonly URL = environment.credlixURL;

  constructor(
    private router: Router,
    public authService: AuthService,
    public settingService: SettingService,
    private pageSerive: PagesService,
    public settingservice: SettingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.getIsCusotmer()) {
      this.isCustomer = true;
    }
    // this.settingService._GSTINOnBoardData.subscribe(res => {
    //     this.userOnBoardData = res;
    // });
    this.getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));

    if (localStorage.getItem("TOKEN") != null) {
      if (
        route.routeConfig.path == "login" ||
        route.routeConfig.path == "signup"
      ) {
        this.router.navigate(["/"]);
        return false;
      } else {
        if (route.routeConfig.path == "") {
          if (this.getSessionStorage.exim == true) {
            window.location.href = this.URL + "/nuphi/#/?token=EXIM";
          } else if (
            this.getSessionStorage &&
            this.getSessionStorage["access_token"] == null &&
            this.getSessionStorage["account_token"]
          ) {
            // this.router.navigate(['select-account']);
            let id = this.getSessionStorage["user_roles"][0]["id"];
            this.settingservice.setBydefaultuser(id).subscribe((res) => {});
            this.settingservice.multipleAccount(id).subscribe((data: any) => {
              this.authService.getUserDetails(data["result"]);
              let idtoken = data["result"]["access_token"];
              this.authService.createSession(idtoken);
              window.location.href = this.URL + "/nuphi/#/?token=EXIM";
            });
          } else if (
            this.authService.isCredlixUser() &&
            !this.authService.isAnchorFinancing()
          ) {
            if (!localStorage.getItem("credlixUserID")) {
              this.pageSerive.getAnchorList().subscribe((res) => {
                this.pageSerive.setAnchorList(res["result"]);
                this.permission =
                  this.getSessionStorage.role.role["permissions"];
                if (this.permission.includes("VIEW_BORROWER")) {
                  this.router.navigate(["/supplierLinks"]);
                } else if (this.permission.includes("KANBAN")) {
                  this.router.navigate(["/credlixboard"]);
                } else if (this.checkPermissionForPreSales()) {
                  this.router.navigate(["/presales/sales"]);
                }
              });
            } else if (this.authService.isChannleFinancing()) {
              this.router.navigate(["/channelFinance"]);
            } else if (this.authService.isAnchorFinancing()) {
            } else {
              this.permission = this.getSessionStorage.role.role["permissions"];
              if (this.permission.includes("VIEW_BORROWER")) {
                this.router.navigate(["/supplierLinks"]);
              } else if (this.permission.includes("KANBAN")) {
                this.router.navigate(["/credlixboard"]);
              }
            }
          } else if (this.authService.isAnchorFinancing()) {
            this.router.navigate(["/anchor/invoice"]);
          } else if (this.authService.isKanbanUser()) {
            this.router.navigate(["/credlixboard"]);
          } else if (
            this.authService.isLenderLeads() &&
            !this.authService.isSIDLenderLogin()
          ) {
            this.router.navigate(["/leads"]);
          } else if (
            this.authService.isLenderLeads() &&
            this.authService.isSIDLenderLogin()
          ) {
            this.router.navigate(["/sid/listing/invoices"]);
          } else if (this.authService.isChannleFinancing()) {
            if (this.authService.isAnchor()) {
              this.router.navigate(["/channelFinance"]);
            } else {
              if (this.getSessionStorage["accountName"] == "MSAF") {
                this.router.navigate(["/financeListing"]);
              } else {
                this.settingService.checkGSTINExist().subscribe(
                  (res) => {
                    this.onBoardRes = res["result"];
                    this.settingService.storeOnBoardData(this.onBoardRes);
                    if (
                      this.onBoardRes["status"] != "SUBMITTED" &&
                      this.onBoardRes.verified != true
                    ) {
                      this.pageSerive.isSubmitted = true;
                      localStorage.setItem("isSubmitted", "true");
                      this.router.navigate(["/OnBoard"]);
                    } else {
                      this.router.navigate(["/bankLists"]);
                    }
                  },
                  (error) => {
                    if (error["status"] == 404) {
                      this.pageSerive.isSubmitted = true;
                      localStorage.setItem("isSubmitted", "true");
                      this.router.navigate(["/OnBoard"]);
                    }
                  }
                );
              }
            }
          }
          // else if(this.getSessionStorage && this.getSessionStorage['access_token'] == null && this.getSessionStorage['account_token']){
          //     this.router.navigate(['select-account']);
          // }
          else {
            if (this.isCustomer) {
              this.router.navigate(["/supplierLinks"]);
            } else if (this.authService.isSIDUSER()) {
              this.router.navigate(["/sid/listing/supplier"]);
            } else if (this.authService.isBuyer()) {
              if (
                this.getSessionStorage["role"] &&
                this.getSessionStorage["role"]["masterAccount"] &&
                this.getSessionStorage["role"]["masterAccount"]["isSid"]
              ) {
                this.router.navigate(["/sid/listing/buyerSupplier"]);
              } else {
                this.router.navigate(["/earlyPayments"]);
              }
            } else if (this.authService.isBorrower()) {
              if (
                this.getSessionStorage["role"] &&
                this.getSessionStorage["role"]["masterAccount"] &&
                this.getSessionStorage["role"]["masterAccount"]["isSid"]
              ) {
                this.router.navigate(["/sid/invoiceListing"]);
              } else {
                this.router.navigate(["/earlyPayments"]);
              }
            } else {
              this.router.navigate(["/earlyPayments"]);
            }
          }
        } 
        return true;
      }
    } else {
      if (route.routeConfig.path == "") {
        this.router.navigate(["/auth/login"]);
        return true;
      }
      if (
        route.routeConfig.path == "login" ||
        route.routeConfig.path == "signup"
      ) {
        return true;
      } else {
        this.router.navigate(["/auth/login"]);
        return false;
      }
    }
  }
  checkPermissionForPreSales() {
    for (let value of this.permission) {
      if (
        [
          "MANAGE_PRESALES_INPROGRESS",
          "MANAGE_PRESALES_READY",
          "VIEW_PRESALES_INPROGRESS",
          "VIEW_PRESALES_READY",
        ].includes(value)
      ) {
        return true;
      }
    }
    return false;
  }
}
