import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonSupportService {

  constructor() { }
  getFileExtension(contentDispositionHeader: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionHeader);
    if (matches != null && matches[1]) {
      const filename = matches[1].replace(/['"]/g, '');
      const extension = filename.split('.').pop();
      return extension.toLowerCase();
    }
    return '';
  }
}
