
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from './pages/pages.service';
import { FormBuilder } from '@angular/forms';
import { SettingService } from './settings/setting.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SidService } from './component/sid/services/sid.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  userInfo: {};
  opened: boolean;
  title = 'credlix';
  getLocalStorage
  selected = 'option2';
  links = ['All Sales Invoices', 'Customers'];
  activeLink = this.links[0];
  loginDetails: string;
  userDetail: any = [];
  selectAccount: boolean = false;
  showSideBar: boolean;
  userProfile: string
  hideDashboard: boolean = true;
  showInvoicePage: boolean = false;
  isMenuOpen = true;
  selectedItem = '';
  panelOpenState = false;
  contentMargin = 190;
  accountDetails = [];
  getAccountName: string;
  account_name: any;
  // accountForm: FormGroup;
  sidenavfull: boolean = false;
  AccName: string;
  isCustomer: boolean = false;
  getBusinessID: String;
  isLoading: boolean;
  channleFinancing: boolean = false;
  anchorFinancing: boolean = false;
  epfinancing: boolean = false;
  isCredlixUser: boolean = false;
  anchorList: any = [];
  anchorType: any;
  isBuyer: boolean = false;
  userOnBoardData: any;
  onBoardRes: any;
  businessName: string;
  displayName: string;
  roleType: string;
  roleName: string;
  gstNumber: string;
  anchorDisplayName: string;
  isSidUser: boolean = false;
  isGlobalFilterShown: boolean = false;
  isDefaultSelected: boolean = false;
  userDetails: string;
  idtoken: any;
  accountName: any;
  logObj: any;
  selectedRole = {};
  notificationList = [];
  unreadAlerts: number;
  anchorMasterAccountId: String = "";
  constructor(private router: Router, public authService: AuthService, private activatedRoute: ActivatedRoute, public settingService: SettingService, public pagesService: PagesService,
    private fb: FormBuilder, public tostrService: ToastrService, public sidService: SidService, public dataService: DataService) {
    this.settingService.selectAccount.subscribe((account: any) => {
      if (account == false) {
        this.selectAccount = false;
      }
      else {
        this.selectAccount = true;
      }
    })
    this.settingService._GSTINOnBoardData.subscribe(res => {
      this.userOnBoardData = res['status'];
    });
    //Handling Router For Restrict Global filter Hide feature on credlix board tab and
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('credlixboard') || event.urlAfterRedirects.includes('cardDetails') || event.urlAfterRedirects.includes('los-tracker')) {
          this.isGlobalFilterShown = true;
        } else {
          this.isGlobalFilterShown = false;
        }
      }
    });

  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.notificationId) {
        this.authService.destroySession();
        this.selectAccount = false;
      }
    });
  }

  setUserData(data: any) {
    if (data['status'] == 200) {
      this.userDetails = data['result']['first_name'] + " " + data['result']['last_name']
      this.authService.getUserDetails((data['result']));
      this.authService.storageUserDetails(this.userDetails);
      this.idtoken = data['result']['access_token'];
      this.authService.createSession(this.idtoken);
      // this.accountName = this.logObj['email'];
      // this.authService.storageAccountName(this.accountName);
    }
  }

  checkin(obj) {
    if (this.userInfo && this.userInfo['access_token']) {
      ``
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    else {
      this.settingService.multipleAccount(obj.id).subscribe((data: any) => {
        this.setUserData(data);
        if (data.result != null && data['result']['access_token'] &&
          data.result.role.role.roleType == 'SID' && this.authService.isSIDRoleType()) {
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/sid/listing/supplier']).then(() => {
            window.location.reload();
          });
        }
        else if (data.result != null && data['result']['access_token'] &&
          data.result.role.role.roleType == 'BORROWER' && this.authService.isSIDRoleType()) {
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/sid/invoiceListing']).then(() => {
            window.location.reload();
          });
        }
        else if (data.result != null && data['result']['access_token'] &&
          data.result.role.role.roleType == 'BUYER' && this.authService.isSIDRoleType()) {
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/sid/listing/buyerSupplier']).then(() => {
            window.location.reload();
          });
        } else if (data.result != null && data['result']['access_token'] && this.authService.isAnchorFinancing()) {
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/anchor/invoice']);
        }
        else {
          this.router.navigate(['/']).then(() => {
            localStorage.setItem('logged_multiple', 'true');
            window.location.reload();
          });
        }
      })
    }
  }
  ngOnInit() {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getLocalStorage && getLocalStorage['role']) {
      this.businessName = getLocalStorage['role']['businessName'];
      this.displayName = getLocalStorage['role']['role']['displayName'];
      this.gstNumber = getLocalStorage['role']['gstNumber'];
      this.roleType = getLocalStorage['role']['roleType'];
      this.roleName = getLocalStorage['role']['roleName'];
      if (getLocalStorage['role']['default'] == true) {
        this.isDefaultSelected = true;
      } else {
        this.isDefaultSelected = false;
      }
    }
    if (getLocalStorage && getLocalStorage['access_token'] != null) {
      this.selectAccount = true;
    }
    else {
      this.selectAccount = false;
    }

    let checkSubmit = localStorage.getItem('isSubmitted');
    if (checkSubmit == 'true') {
      this.pagesService.isSubmitted = true;
    }
    else {
      this.pagesService.isSubmitted = false;
    }
    this.pagesService.isLoading$.subscribe(res => {
      setTimeout(() => {
        this.isLoading = res;
      }, 0);
    })
    window.addEventListener('storage', (event) => {
      if (event.key === 'logged_multiple') {
        if (event.newValue === "true") {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else {
          this.router.navigate(['select-account']).then(() => {
            window.location.reload();
          });
        }
      }
      else if (event.key === 'logged_in' || event.key === 'TOKEN') {
        sessionStorage.clear();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    }, false)
    this.getAccountName = localStorage.getItem('accountName');
    this.loginDetails = localStorage.getItem('currentUser');
    if (this.authService.isChannleFinancing()) {
      this.channleFinancing = true;
    }
    if (this.authService.isEPfinancing()) {
      this.epfinancing = true;
    }

    if (this.loginDetails && this.loginDetails.trim()) {
      var matches = this.loginDetails.match(/\b(\w)/g);
      if (matches.length > 2) {
        matches.splice(2, matches.length)
      }
      this.userProfile = matches.join('').toUpperCase()
    }
    if (this.authService.isAnchor()) {
      this.isCustomer = true;
    }
    if (this.authService.isBuyer()) {
      this.isBuyer = true;
    }
    if (this.authService.isSIDRoleType()) {
      this.isSidUser = true;
    }
    if (this.authService.isCredlixUser() && !this.authService.isAnchorFinancing()) {
      this.isCredlixUser = true;
      if (localStorage.getItem('credlixUserID')) {
        let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
        this.anchorList = getLocalStorage['credlixAnchorList'];
        this.anchorList.map(val => {
          if (localStorage.getItem('credlixUserID') == val['id']) {
            this.anchorDisplayName = val['displayName'];
            this.anchorType = val;
            this.epfinancing = val['isEPFinancing'];
            this.channleFinancing = val['isChannleFinancing'];
            this.anchorFinancing = val['isAnchorFinancing'];
          }
        })
      }
      this.pagesService.$anchorList.subscribe((res: any) => {
        let anchors = res;
        this.anchorList = anchors.filter(val => {
          return val['displayName'] != 'NUPHI'
        })
        this.anchorList.map((val, i) => {
          if (val['displayName'] == "MOGLIX") {
            this.anchorList.splice(i, 1);
            this.anchorList.unshift(val);
          }
        })
        if (this.anchorList != undefined && this.anchorList.length > 0) {
          this.anchorType = this.anchorList[0];
          this.epfinancing = res[0]['isEPFinancing'];
          this.channleFinancing = res[0]['isChannleFinancing'];
          this.anchorFinancing = res[0]['isAnchorFinancing'];
          let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
          if (res[0]['displayName'] == "MOGLIX") {
            getLocalStorage['moglix'] = true;
          }
          getLocalStorage['credlixAnchorList'] = this.anchorList;
          localStorage.setItem('credlixUserID', this.anchorList[0]['id']);
          localStorage.setItem('USERINFO', JSON.stringify(getLocalStorage));
        }
      })
    }
    this.pagesService.changeColor$.subscribe(res => {
      if (res) {
        let ele = document.getElementById(res);
        if (ele && ele.style) {
          document.getElementById(res).style.display = "inline-block";
          this.changeElementColor(res)
        }
      }
    })
    if (this.authService.isSIDUSER()) {
      this.getNortification();
    }
  }
  getUserRoles() {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
    let rolesArray = [];
    let roleList = []
    let selectedRole = {};
    this.pagesService.getRolesByAccount().subscribe(res => {
      if (res['status'] == 200) {
        // localStorage['user_roles'] = res['result']['user_roles'];
        let id = getLocalStorage['role']['id'];
        rolesArray = res['result']['user_roles'];
        if (rolesArray.length > 1) {
          rolesArray.forEach(role => {
            if (role.id != id) {
              roleList.push(role);
            } else {
              selectedRole = role;
            }
          });
          this.userDetail = roleList;
        } else {
          this.userDetail = rolesArray;
          selectedRole = rolesArray.reduce((a, b) => Object.assign(a, b), {})
        }
        this.selectedRole = selectedRole;
      }
    });
  }
  setAccSwith(obj) {
    sessionStorage.clear();
    this.userInfo = JSON.parse(localStorage.getItem("USERINFO"));
    this.userInfo['access_token'] = null;
    localStorage.removeItem('credlixUserID');
    localStorage.removeItem('isCustomer');
    localStorage.setItem('TOKEN', this.userInfo['account_token']);
    this.userInfo['user_roles'] = this.userDetail
    localStorage.setItem("USERINFO", JSON.stringify(this.userInfo));
    this.checkin(obj)
  }
  login() {
    this.router.navigate(['/auth/login']);
  }
  logout() {
    sessionStorage.clear();
    this.authService.destroySession();
    this.router.navigate(['/auth/login'])
      .then(() => {
        window.localStorage.setItem('logged_in', 'false');
        window.location.reload();
      });
  }

  switchAccount() {
    sessionStorage.clear();
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    getLocalStorage['access_token'] = null;
    localStorage.removeItem('credlixUserID');
    localStorage.removeItem('isCustomer');
    localStorage.setItem('TOKEN', getLocalStorage['account_token']);
    this.pagesService.getRolesByAccount().subscribe(res => {
      if (res['status'] == 200) {
        getLocalStorage['user_roles'] = res['result']['user_roles'];
        localStorage.setItem("USERINFO", JSON.stringify(getLocalStorage));
        this.userDetail = res['result']['user_roles'];
        this.router.navigate(['select-account']).then(() => {
          localStorage.setItem('logged_multiple', 'false');
          window.location.reload();
        });
      }
    });
  }

  changePassword() {
    this.router.navigate(['/auth/change-password'])
  }
  createInvoice() {
    this.hideDashboard = false;
    this.showInvoicePage = true;
  }
  getUserAccounts() {
    this.pagesService.getAccountDetails().subscribe(data => {
      if (data['status'] == 200) {
        this.pagesService.offSpinner();
        this.accountDetails = data['result'].filter(function (el) {
          return el != null;
        });
      }
    })
  }
  sidenav_open() {
    this.sidenavfull == true;
  }
  sidenav_close() {
    this.sidenavfull == false;
  }

  // changeBusinessID(value) {
  //   let selectedBusinessRoles = []
  //   this.getBusinessID = value;
  //   this.authService.storageBusinessID(this.getBusinessID);
  //   for (let i = 0; i < this.accountDetails.length; i++) {
  //     if (value == this.accountDetails[i].id) {
  //       selectedBusinessRoles = this.accountDetails[i].roles
  //       localStorage.setItem('businessGSTN', this.accountDetails[i].gstNo);
  //     }
  //   }
  //   let obj = {
  //     roleId: selectedBusinessRoles[0].id
  //   }
  //   this.pagesService.refreshTokenWithBusinessID(obj).subscribe(res => {
  //     if (res['status'] == 200) {
  //       this.authService.createSession(res['result'].access_token)
  //       this.authService.getUserDetails(res['result']);
  //       window.location.reload();
  //     }
  //   })

  // }

  setAccountName() {
    if (this.accountDetails != null && this.accountDetails.length > 0)
      return this.accountDetails[0].id
  }
  showSuppliers() {
    this.router.navigate(['/supplierLinks'])
  }

  resetGlobaFiler(val, isShown?) {
    if (isShown === true && val != 'leads_white') {
      this.isGlobalFilterShown = true;
    } else {
      this.isGlobalFilterShown = false;
    }
    sessionStorage.clear();
    document.getElementById(val).style.display = "inline-block";
    this.changeElementColor(val);
    this.pagesService.getClickEvent('clicked');
  }

  // headerlistData(){
  //   this.pagesService.getHeaderList().subscribe(list=>{
  //     this.authService.storageListHeaders(list['result']);
  //   })
  // }
  permission = []

  navigateToUserManagement() {
    let anchorDisplayName;
    sessionStorage.clear();
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.permission = getSessionStorage.role.role['permissions'];
    this.pagesService.getClickEvent('clicked');
    if (!this.authService.isAccordUser() && this.isCustomer) {
      this.router.navigate(['/settings/manualUpload/manualFileUpload/booking'])
    }
    else if (this.isCredlixUser && !this.authService.isAnchorFinancing()) {
      getSessionStorage['credlixAnchorList'].map(val => {
        if (val.id == localStorage.getItem('credlixUserID')) {
          anchorDisplayName = val.displayName;
        }
      })
      if (anchorDisplayName == "MOGLIX") {
        this.router.navigate(['/settings/manualUpload/manualFileUpload/booking']);
      }
      else if (anchorDisplayName == "TWIGA" || anchorDisplayName == "ACCORD" ||
        anchorDisplayName == "GHCL" || anchorDisplayName == "MSAF") {
        this.router.navigate(['/settings/manualUpload/manualFileUpload/home']);
      }
      else if (anchorDisplayName == "VEDANTA") {
        this.router.navigate(['/settings/myProfile']);
      }
    }
    else if (this.authService.isBuyer() || this.authService.isBorrower()) {
      this.router.navigate(['/settings/myProfile'])
    }
    else if (this.authService.isSIDUSER()) {
      this.router.navigate(['/settings/manualUpload/manualFileUpload/sidRepayment']);
    }
    else if (this.authService.isAnchorFinancing) {
      this.router.navigate(['/settings/myProfile'])
    }
    else if (this.authService.isAccordUser() && !this.authService.isLenderLeads()) {
      this.router.navigate(['/settings/report'])
    }
    else if (this.authService.isLenderLeads()) {
      this.router.navigate(['/settings/myProfile'])
    }
    else {
      this.router.navigate(['/settings/myProfile'])
    }
  }

  changeAnchor(anchorType) {
    this.anchorDisplayName = anchorType['displayName'];
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    if (this.router.url.includes('settings/manualUpload') || anchorType['isChannleFinancing']) {
      this.pagesService.getHeaderList(anchorType['id']).subscribe(list => {
        this.authService.storageListHeaders(list['result']);
      })
    }
    // oldEPFinancing added to handle when credlixUser is routing from vedanta to moglix/accord/twiga
    let oldEPFinancing = this.epfinancing;
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
    if (anchorType['displayName'] == "MOGLIX") {
      getLocalStorage['moglix'] = true;
    }
    else {
      getLocalStorage['moglix'] = false;
    }
    //for SID anchor
    if (anchorType['isSid']) {
      getLocalStorage['isSid'] = true;
    }
    else {
      getLocalStorage['isSid'] = false;
    }
    this.epfinancing = anchorType['isEPFinancing'];
    this.channleFinancing = anchorType['isChannleFinancing'];
    getLocalStorage['channleFinancing'] = this.epfinancing;
    getLocalStorage['channleFinancing'] = this.channleFinancing;
    this.pagesService.setChannelFinancing(this.channleFinancing);
    localStorage.setItem('credlixUserID', anchorType['id']);
    localStorage.setItem('USERINFO', JSON.stringify(getLocalStorage));
    if (this.epfinancing) {
      if (!this.router.url.includes('/earlyPayments') && !this.router.url.includes('/supplierProfile')) {
        if (this.router.url.includes('/manualFileUpload')) {
          if (anchorType['displayName'] == "MOGLIX") {
            this.router.navigate(['/settings/manualUpload/manualFileUpload/booking']);
          }
          else {
            this.router.navigate(['/settings/manualUpload/manualFileUpload/home']);
          }
        }
        else if (!oldEPFinancing) {
          this.router.navigate(['/supplierLinks'])
        }
        else {
          this.router.navigate([this.router.url]);
        }
      }
      else {
        this.router.navigate(['/supplierLinks'])
      }
    }
    else if (this.channleFinancing) {
      this.router.navigate(['/channelFinance']);
    }
  }

  navigateToSupplier() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    if (this.authService.isBorrower() && this.authService.isSIDRoleType()) {
      this.router.navigate(['/sid/invoiceListing'])
    }
    else if (this.authService.isBuyer() && this.authService.isSIDRoleType()) {
      this.router.navigate(['/sid/listing/buyerSupplier'])
    }
    else if (this.authService.isSIDUSER()) {
      this.router.navigate(['/sid/listing/supplier'])
    }
    if ((this.isCustomer || this.isCredlixUser) && this.epfinancing && !this.authService.isSIDRoleType())
      this.router.navigate(['/supplierLinks'])
    if (!this.isCustomer && this.epfinancing && !this.isCredlixUser && !this.authService.isSIDRoleType())
      this.router.navigate(['/earlyPayments'])
    if ((this.isCustomer || this.isCredlixUser) && this.channleFinancing && !this.authService.isSIDRoleType())
      this.router.navigate(['/channelFinance'])
    if (!this.isCustomer && this.channleFinancing && !this.isCredlixUser && !this.authService.isSIDRoleType())
      this.router.navigate(['/bankLists'])
  }

  //added for changing icons css when routing in internal components
  changeElementColor(val) {
    if (val != "EP_white" && this.epfinancing && document.getElementById('EP_grey')) {
      document.getElementById('EP_grey').style.display = "inline-block";
      document.getElementById('EP_white').style.display = "none";
    }
    else if (val == "EP_white" && this.epfinancing) {
      document.getElementById('EP_grey').style.display = "none";
    }
    if (val != "white_das" && document.getElementById('grey_das')) {
      document.getElementById('grey_das').style.display = "inline-block";
      document.getElementById('white_das').style.display = "none";
    }
    else if (val == "white_das" && document.getElementById('grey_das')) {
      document.getElementById('grey_das').style.display = "none";
    }
    // if (val != "white_doc" && !this.channleFinancing) {
    //   document.getElementById('grey_doc').style.display = "inline-block";
    //   document.getElementById('white_doc').style.display = "none";
    // }
    // else if (val == "white_doc" && !this.channleFinancing) {
    //   document.getElementById('grey_doc').style.display = "none";
    // }

    if (val != "buyer_white" && document.getElementById('buyer_grey')) {
      document.getElementById('buyer_grey').style.display = "inline-block";
      document.getElementById('buyer_white').style.display = "none";
    }
    else if (val == "buyer_white") {
      document.getElementById('buyer_grey').style.display = "none";
    }

    if (val != "supplier_white" && document.getElementById('supplier_grey')) {
      document.getElementById('supplier_grey').style.display = "inline-block";
      document.getElementById('supplier_white').style.display = "none";
    }
    else if (val == "supplier_white") {
      document.getElementById('supplier_grey').style.display = "none";
    }

    if (val != "invoice-white" && document.getElementById('invoice-grey')) {
      document.getElementById('invoice-grey').style.display = "inline-block";
      document.getElementById('invoice-white').style.display = "none";
    }
    else if (val == "invoice-white" && (this.authService.isSIDRoleType())) {
      document.getElementById('invoice-grey').style.display = "none";
    }

    if (val != "finance_white" && this.channleFinancing && document.getElementById('finance_grey')) {
      document.getElementById('finance_grey').style.display = "inline-block";
      document.getElementById('finance_white').style.display = "none";
    }
    else if (val == "finance_white") {
      document.getElementById('finance_grey').style.display = "none";
    }
    if ((val != "leads_white" && (this.authService.isLenderLeads() ||
      this.authService.isCredlixUser()) && !this.channleFinancing) &&
      document.getElementById('leads_grey')) {
      document.getElementById('leads_grey').style.display = "inline-block";
      document.getElementById('leads_white').style.display = "none";
    }
    else if (val == "leads_white" && (this.authService.isLenderLeads() || this.authService.isCredlixUser())) {
      document.getElementById('leads_grey').style.display = "none";
    }
    if (val != "white_doc_ci" && this.authService.isCredlixUser() && !this.channleFinancing
      && document.getElementById('grey_doc_ci')) {
      document.getElementById('grey_doc_ci').style.display = "inline-block";
      document.getElementById('white_doc_ci').style.display = "none";
    }
    else if (val == "white_doc_ci" && this.authService.isCredlixUser()) {
      document.getElementById('grey_doc_ci').style.display = "none";
    }

    if (val != "white_das_anchor" && this.authService.isAnchorFinancing()) {
      document.getElementById('grey_das_anchor').style.display = "inline-block";
      document.getElementById('white_das_anchor').style.display = "none";
    } else if (val == "white_das_anchor" && this.authService.isAnchorFinancing()) {
      document.getElementById('grey_das_anchor').style.display = "none";
    }

    if (val != "anchor_invoice_white" && this.authService.isAnchorFinancing()) {
      document.getElementById('anchor_invoice_grey').style.display = "inline-block";
      document.getElementById('anchor_invoice_white').style.display = "none";
    } else if (val == "anchor_invoice_white" && this.authService.isAnchorFinancing()) {
      document.getElementById('anchor_invoice_grey').style.display = "none";
    }

    if (val != "anchor_supplier_white" && this.authService.isAnchorFinancing()) {
      document.getElementById('anchor_supplier_grey').style.display = "inline-block";
      document.getElementById('anchor_supplier_white').style.display = "none";
    } else if (val == "anchor_supplier_white" && this.authService.isAnchorFinancing()) {
      document.getElementById('anchor_supplier_grey').style.display = "none";
    }

    // if (val != "anchor_report_white" && this.authService.isAnchorFinancing()){
    //   document.getElementById('anchor_report_grey').style.display = "inline-block";
    //   document.getElementById('anchor_report_white').style.display = "none";
    // } else if (val == "anchor_report_white" && this.authService.isAnchorFinancing()){
    //   document.getElementById('anchor_report_grey').style.display = "none";
    // }

    if (val != "anchor_white" && this.authService.isAnchorFinancing() && this.authService.isCredlixUser()) {
      document.getElementById('anchor_grey').style.display = "inline-block";
      document.getElementById('anchor_white').style.display = "none";
    } else if (val == "anchor_white" && this.authService.isAnchorFinancing()) {
      document.getElementById('anchor_grey').style.display = "none";
    }

    if ((val != "white_doc_et" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() || this.authService.isSIDRoleType() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_et'))) {
      document.getElementById('grey_doc_et').style.display = "inline-block";
      document.getElementById('white_doc_et').style.display = "none";
    }
    else if (val == "white_doc_et" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() || this.authService.isSIDRoleType() || this.isCustomer)) {
      document.getElementById('grey_doc_et').style.display = "none";
    }
    if ((val != "white_doc_pt" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_pt'))) {
      document.getElementById('grey_doc_pt').style.display = "inline-block";
      document.getElementById('white_doc_pt').style.display = "none";
    }
    else if (val == "white_doc_pt" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
      document.getElementById('grey_doc_pt').style.display = "none";
    }

    if ((val != "white_doc_pr" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_pr'))) {
      document.getElementById('grey_doc_pr').style.display = "inline-block";
      document.getElementById('white_doc_pr').style.display = "none";
    }
    else if (val == "white_doc_pr" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
      document.getElementById('grey_doc_pr').style.display = "none";
    }

    if ((val != "white_doc_cb" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_cb'))) {
      document.getElementById('grey_doc_cb').style.display = "inline-block";
      document.getElementById('white_doc_cb').style.display = "none";
    }
    else if (val == "white_doc_cb" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
      document.getElementById('grey_doc_cb').style.display = "none";
    }

    if ((val != "white_preSales" && (this.authService.isCredlixUser()) && document.getElementById('grey_preSales'))) {
      document.getElementById('grey_preSales').style.display = "inline-block";
      document.getElementById('white_preSales').style.display = "none";
    }
    else if (val == "white_preSales" && (this.authService.isCredlixUser())) {
      document.getElementById('grey_preSales').style.display = "none";
    }
    if (val != "ledger-white" && document.getElementById('ledger-grey')) {
      document.getElementById('ledger-grey').style.display = "inline-block";
      document.getElementById('ledger-white').style.display = "none";
    }
    else if (val == "ledger-white" && (this.authService.isSIDRoleType())) {
      document.getElementById('ledger-grey').style.display = "none";
    }

    // if ((val != "chart_white" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
    //   this.isCustomer && !this.channleFinancing) && document.getElementById('chart_grey'))) {
    //   document.getElementById('chart_grey').style.display = "inline-block";
    //   document.getElementById('chart_white').style.display = "none";
    // }
    // else if (val == "chart_white" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
    //   document.getElementById('chart_grey').style.display = "none";
    // }

    if (val != "settings_white" && document.getElementById('settings_grey')) {
      document.getElementById('settings_grey').style.display = "inline-block";
      document.getElementById('settings_white').style.display = "none";
    }
    else {
      document.getElementById('settings_grey').style.display = "none";
    }

  }
  navigateTOChangePassword() {
    window.open('/#/auth/change-password');
  }
  onStatusChange(event) {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.isDefaultSelected = event.checked;
    let roleId = getLocalStorage['role']['id'];
    if (this.isDefaultSelected && roleId != null) {
      this.settingService.setBydefaultuser(roleId).subscribe(res => {
      })
    }
  }

  getNortification() {
    let obj = {
      pageNumber: "0",
      pageSize: "200"
    }
    this.pagesService.getSidNotification(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.notificationList = res['result'].alerts;
        this.unreadAlerts = res['result'].unreadAlerts;
      }
    })
  }
  dismissNotification(element, index) {
    if (this.notificationList[index].seen == false) {
      this.pagesService.dismissNotification(element.id).subscribe(res => {
        if (res['status'] == 200) {
          this.notificationList[index].seen = true;
          this.unreadAlerts = this.unreadAlerts - 1;
        }
      })
    }
    this.checkNavigation(element);
  }
  checkNavigation(element) {
    if (element.type == 'INVOICE_UPLOADED') {
      this.navigateToInvoice(element.uniqueId);
    } else {
      this.router.navigateByUrl('/escrow-payment');  // navigate to escrow payment tab
      this.settingService.setEscrowFilter(element.uniqueId);  // to set UTR filter in escrow payment page
    }
  }
  navigateToInvoice(id) {
    this.sidService.getInvoiceDetails(id).subscribe(res => {
      let invoiceDetails = res['result'];
      let supplierName = invoiceDetails['supplierAccount']['businessName'].replace(/[()]/g, '');;
      this.router.navigateByUrl(`/sid/editInvoice/${invoiceDetails['supplierAccount']['searchKey']}/${invoiceDetails['id']}/${invoiceDetails['status']}/${invoiceDetails['supplierAccount']['id']}/${supplierName}`);
    })
  }
  onScrollDown() {
    console.log("event")
  }
}
@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, " ")
  };
}
