import { Component, OnInit, ElementRef, AfterViewInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { RemarksDailogComponent } from "../remarks-dailog/remarks-dailog.component";
import { KanbanService } from "../../../kanban.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DefferedPopupComponent } from "../deffered-popup/deffered-popup.component";
import * as moment from "moment";
import { FileDailogComponent } from "../file-dailog/file-dailog.component";
import { saveAs } from "file-saver";

@Component({
  selector: "app-underwriting-doc",
  templateUrl: "./underwriting-doc.component.html",
  styleUrls: ["./underwriting-doc.component.scss"],
})
export class UnderwritingDocComponent implements OnInit {
  laneTaskId: any;
  selectedDate: any;
  data = {};
  hasAccess:boolean=false;
  constructor(
    public dialog: MatDialog,
    public toasterService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public kService: KanbanService,
    private router: Router
  ) {}

  dataSource1: Array<any> = [];
  dataSource2: Array<any> = [];
  displayColumns1: Array<string> = ["Documents", "Status", "Remarks", "Action"];

  ngOnInit() {
    this.table3();
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x.id;
      this.kService.setLaneTaskId(x.id);
    });
    this.fetchdata(this.laneTaskId);
  }
  fetchdata(x) {
    if (x !== null && x !== "undefined") {
      this.kService.fetchData(x).subscribe({
        next: (resp: any) => {
          this.kService.setLaneData(resp.result);
        },
      });
    }
    this.fetchLaneData();
  }

  table3() {
    this.dataSource1 = [
      {
        Documents: "Adhar Card Front",
        Status: "Pending",
        Remarks:
          "Morbi scelerisque quam lorem, ut consequat dolor lacinia a. Suspendisse nisl magna, malesuada nec molestie in, consectetur eu risus. Quisque viverra tempor varius.",
        Action: "",
      },
      {
        Documents: "Adhar Card back",
        Status: "Approve",
        Remarks:
          "Morbi scelerisque quam lorem, ut consequat dolor lacinia a. Suspendisse nisl magna, malesuada nec molestie in, consectetur eu risus. Quisque viverra tempor varius.",
        Action: "",
      },
    ];
    this.dataSource2 = [
      {
        Documents: "Adhar Card Front",
        Status: "Pending",
        Remarks:
          "Morbi scelerisque quam lorem, ut consequat dolor lacinia a. Suspendisse nisl magna, malesuada nec molestie in, consectetur eu risus. Quisque viverra tempor varius.",
        Action: "",
      },
      {
        Documents: "Adhar Card back",
        Status: "Approve",
        Remarks:
          "Morbi scelerisque quam lorem, ut consequat dolor lacinia a. Suspendisse nisl magna, malesuada nec molestie in, consectetur eu risus. Quisque viverra tempor varius.",
        Action: "",
      },
    ];
  }
  fetchLaneData() {
    this.kService.getListing(this.laneTaskId).subscribe({
      next: (resp: any) => {
        let a =
          resp.result && resp.result.companyDocs ? resp.result.companyDocs : {};
        let b = resp.result && resp.result.kycDocs ? resp.result.kycDocs : {};
        Object.assign(this.data, a);
        Object.assign(this.data, b);
        this.dataSource2 = Object.keys(this.data)
          .map((personNamedIndex) => {
            let person = {
              name: personNamedIndex,
              value: this.data[personNamedIndex],
            };
            return person;
          })
          .filter((x) => {
            if (x) {
              if (Array.isArray(x.value)) {
                return x.value.length > 0;
              } else {
                return x.value;
              }
            }
          });
          this.kService.hasAccess.subscribe(access=>{
            this.hasAccess = access;
          })
      },
    });
  }
  downloadAllFiles() {
    this.kService.downloadAllFiles(this.laneTaskId).subscribe({
      next: (resp: any) => {
        this.downloadSheetCommonMethod(resp);
      },
    });
  }
  resultDownload;
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get("content-disposition");
    this.resultDownload = contentDispositionHeader
      .split(";")[1]
      .trim()
      .split("=")[1];
    let fileName = this.resultDownload.replace(/"/g, "");
    saveAs(res.body, fileName);
    return fileName;
  }
  checkArray(value) {
    return Array.isArray(value);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(RemarksDailogComponent, {
      width: "500px",
      panelClass: "remarks-dialog-container",
    });
  }
  openDefferedDialouge(type, status) {
    const dialogRef = this.dialog.open(DefferedPopupComponent, {
      width: "380px",
      panelClass: "calendar-dailog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedDate = moment(result).format("LL");
        this.changeDefferedStatus(type, status, this.selectedDate);
      }
    });
  }
  changeDefferedStatus(type, status, date) {
    let obj = {
      documentType: type,
      status: status,
      laneTaskId: this.laneTaskId,
      defferedDate: date,
    };
    this.kService.changeStatus(obj).subscribe({
      next: (resp: any) => {
        this.toasterService.success("Action Taken Successfully!");
      },
      complete: () => {
        this.fetchLaneData();
        this.fetchdata(this.laneTaskId);
      },
    });
  }
  changeStatus(type, status) {
    let obj = {
      documentType: type,
      status: status,
      laneTaskId: this.laneTaskId,
    };
    this.kService.changeStatus(obj).subscribe({
      next: (resp: any) => {
        this.toasterService.success("Action Taken Successfully!");
      },
      complete: () => {
        this.fetchLaneData();
        this.fetchdata(this.laneTaskId);
      },
    });
  }
  redirectGst() {
    this.router.navigate([`/lead-details/gst-analysis/${this.laneTaskId}`]);
  }
  openAllFilesPopUp(name) {
    const dialogRef = this.dialog.open(FileDailogComponent, {
      width: "500px",
      data: { dataType: name, laneTaskId: this.laneTaskId },
      panelClass: "file-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchdata(this.laneTaskId);
    });
  }
  downloadFile(id) {
    if (id) {
      this.kService.downloadSingleUploadDocument(id).subscribe({
        next: (resp: any) => {
          this.downloadSheetCommonMethod(resp);
        },
      });
    } else {
      this.toasterService.error("No File Uploaded!");
    }
  }
  changeFile(id, type) {
    // Trigger file input click to change the file
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.onchange = (event: any) => {
      const newFile = event.target.files[0];
      this.reUpload(newFile, id, type);
    };
    fileInput.click();
  }
  triggerFolderUpload(): void {
    const folderInput = document.getElementById(
      "folderInput"
    ) as HTMLInputElement;
    if (folderInput) {
      folderInput.click();
    } else {
      console.error("folderInput element is not found");
    }
  }

  // Method to handle folder upload
  handleFolderUpload(event: Event, type): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files) as File[];
      // Process the files as needed
      this.uploadMultipleFiles(newFiles, "bulk_upload", type);
    }
  }
  reUpload(file, id, type) {
    this.kService.reUploadFile(this.laneTaskId, type, file, id).subscribe({
      next: (resp: any) => {
        this.toasterService.success("File Uploded SuccessFull!");
      },
    });
  }

  uploadMultipleFiles(files, remark, type) {
    let formData = new FormData();
    files.forEach((x) => {
      formData.append("files", x);
    });
    this.kService
      .uploadBulkFiles(formData, this.laneTaskId, remark, type)
      .subscribe({
        next: (resp: any) => {
          this.toasterService.success("Files Uploaded Sucessfully!");
        },
      });
  }
}
