<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div fxLayoutAlign="center center" class="cat-1 inactiveStatus" *ngIf="!isCustomer && isActive == false">
    <label class="txtwrap">We will start accepting Early Payment requests after Aditya Birla Finance
      completes the credit limit set-up and documentation process, which it estimates will take 2-3 weeks.
      We request you co-operation in expediting this. We will inform you over e-mail after completion,
      post which you can start requesting for early payment through this page. Kindly excuse any inconvenience
      caused.
      Requests that have already been placed will be processed.
    </label>
  </div>
  <div class="scrlbl-area">
    <section class="ctaStripWrap">
      <div class="lft">
        <button class="filter back" mat-button (click)="navigateToSupplierPage()" *ngIf="isCustomer">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button class="filter fltbtn" mat-button [ngClass]="{'open':!isFiltersShow}" (click)="filtersDisplay()"
          *ngIf="!isEPBreadcrumVisible">
          <span class="material-icons">
            filter_list
          </span>
          <span> Filter</span>
        </button>

        <button class="sort btnSorting" mat-button [matMenuTriggerFor]="sortMenu">
          <i class="ri-sort-desc"></i>
          <span>Sort
          </span>
          <mat-menu #sortMenu="matMenu" class="sortingmenu">
            <button mat-menu-item (click)="sortData(sort, 'updatedAt', 'desc', 'Modified Date - Newest first')"
              [ngClass]="{sortApplied: activeItem=='Modified Date - Newest first' }">
              <i class="ri-check-line"></i>Modified Date - Newest first</button>

            <button mat-menu-item (click)="sortData(sort, 'updatedAt', 'asc', 'Modified Date - Oldest First')"
              [ngClass]="{sortApplied : activeItem==='Modified Date - Oldest First' }">
              <i class="ri-check-line"></i>Modified
              Date
              - Oldest First</button>
            <button mat-menu-item (click)="sortData(sort,'activeEarlyPayment.amountRequested', 'desc', 'Amount Due -
              Highest first')" [ngClass]="{sortApplied : activeItem==='Amount Due -
              Highest first' }">
              <i class="ri-check-line"></i>Amount Due -
              Highest first</button>

            <button mat-menu-item (click)="sortData(sort,'activeEarlyPayment.amountRequested', 'asc','Amount Due -
              Lowest first')" [ngClass]="{sortApplied : activeItem==='Amount Due -
              Lowest first' }"> <i class="ri-check-line"></i>Amount Due -
              Lowest first</button>

            <button mat-menu-item (click)="sortData(sort,'dueDate', 'desc','Due Date - Newest first' )"
              [ngClass]="{sortApplied : activeItem==='Due Date - Newest first' }"> <i class="ri-check-line"></i>Due Date
              -
              Newest
              first</button>
            <button mat-menu-item (click)="sortData(sort,'dueDate', 'asc','Due Date - Oldest first')"
              [ngClass]="{sortApplied : activeItem==='Due Date - Oldest first' }"> <i class="ri-check-line"></i>Due Date
              -
              Oldest
              first</button>
            <button mat-menu-item (click)="sortData(sort, 'invoiceDate', 'desc', 'Invoice Date - Newest first')"
              [ngClass]="{sortApplied : activeItem==='Invoice Date - Newest first' }"> <i
                class="ri-check-line"></i>Invoice
              Date - Newest first</button>
            <button mat-menu-item (click)="sortData(sort, 'invoiceDate', 'asc', 'Invoice Date - Oldest first')"
              [ngClass]="{sortApplied : activeItem==='Invoice Date - Oldest first' }"> <i
                class="ri-check-line"></i>Invoice
              Date - Oldest first</button>
          </mat-menu>
        </button>
        <button class="download" mat-button (click)="downloadInvoiceResults()" *hasPermission="['EXPORT_DATA']">
          <i class="ri-download-line" title="Download Invoices"></i>
        </button>

      </div>
      <div class="rht" *ngIf="jkFennerCheck">
        <button class="dropdown bulkbtn" mat-button [matMenuTriggerFor]="syncMenu">
        <span class="">Sync</span>
        <i class="ri-arrow-down-s-line"></i>
      </button>
      <mat-menu #syncMenu="matMenu">
        <button mat-menu-item (click)="updateInvoiceStatus()">Invoices</button>
      </mat-menu>
      </div>
      <div class="rht" *hasPermission="['REQUEST_INVOICES']">
        <div class="cat-1 grouped reqbtn dropdown">
          <button mat-raised-button *ngIf="!isCustomer || isActive == true" (click)="requestEarlyPayment()"
            [disabled]="isButtonEnable ===true ||limitExceeded== true ? true:false">Request Early
            Payment</button>
        </div>
      </div>
      <div class="rht" *ngIf="authService.isCredlixUser()">
        <div class="cat-1 grouped reqbtn dropdown">
          <button mat-raised-button (click)="requestEarlyPayment()" [disabled]="isApproveButtonEnable ==true"> View Summary</button>
        </div>
      </div>

      <div class="rht" *hasPermission="['APPROVE_INVOICES']">
        <button class="dropdown" mat-button *ngIf="isCustomer" [matMenuTriggerFor]="actions"
          [disabled]="isApproveButtonEnable ==true">
          <span class="">Actions</span>
          <i class="ri-arrow-down-s-line"></i>
        </button>
        <mat-menu #actions="matMenu">
          <button *ngIf="isCustomer" mat-menu-item (click)="SetApprovalSuppliers('supply')"
            [disabled]="checkApprovalEnable">Approve
            (Supply)</button>
          <button *ngIf="isCustomer" mat-menu-item (click)="SetApprovalSuppliers('finance')"
            [disabled]="checkApprovalEnable">Approve
            (Finance)</button>
        </mat-menu>
      </div>
    </section>

    <div class="onbord"
      *ngIf="(!this.isCustomer && onboardStatus?.onboarding== true && onboardStatus?.verified==false && onboardStatus?.status != 'SUBMITTED')">
      <h5>Onboarding enabled. Please add documents</h5>
      <button type="button" mat-raised-button (click)="continueWithOnboard()" class="cntbtn">Continue</button>
    </div>

    <div class="onbord" *ngIf="(!this.isCustomer && onboardStatus?.onboarding== true &&
     (onboardStatus?.verified==true || onboardStatus?.status== 'SUBMITTED'))">
      <h5 style="margin: 15px; margin-left: 0px; font-size: 16px;">Thank you for choosing Credlix. Your application is
        under process </h5>
    </div>

    <div class="topheadr pgheader" fxLayout="row" flexLayoutAlign="center center">
      <div class="breadcrumb" *ngIf="!isEPBreadcrumVisible">
        <span>Early Payments</span>
        <div class="chek" *ngIf="isCustomer" style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
          <span (click)="breadcrumNavigation()" class="hyperlinktxt">Suppliers</span>
          <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
          <span *ngIf="setSupplierName !=undefined">{{setSupplierName}} ({{setSupplierID}})
          </span>
          <span *ngIf="setSupplierName ==undefined">No Invoices Found for this supplier -({{supplierID}})
          </span>
        </div>
      </div>
      <div class="breadcrumb" *ngIf="isEPBreadcrumVisible">
        <span>Search Results</span>
      </div>
    </div>
    <div class="top clearfix">
      <div class="top_leftarea">
        <div class="search searchBoxtop cusSearch" fxLayout="row" *ngIf="!isCustomer">
          <input type="text" fxFlex placeholder="Search Invoice ID" [(ngModel)]="seachText"
            (keyup.enter)="applySupplierSearch()" (keydown.space)="$event.preventDefault();">
          <button class="searchbtn">
            <i class="ri-search-line" (click)="applySupplierSearch()" *ngIf="showSearchIcon"></i>
            <i class="ri-close-line" *ngIf="showCloseIcon" (click)="resetSearchText()"></i>
          </button>
        </div>

      </div>
      <div class="right-panel">

        <div fxLayoutAlign="center center" class="cat-1 limitleft" [ngClass]="(selection.selected.length==0 && LimitLeft >0) ||
          (selection.selected.length>0 && ((LimitLeft- selectedAmount) >0)) ? 'statusfalse' : 'statustrue' "
          *ngIf="!isCustomer && isActive == true && (istotalLimit !=-1 )">
          <label class="txtwrap" *ngIf="istotalLimit !=-1 && istotalLimit !=null">Early Payment Limit Left:
            <span *ngIf="(istotalLimit !=-1 || istotalLimit !=null) && selection.selected.length ==0">₹{{LimitLeft |
              number:'1.2-2'}}</span>
            <span
              *ngIf="(istotalLimit !=-1 || istotalLimit !=null) && selection.selected.length>0 && ((LimitLeft- selectedAmount) >0)">₹{{LimitLeft-selectedAmount
              | number:'1.2-2'}}</span>
            <span
              *ngIf="(istotalLimit !=-1 || istotalLimit !=null) && selection.selected.length>0 && ((LimitLeft- selectedAmount)<=0)">
              ₹0.00</span>
          </label>
        </div>
      </div>
    </div>
    <div class="filters_EP" fxLayout="row" [hidden]="isFiltersShow" *ngIf="!isEPBreadcrumVisible">
      <div class="selectdate mataccWrap mataccWrapEp">
        <button mat-button [matMenuTriggerFor]="selectdate">
          <input type="text" class="setinpt" [(ngModel)]="setfilterValue" readonly *ngIf="timeSlot !=null">
          <span *ngIf="timeSlot ==null">Invoice Issue Date</span> <i class="ri-arrow-down-s-line"></i></button>
        <mat-menu #selectdate="matMenu" class="selectdatemenu">
          <div classs="chk_type" (click)="$event.stopPropagation();">
            <mat-radio-group aria-label="Select an option" (change)="getCustomSelectedDate($event.value)"
              [(ngModel)]="customDateOption">
              <mat-radio-button color="primary" value="7">Last Week</mat-radio-button>
              <mat-radio-button color="primary" value="30">Last 30 Days</mat-radio-button>
              <mat-radio-button color="primary" value="90">Last 90 Days</mat-radio-button>
              <mat-radio-button color="primary" value="-1">Custom</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="datebox" *ngIf="customDateOption ==-1" (click)="$event.stopPropagation();">
            <div class="dateboxinput">
              <input type="text" ngxDaterangepickerMd class="form-control" [opens]="right" [drops]="right"
                [linkedCalendars]="true" [(ngModel)]="selectedDate" (datesUpdated)="datesUpdated($event)"
                [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range" readonly>
            </div>
          </div>
        </mat-menu>
      </div>
      <mat-form-field appearance="fill" class="selectearlypaystatus">
        <mat-label>Early Payment Status</mat-label>
        <mat-select #matRef multiple (selectionChange)="onEPStatusChange($event.value)" [(ngModel)]="EPFilteresStatus">
          <mat-option *ngFor="let filter of statusFilterList" [value]="filter.roleStatus">{{filter.roleName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="selectearlypaystatus biztype"
        *ngIf="isCustomer && !authService.isAccordUser()">
        <mat-label>Biz Type Status</mat-label>
        <mat-select #matRef1 multiple (selectionChange)="onBizTypeChange($event.value)"
          [(ngModel)]="SetBizSelectedValue">
          <mat-option *ngFor="let type of BizTypeFilterList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="selectearlypaystatus biztype"
      *ngIf="isCustomer && !authService.isAccordUser()">
      <mat-label>Payment Hold</mat-label>
      <mat-select #matRef1 multiple (selectionChange)="onPaymentHoldChange($event.value)"
        [(ngModel)]="SetPaymentSelectedValue">
        <mat-option *ngFor="let type of paymentHoldFilterList" [value]="type">{{type ? type : ''}}</mat-option>
      </mat-select>
    </mat-form-field>

      <div class="leftfilteraction">
        <button mat-flat-button class="appbtn" #button [disabled]="isApplyButtonEnable"
          (click)="applyInvoiceFilters(false)">Apply</button>
        <button mat-flat-button class="resetbtn" #button [disabled]="isClearButtonEnable"
          (click)="resetInvoiceFilters($event, button, false)">Clear
          All</button>
      </div>
    </div>
    <div class="mat_table">
      <table mat-table matSortDisableClear [dataSource]="dataSource" matSort [matSortActive]="sort['active']"
        [ngClass]="isCustomer ? 'Customertable': 'suppliertable'" [matSortDirection]="sort['direction']"
        class="mat-elevation-z8 table_invoice" [hidden]="!(earlyPaymentInvoices?.length>0)">
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="checkSupplierLimit(row)" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" *ngIf="isCustomer && (row !=null  &&
              !authService.isCredlixUser() && (row.epStatus=='Supply Approval Awaited' || row.epStatus=='Finance Approval Awaited') )">
            </mat-checkbox>
            <mat-checkbox (click)="checkSupplierLimit(row)" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"  [aria-label]="checkboxLabel(row)" *ngIf="(!isCustomer && (row !=null &&
    row.epStatus=='Eligible') || (authService.isCredlixUser() && row.epStatus== 'Eligible'))">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}"
          [sticky]="getStatus(disCol.field)">
          <th mat-header-cell *matHeaderCellDef> {{disCol.key}} </th>
          <td mat-cell *matCellDef="let element" (click)="getRecord(element, disCol.field)">
            <ng-container *ngIf="disCol.display">
              <span  [ngClass]="getClass(disCol.field, element)" [matTooltip]="getToolTip(disCol.field, element)">
                {{ disCol.cell(element) }}
                </span>
                </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="image" sticky>
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="outline" (click)="requestPaymentBySingleRow(element)">
              help_outline</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="action" sticky>
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="list" #list *ngIf="(element.epStatus == 'Requested' && jkFennerCheck)">
                      <mat-icon class="icon_details">more_vert</mat-icon>
                      <mat-menu #list="matMenu">
                        <button mat-menu-item (click)="refreshStatus(element.id)">Refresh Status</button>
                      </mat-menu>
                    </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
      </table>
    </div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
      [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
