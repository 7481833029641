<div class="dialog_modal">
  <mat-icon class="iconclose" (click)="closeDialog()" fxFlex="35" fxLayoutAlign="end">close</mat-icon>
  <mat-tab-group (selectedIndexChange)="getTabValue($event)" *ngIf="showDialogTabs ==true">
    <mat-tab *ngFor="let tab of historyTabs; let index = index" [label]="tab" [disabled]='isMultipleSelectedData(tab)'>
    </mat-tab>
  </mat-tab-group>
  <div class="dialog_content">
    <div class="info" *ngIf="tabName == 'InvoiceInfo' || showDialogTabs ==false ">
      <div class="top-head">
        <h1>Calculation for<span>{{setInvoiceIDArray.length}} Invoices</span></h1>
      </div>
      <div class="scrollsection" style="height:390px; overflow:auto;">
        <div class="mid_section">
          <div fxLayout="row" fxLayoutGap="5px" *ngIf="!isMultipleSelected">
            <h5 fxFlex="65" fxLayoutAlign="start">Original Payment Due Date</h5>
            <h5 fxFlex="35" fxLayoutAlign="end">{{ _.get(getEarlyPaymentsData, 'invoiceDueDate', "") |date}}</h5>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <h5 fxFlex="65" fxLayoutAlign="start">Estimated Early Payment Date</h5>
            <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">By {{
              _.get(getEarlyPaymentsData, 'expectedPaymentDate', "") |date}}</h5>
            <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">NA</h5>
          </div>
        </div>
        <div class="content" fxLayout="column">
          <div fxLayout="column" class="sml">
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Total invoiced Amount</h5>
              <h5 fxFlex="35" fxLayoutAlign="end">₹{{ _.get(getEarlyPaymentsData, 'invoiceTotal', "") | number:'1.2-2'}}
              </h5>
            </div>

            <div fxLayout="row" fxLayoutGap="5px" *ngIf="getEarlyPaymentsData.grnAmount != null">
              <h5 fxFlex="65" fxLayoutAlign="start">GRN Amount</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'grnAmount', "") |number:'1.2-2'}}</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00</h5>
            </div>

            <div fxLayout="row" fxLayoutGap="5px" *ngIf="getEarlyPaymentsData.debitAmount !=null">
              <h5 fxFlex="65" fxLayoutAlign="start">Debit Note (s)</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'debitAmount', "") |number:'1.2-2'}}</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00</h5>
            </div>
            <!-- <div fxLayout="row" fxLayoutGap="5px" *ngIf="getEarlyPaymentsData.isDebitNoteCreated">
              <h5 fxFlex="65" fxLayoutAlign="start">Net amount Receivable</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{ _.get(getEarlyPaymentsData, 'totalAmountReceivable', "") |number:'1.2-2'}}</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00</h5>
            </div> -->
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Amount eligible for Early Payment</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'amountRequested', "") |number:'1.2-2'}}</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00</h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Commission Fee</h5>
              <h5 fxFlex="35" fxLayoutAlign="end"
                *ngIf="(getEarlyPaymentsData.commission !=null || getEarlyPaymentsData.commission != undefined) && getEarlyPaymentsData.epStatus != 'Expired'">
                ₹{{ _.get(getEarlyPaymentsData, 'commission', "") |number:'1.2-2'}}</h5>
              <h5 fxFlex="35" fxLayoutAlign="end"
                *ngIf="(getEarlyPaymentsData.commission ==null || getEarlyPaymentsData.commission == undefined) && getEarlyPaymentsData.epStatus != 'Expired'">
                ₹0.00</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00</h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Discount*</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'discount', "") |number:'1.2-2'}}</h5>
              <h5 fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00</h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 class="bold" fxFlex="65" fxLayoutAlign="start">Early Receivable</h5>
              <h5 class="bold" fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'amount', "") |number:'1.2-2'}}</h5>
              <h5 class="bold" fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00
              </h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px" class="border">
              <h5 class="bold" fxFlex="65" fxLayoutAlign="start">Balance Receivable (on due date)</h5>
              <h5 class="bold" fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'balanceOnDueDate', "") |number:'1.2-2'}}</h5>
              <h5 class="bold" fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00
              </h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 class="bold" fxFlex="65" fxLayoutAlign="start">Total Receivable</h5>
              <h5 class="bold" fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus != 'Expired'">₹{{
                _.get(getEarlyPaymentsData, 'totalAmountReceivable', "") |number:'1.2-2'}}</h5>
              <h5 class="bold" fxFlex="35" fxLayoutAlign="end" *ngIf="getEarlyPaymentsData.epStatus == 'Expired'">₹0.00
              </h5>
            </div>
          </div>
          <div class="downPayment">
            <a (click)="downloadPaymentSchedule()">Download Payment Schedule</a>
            <img src="/assets/images/downloadpayment.png" />
          </div>

          <div class="paidoption"
            *ngIf="getEarlyPaymentsData.transactions!=null && getEarlyPaymentsData.transactions.length>0">
            <span class="status early_status2" *ngIf="getEarlyPaymentsData.epStatus=='Requested'">
              Requested</span>

            <span class="status early_status3" *ngIf="getEarlyPaymentsData.epStatus == 'Partially Paid'">
              Patially Settled </span>

            <span class="status early_status3" *ngIf="getEarlyPaymentsData.epStatus =='Paid'"> Fully
              Settled
            </span>

            <div class="utrOption" *ngFor="let UTR of getEarlyPaymentsData.transactions">
              <label class="date">{{UTR.paymentDate | date:'dd/MM/yyyy'}}</label>
              <label>Paid ₹{{UTR.amount}}</label>
              <span>UTR {{UTR.utr}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomarea">
        <div class="p-text">
          <p>*Discount will be calculated as per The actual number of days payment made early. Current Discount
            percentage
            offered is {{getEarlyPaymentsData.discountRatePerDay}}% per year. </p>
        </div>

        <div class="rht"
          *ngIf="authService.isCredlixUser() && getEarlyPaymentsData.epStatus== 'Requested' && !isPayoutOption">
          <div class="cat-1 grouped reqbtn dropdown">
            <button class="delaybtn" mat-button (click)="markEPDelayed()" *hasPermission="['EARLY_PAYMENT_DELAY']">Mark
              Delay</button>
            <button class="canclbtn" mat-button (click)="cancelEPRequest()"
              *hasPermission="['EARLY_PAYMENT_CANCEL']">Cancel EP</button>
          </div>
        </div>
        <div class="rht"
          *ngIf="authService.isCredlixUser() && getEarlyPaymentsData.epStatus== 'Eligible' && !isPayoutOption ">
          <div class="cat-1 grouped reqbtn dropdown" *hasPermission="['SEND_WHATSAPP_MESSAGES']">
            <button class="whtsapbtn" mat-button (click)="sendWhatsappMessage()">Send Message</button>
          </div>
        </div>
        <mat-dialog-actions class="dialog_footer">
          <h1 *ngIf="!authService.isCredlixUser() && !isActionBtn">By continuing with payment you accept<a
              routerLink="/auth/terms-conditions" target="_blank">Credlix
              Terms</a></h1>
          <div class="checkperm" *hasPermission="['REQUEST_INVOICES']">
            <button mat-button (click)="createEarlyPayment()" fxLayoutAlign="end"
              *ngIf="disableRequestButton && !isCustomer && !isPayoutOption">
              SEND REQUEST
            </button>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
    <div class="historyInfo" *ngIf="tabName == 'History'">
      <section class="invoice-history">
        <strong class="head">{{setInvoiceNumber}}</strong>
        <div class="history-list">
          <ul>
            <li>
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Invoice Added</span>
                <span class="nrml"> {{getEarlyPaymentsData.invoiceCreatedAt | date}}</span>
              </span>
              <span class="value">Invoice was added to credlix system.</span>
            </li>
            <li *ngIf="getEarlyPaymentsData.isEligibleForEP==true">
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">
                  Approved
                </span>
              </span>
              <ul class="nest" *ngIf="getEarlyPaymentsData['approvals'] != null">
                <li *ngFor="let approval of requiredApprovals">
                  <i class="chck-sign ri-checkbox-circle-fill"
                    *ngIf="getVerifiedApproval(approval.field).field !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getVerifiedApproval(approval.field).field ==null"></i>
                  <span class="lbl">
                    <span class="bold" *ngIf="approval.field =='supplyApproval'">
                      <strong>Internal Checks -</strong> <label class="txtwrp">
                        Moglix's Internal checks like category,Invoice type </label>
                    </span>
                    <span class="bold" *ngIf="approval.field =='financeApproval'">
                      <div *ngIf="!isAccord">
                        <strong>POD Checks -</strong> <label class="txtwrp"> Check if POD has been received </label>
                      </div>
                      <div *ngIf="isAccord">
                        <strong>Invoice Amount -</strong> <label class="txtwrp">Should be <= ₹80,000 </label>
                      </div>
                    </span>
                    <span class="nrml" *ngIf="getVerifiedApproval(approval.field).field !=null">
                      {{getVerifiedApproval(approval.field).modifiedAt |date}}</span> </span>
                </li>
                <li *ngIf="getEarlyPaymentsData.isAbfrl== true ||getEarlyPaymentsData.isTasva ">
                  <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.bookingDate !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.bookingDate ==null"></i>
                  <span class="lbl">
                    <span class="bold">
                      <strong>Booking Status -</strong> <label class="txtwrp"> Check if booking has been received
                      </label>
                    </span>
                    <span class="nrml" *ngIf="getEarlyPaymentsData.bookingDate !=null">
                      {{getEarlyPaymentsData.bookingDate |date}}</span>
                  </span>
                </li>

                <li *ngIf="getEarlyPaymentsData.isAbfrl== true">
                  <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.grnAmount !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.grnAmount ==null"></i>
                  <span class="lbl">
                    <span class="bold">
                      <strong *ngIf="getEarlyPaymentsData.grnAmount ==null">GRN Awaited</strong>
                      <strong *ngIf="getEarlyPaymentsData.grnAmount !=null">GRN Done</strong>
                    </span>
                  </span>
                </li>
              </ul>
            </li>
            <li *ngIf="getEarlyPaymentsData.isEligibleForEP==false">
              <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.isEligibleForEP==true"></i>
              <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.isEligibleForEP==false"></i>
              <span class="lbl">
                <span class="bold">
                  Approval Pending
                </span>
              </span>
              <ul class="nest">
                <li *ngFor="let approval of requiredApprovals">
                  <i class="chck-sign ri-checkbox-circle-fill"
                    *ngIf="getVerifiedApproval(approval.field).field !=null && approval.field =='supplyApproval'"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getVerifiedApproval(approval.field).field ==null
                  && approval.field =='supplyApproval'"></i>

                  <i class="chck-sign ri-checkbox-circle-fill"
                    *ngIf="getEarlyPaymentsData.isPODDone == true && approval.field =='financeApproval'"></i>
                  <i class="chck-sign ri-time-line" *ngIf="(getEarlyPaymentsData.isPODDone == false || getEarlyPaymentsData.isPODDone == null)
                  && approval.field =='financeApproval'"></i>
                  <span class="lbl">
                    <span class="bold" *ngIf="approval.field =='supplyApproval'">
                      <strong>Internal Checks -</strong><label class="txtwrp">Moglix's Internal checks like
                        category,Invoice type
                      </label>
                    </span>
                    <span class="bold" *ngIf="approval.field =='financeApproval'">
                      <div *ngIf="!isAccord">
                        <strong>POD Checks -</strong> <label class="txtwrp"> Check if POD has been received </label>
                      </div>
                      <div *ngIf="isAccord">
                        <strong>Invoice Amount -</strong> <label class="txtwrp">Should be <= ₹80,000 </label>
                      </div>
                    </span>
                    <span class="nrml" *ngIf="getVerifiedApproval(approval.field).field !=null">
                      {{getVerifiedApproval(approval.field).modifiedAt |date}}</span>
                  </span>
                </li>
                <li *ngIf="getEarlyPaymentsData.isAbfrl== true || getEarlyPaymentsData.isTasva == true">
                  <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.bookingDate !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.bookingDate ==null"></i>
                  <span class="lbl">
                    <span class="bold">
                      <strong>Booking Status -</strong> <label class="txtwrp"> Check if booking has been received
                      </label>
                    </span>
                    <span class="nrml" *ngIf="getEarlyPaymentsData.bookingDate !=null">
                      {{getEarlyPaymentsData.bookingDate |date}}</span>
                  </span>
                </li>
                <li *ngIf="getEarlyPaymentsData.isAbfrl== true">
                  <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.grnAmount !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.grnAmount ==null"></i>
                  <span class="lbl">
                    <span class="bold">
                      <strong *ngIf="getEarlyPaymentsData.grnAmount ==null">GRN Awaited</strong>
                      <strong *ngIf="getEarlyPaymentsData.grnAmount !=null">GRN Done</strong>
                    </span>
                  </span>
                </li>
              </ul>
            </li>
            <li *ngIf="historyResp && historyResp.length > 0 && historyResp[0].status == 'VALIDATED'">
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Finance validation</span>
                <span class="nrml"> {{(historyResp && historyResp.length > 0 && historyResp[0].actionDate) ?
                  (historyResp[0].actionDate | date) :
                  ''}}</span>
              </span>
              <span class="value">{{historyResp && historyResp.length > 0 && historyResp[0].reason ?
                historyResp[0].reason : ''}}</span>
            </li>

            <li *ngIf="historyResp &&  historyResp.length > 0 && historyResp[0].status != 'VALIDATED'">
              <i class="chck-sign ri-time-line"></i>
              <span class="lbl">
                <span class="bold">Finance validation <span class="validationRefreshIcon" (click)="refreshInvoiceValidation()"><i class="ri-refresh-fill"></i></span></span>                
              </span>
              <span class="value">{{historyResp && historyResp.length > 0 && historyResp[0].reason ?
                historyResp[0].reason: ''}}</span>
            </li>
            <li *ngIf="getEarlyPaymentsData.isDebitNoteCreated== true">
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Debit Note Received</span>
                <span class="nrml">{{getEarlyPaymentsData.debitNoteDate | date}}</span>
              </span>
              <span class="value">₹{{getEarlyPaymentsData.debitAmount| number:'1.2-2'}}</span>
            </li>
            <li *ngIf="getEarlyPaymentsData.onHold== true">
              <i class="chck-sign ri-time-line"></i>
              <span class="lbl">
                <span class="bold">On Hold</span>
              </span>
              <span class="value">Till {{getEarlyPaymentsData.onHoldDate | date}}</span>
            </li>

            <!-- for Ep delayed -->

            <li *ngFor="let history of historyResp; let i = index">
              <i class="chck-sign ri-checkbox-circle-fill"
                *ngIf="history.status =='PAYMENT_REQUESTED' || history.status =='ELIGIBLE' "></i>
              <i class="chck-sign-delay ri-error-warning-fill" *ngIf="history.status== 'DELAYED'"></i>
              <i class="chck-sign-cancl ri-close-circle-fill" *ngIf="history.status== 'CANCELLED'"></i>
              <span *ngIf="history.status== 'ELIGIBLE'">
                <span class="lbl">
                  <span class="bold"> Eligible </span>
                  <span class="nrml"> {{history.actionDate}}</span>
                </span>
                <span class="value">{{history.reason}}</span>
              </span>
              <span
                *ngIf="history.status!= 'ELIGIBLE' && (history.status == 'SUPPLY_APPROVAL_AWAITED' || history.status == 'FINANCE_APPROVAL_AWAITED')">
                <i class="chck-sign ri-time-line"></i>
                <span class="lbl">
                  <span class="bold"> Eligible </span>
                </span>
              </span>
              <span *ngIf="history.status== 'PAYMENT_REQUESTED'">
                <span class="lbl">
                  <span class="bold">Early Payment Requested</span>
                  <span class="nrml"> {{history.actionDate}}</span>
                </span>
                <span class="value">{{history.reason}}</span>
              </span>

              <span style="margin-top: 5px;"
                *ngIf="requestIndex  == i && history.status== 'PAYMENT_REQUESTED' && getEarlyPaymentDetails[0].source != null">
                <span class="lbl">
                  <span *ngIf="getEarlyPaymentDetails[0].source == 'WEB'" class="value">Source : Web</span>
                  <span *ngIf="getEarlyPaymentDetails[0].source == 'WHATSAPP'" class="value">Source : WhatsApp</span>
                </span>
              </span>

              <!-- For delay history -->
              <span *ngIf="history.status== 'DELAYED'">
                <span class="lbl">
                  <span class="bold">Early Payment Delayed</span>
                  <span class="nrml"> {{history.actionDate}}</span>
                </span>
                <span class="value">Reason: {{history.reason}}</span>
                <span class="value eta">ETA: {{history.expectedPaymentDate}}</span>
              </span>

              <!-- For cancel history -->
              <span *ngIf="history.status== 'CANCELLED'">
                <span class="lbl">
                  <span class="bold">Early Payment Cancelled</span>
                  <span class="nrml"> {{history.actionDate}}</span>
                </span>
                <span class="value">Reason: {{history.reason}}</span>
              </span>
            </li>

            <li *ngIf="getEarlyPaymentsData.isEPRequested == false && getEarlyPaymentDetails[0].epStatus!='Expired'">
              <i class="chck-sign ri-time-line"></i>
              <span class="lbl">
                <span class="bold">Request Early Payment</span>
              </span>
              <span class="value">Before {{RequestEPDate | date}}</span>
            </li>
            <li *ngIf="getEarlyPaymentsData.onHold== false && getEarlyPaymentsData.epStatus=='Expired'">
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Expired</span>
                <span class="nrml">{{RequestEPDate | date}}</span>
              </span>
              <span class="value">Early payment window is expired. Payments
                (if any) will be made by Moglix via standarad process.</span>
            </li>
            <li>
              <i *ngIf="getEarlyPaymentsData.gstHold == true" class="chck-sign ri-time-line"></i>
              <i *ngIf="getEarlyPaymentsData.gstHold == false || !getEarlyPaymentsData.gstHold"
                class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Invoice GST Upload</span>
                <span class="nrml"
                  *ngIf="getEarlyPaymentsData.gstHold== false || !getEarlyPaymentsData.gstHold">{{getEarlyPaymentsData?.invoiceGstDate
                  | date}}</span>
              </span>
              <span class="value" *ngIf="getEarlyPaymentsData.gstHold == true">On Hold</span>
              <span class="value"
                *ngIf="getEarlyPaymentsData.gstHold == false || !getEarlyPaymentsData.gstHold">{{getEarlyPaymentsData?.invoiceGst}}</span>
            </li>

            <li *ngIf="getEarlyPaymentDetails[0].epStatus!='Expired'">
              <i class="chck-sign ri-time-line"
                *ngIf="getEarlyPaymentsData.isEPPaid !=undefined && getEarlyPaymentsData.isEPPaid== false "></i>
              <i class="chck-sign ri-checkbox-circle-fill"
                *ngIf="getEarlyPaymentsData.isEPPaid !=undefined && getEarlyPaymentsData.isEPPaid== true"></i>
              <span class="lbl">
                <span class="bold">Early Payment Disbursement</span>
              </span>
              <span class="value"
                *ngIf="getEarlyPaymentsData.isEPRequested ==false  && (getEarlyPaymentsData.epStatus=='Eligible' || getEarlyPaymentsData.epStatus=='Supply Approval Awaited' || getEarlyPaymentsData.epStatus=='Finance Approval Awaited')">Within
                {{getEarlyPaymentsData.minDaysInterval}} Days of request</span>

              <span class="value" *ngIf="getEarlyPaymentsData.isEPRequested ==true
                && getEarlyPaymentsData.epStatus=='Requested'">
                Before {{getEarlyPaymentsData.expectedPaymentDate | date}}</span>

              <!-- <span class="value" *ngIf="getEarlyPaymentsData.epStatus == 'Partially Paid'">
                Before {{getEarlyPaymentsData.expectedPaymentDate| date}}
              </span> -->

              <span class="value" *ngIf="(getEarlyPaymentsData.epStatus =='Paid' || getEarlyPaymentsData.epStatus == 'Partially Paid')
                  && getEarlyPaymentsData['transactions'] != null">
                Settled ₹{{getEarlyPaymentsData['transactions'][0].amount}}
                <span class="nrml payDate" *ngIf="getEarlyPaymentsData.transactions !=null">
                  {{getEarlyPaymentsData['transactions'][0].paymentDate |date}}</span>
              </span>
            </li>
            <li *ngIf="getEarlyPaymentDetails[0].epStatus!='Expired'">
              <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.isBalancePaid==null"></i>

              <i class="chck-sign ri-time-line"
                *ngIf="getEarlyPaymentsData.isBalancePaid!=null && getEarlyPaymentsData.isBalancePaid == false "></i>

              <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.isBalancePaid == true"></i>
              <span class="lbl">
                <span class="bold"> Balance Payment</span>
                <span class="nrml"
                  *ngIf="getEarlyPaymentsData.epStatus =='Paid' && getEarlyPaymentsData['transactions'] != null">{{getEarlyPaymentsData.balanceDueDate
                  |
                  date}}</span>
              </span>
              <span class="value"
                *ngIf="getEarlyPaymentsData['transactions'] != null && (getEarlyPaymentsData.epStatus =='Paid' || getEarlyPaymentsData.epStatus== 'Partially Paid')">
                {{getEarlyPaymentsData.balanceOnDueDate | number:'1.2-2'}}</span>
            </li>
          </ul>
        </div>
      </section>
    </div>

  </div>

</div>