import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { KanbanService } from "../../../kanban.service";
import { WatcherPopupComponent } from "../card-details/watcher-popup/watcher-popup.component";
import { MatDialog } from "@angular/material";
import { LaneTaskUsersTabComponent } from "../card-details/lane-task-users-tab/lane-task-users-tab.component";
import { CommentsTabComponent } from "../card-details/comments-tab/comments-tab.component";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { element } from "protractor";

@Component({
	selector: 'app-lead-details',
	templateUrl: './lead-details.component.html',
	styleUrls: ['./lead-details.component.scss'],
})
export class LeadDetailsComponent implements OnInit {
	laneTaskId: any
	laneData: any
	count: number = 0
	primaryContact: any
	buyerLength: number = 0
	buyerLimit: any
	options: any
	laneListData = []
	laneId: any
	showLaneChange: boolean = false
	selectedLane: any
	currentLanePosition: number = 0
	toatalLanesList: { laneName: string; position: number }[]
	uploadTypeName: any
	currentLaneName: any
	LanesObject: any[]
	laneList: any = []
	userInfo = JSON.parse(localStorage.getItem('USERINFO'))
	editAccess: boolean = false
	constructor(
		private route: ActivatedRoute,
		public kService: KanbanService,
		private router: Router,
		public dialog: MatDialog,
		public toasterService: ToastrService,
		private location: Location,
		private activatedRoute: ActivatedRoute,
	) {}

	ngOnInit() {
		this.toatalLanesList = this.kService.LeadLanes
		this.kService.isBuyer.subscribe((element) => {
			if (element) {
				this.uploadTypeName = element
			}
		})
		this.kService.laneData.subscribe((x) => {
			if (Object.keys(x).length == 0) {
				return
			}
			this.laneId = x.lane.id
			this.currentLaneName = x.lane.name
			this.kService.setCurrentLaneId(x.laneId)
			this.currentLanePosition = x.lane.position
			this.selectedLane = x.lane.id
			if (x && x.domesticData) {
				this.laneData = x
				this.getPrimaryInfo(x)
				this.onceCallApi(this.count, x.boardId)
			}
			if (this.userInfo.role.roleName == 'COMPANY_ADMIN') {
				this.editAccess = true
				this.kService.setHasAccess(true)
			} else {
				x.assignee.forEach((element) => {
					if (element.user.id == this.userInfo.role.userId) {
						this.editAccess = true
						this.kService.setHasAccess(true)
					}
				})
			}
		})
		this.kService.laneTaskId.subscribe((x) => {
			this.laneTaskId = x
		})
	}

	onceCallApi(number, id) {
		if (number == 0) {
			this.kService.getKanbanUserList().subscribe((list) => {
				this.options = list['result']
			})
			this.boardLaneApi(id)
			this.count++
		}
	}

	reloadPage(): void {
		const currentUrl = this.router.url
		this.router.navigateByUrl(currentUrl, { skipLocationChange: true }).then(() => {
			// Reload the page by navigating to the same URL
			window.location.reload()
		})
	}
	boardLaneApi(id) {
		this.kService.getLaneWithUserAccess(id, null, null, null).subscribe({
			next: (resp: any) => {
				this.laneListData = []
				this.showLaneChange = true
				let levelaccess = resp.result.levelAccess
				levelaccess.forEach((element, i) => {
					let obj = {
						laneName: element.laneDTO.name,
						lanePosition: element.laneDTO.position,
						laneId: element.laneDTO.id,
						displayName: element.laneDTO.displayName,
					}
					this.laneListData.push(obj)
				})
				this.laneListData.sort((a, b) => {
					return a.lanePosition - b.lanePosition
				})
				let curentlaneObj = this.laneListData.filter((element, i) => {
					return element.laneName == this.currentLaneName
				})
				let currentLaneIndex = curentlaneObj[0].lanePosition
				let previosLaneObj = this.laneListData[currentLaneIndex - 2]
				if (currentLaneIndex <= this.laneListData.length) {
					var nextLaneObj = this.laneListData[currentLaneIndex]
				}
				this.laneList = []
				if (previosLaneObj) {
					this.laneList.push(previosLaneObj)
				}
				this.laneList.push(curentlaneObj[0])
				if (nextLaneObj) {
					this.laneList.push(nextLaneObj)
				}
				this.kService.setLaneListIds(this.laneListData)
			},
		})
	}

	goToBack(): void {
		this.kService.count = 0
		this.router.navigate(['/credlixboard'])
		// console.log(sessionStorage.domesticData);
	}
	reRoute(type: string) {
		this.router.navigate([`/lead-details/${type}/${this.laneTaskId}`])
	}
	getPrimaryInfo(x) {
		if (x) {
			let newLeads = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].contact ? x.domesticData['NEW_LEADS'].contact : []
			newLeads.forEach((element) => {
				if (element.isPrimary) {
					this.primaryContact = element
				}
			})
			if (x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].buyer.length > 0) {
				let buyerSum = 0
				let buyer = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].buyer ? x.domesticData['NEW_LEADS'].buyer : []
				this.buyerLength = buyer.length
				buyer.forEach((item) => {
					buyerSum = buyerSum + item.limitRequested
				})
				this.buyerLimit = buyerSum
			} else {
				let supplierSum = 0
				let supplier = x.domesticData['NEW_LEADS'] && x.domesticData['NEW_LEADS'].supplier ? x.domesticData['NEW_LEADS'].supplier : []
				this.buyerLength = supplier.length
				supplier.forEach((item) => {
					supplierSum = supplierSum + item.limitRequested
				})
				this.buyerLimit = supplierSum
			}
		}
	}
	watcherPopup() {
		const dialogRef = this.dialog.open(WatcherPopupComponent, {
			disableClose: false,
			panelClass: 'watcherPopupWrap',
			data: {
				currentBoardID: this.laneData.boardId,
				laneTaskDetails: this.laneData,
			},
		})
		dialogRef.afterClosed().subscribe((result) => {
			// this.getLaneDetailsById(false);
		})
	}

	onSideTabToggle = () => {
		let component = null
		component = LaneTaskUsersTabComponent
		const dialogRef = this.dialog.open(component, {
			disableClose: false,
			width: '30%',
			height: '100%',
			position: { bottom: '0px', right: '0px' },
			data: {
				laneTask: this.laneData,
				userList: this.options,
			},
		})
		dialogRef.afterClosed().subscribe((result) => {
			console.log(result)
			this.reloadPage()
		})
	}

	comments() {
		let component = null
		component = CommentsTabComponent
		const dialogRef = this.dialog.open(component, {
			disableClose: false,
			width: '30%',
			height: '100%',
			position: { bottom: '0px', right: '0px' },
			data: {
				laneTask: this.laneData,
				userList: this.options,
			},
		})
		dialogRef.afterClosed().subscribe((result) => {})
	}

	laneChange(event) {
		let laneName = this.laneListData.filter((element) => {
			return element.laneId == this.laneData.lane.id
		})
		// //if lane is moving to forword
		// if (
		//   this.laneData.lane.position < laneName[0].lanePosition &&
		//   this.laneData.lane.displayName != "GSTIN Analysis"
		// ) {
		//   if (
		//     this.laneData && this.laneData.domesticData && !this.laneData.domesticData[this.laneData.lane.displayName]
		//   ) {
		//     this.toasterService.error(
		//       "Cannot proceed to the next stage. Please fill in all required data first!"
		//     );
		//     return;
		//   }
		// }
		let id = this.laneId
		const self = this
		this.kService.laneChangeCall(this.laneTaskId, this.selectedLane, this.laneId).subscribe({
			next: (resp: any) => {
				this.toasterService.success(resp.result)
				let toLaneObject = this.laneListData.filter((element) => {
					return element.laneId == event.value
				})
				if (id == toLaneObject[0]['laneId']) {
					this.reRoute(toLaneObject[0]['laneName'])
				}
			},
			error: (err: any) => {
				this.toasterService.error(err && err.error && err.error.message ? err.error.message : 'Something went wrong!')
				self.laneId = self.laneListData[this.laneData.lane.position - 1].laneId
				// this.reloadPage();
			},
		})
	}
}
